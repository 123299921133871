import { FC, ReactNode } from 'react';

import { RdbUserCtx } from '@/utils/ctxs';
import { useRealtimeDatabase } from '@/utils/customHooks';

export const RealtimeDatabaseProvider: FC<{
    children: ReactNode;
    initialRoomId: string | undefined;
}> = ({ children, initialRoomId }) => {
    // HOOKS
    const { isRdbOnline } = useRealtimeDatabase({ initialRoomId });

    return (
        <RdbUserCtx.Provider
            value={{
                isOnline: isRdbOnline,
            }}
        >
            {children}
        </RdbUserCtx.Provider>
    );
};
RealtimeDatabaseProvider.displayName = 'RealtimeDatabaseProvider';
