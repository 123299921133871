import { FC } from 'react';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import MuiTypography from '@material-ui/core/Typography';
import { initPowerchatFirebaseUserClient } from '@/utils/PowerchatClient';
import { useUserCtx } from '@/utils/ctxs';
import { useLanguage } from '@/utils/customHooks';
import { useUniqueName } from '@/components/3_template/PreConsoleTemplate/SignupDisplay/useUniqueName';
import { TextInput } from '@/components/0_atom/Input';
import { LoadingIndicator } from '@/components/0_atom/LoadingIndicator';
import { MutationButton } from '@/components/0_atom/MutationButton';
import SvgCheck from '@/svgs/fa-check-solid';

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: 25,
    },
    h1: {
        marginBottom: 9,
    },
    inputbox: {
        height: 140,
    },
    uniquNameInput: {
        '& .MuiInputBase-input': {
            borderWidth: '2px !important',
        },
    },
    check: {
        color: theme.palette.success.main,
        width: 18,
    },
    submitButtonBox: {
        textAlign: 'right',
    },
    submitButton: {
        padding: '12px 24px',
    },
}));

export const SignupDisplay: FC = () => {
    // STYLE
    const c = useStyles(useTheme());

    // HOOKS
    const { txt, language } = useLanguage();
    const { isFetching, uniqueName, setUniqueName, isUnique, isValidFormat } = useUniqueName();
    const { reInitialize, firebaseUser, fcmToken } = useUserCtx();

    // MUTATION
    const runCreateUser = async () => {
        if (uniqueName && firebaseUser && fcmToken && language) {
            const firebaseUserClient = initPowerchatFirebaseUserClient({
                firebaseUserIdToken: firebaseUser.idToken,
                fcmToken,
                firebaseUid: firebaseUser.uid,
                language,
            });
            const result = await firebaseUserClient.createUser({ uniqueName }).catch(() => undefined);
            if (result) {
                reInitialize();
                return 'success' as const;
            }
        }
        return 'failed' as const;
    };

    return (
        <div className={c.root}>
            <MuiTypography variant={'h1'} className={c.h1}>
                {txt({
                    en: 'Welcome to Power!',
                    ja: 'Powerへようこそ!',
                })}
            </MuiTypography>
            <div className={c.inputbox}>
                <TextInput
                    className={c.uniquNameInput}
                    fullWidth
                    value={uniqueName}
                    setValue={setUniqueName}
                    isSatisfied={!isFetching && isUnique && isValidFormat}
                    messages={[
                        {
                            variant: !isFetching && isValidFormat === false ? 'error' : 'plain',
                            isVisible: true,
                            message: txt({
                                en: 'Only single-byte alphanumeric characters can be used for username.',
                                ja: 'ユーザーネームに利用できるのは半角英数字のみです。',
                            }),
                        },
                        {
                            variant: 'error',
                            isVisible: !isFetching && isUnique === false,
                            message: txt({
                                en: 'That name is not available.',
                                ja: 'その名前は利用できません。',
                            }),
                        },
                        {
                            variant: 'success',
                            isVisible: !isFetching && isUnique === true,
                            message: txt({
                                en: 'That name is available.',
                                ja: 'その名前は利用可能です。',
                            }),
                        },
                    ]}
                    placeholder={txt({
                        en: 'Username',
                        ja: 'ユーザーネーム',
                    })}
                    endAdornment={
                        isFetching ? (
                            <LoadingIndicator />
                        ) : isUnique && isValidFormat ? (
                            <SvgCheck className={c.check} />
                        ) : undefined
                    }
                />
            </div>
            <div className={c.submitButtonBox}>
                <MutationButton
                    className={c.submitButton}
                    disabled={!(!isFetching && isUnique && isValidFormat && language)}
                    mutation={{
                        run: runCreateUser,
                        description: txt({
                            en: 'Create an account',
                            ja: 'アカウントを作成',
                        }),
                    }}
                    role={'submit'}
                >
                    {txt({
                        en: 'Create an account',
                        ja: 'アカウントを作成',
                    })}
                </MutationButton>
            </div>
        </div>
    );
};
SignupDisplay.displayName = 'SignupDisplay';
