import { ref, set } from 'firebase/database';
import { getFirebaseRealtimeDatabase } from '@/utils/GoogleCloud/firebase';
import {
    RemoveRdbLastReactionInput,
    getRdbLastReactionInput,
} from '@/utils/PowerchatClient/RealtimeDatabase/RdbModels';

export const removeRdbReaction = ({ roomId, reaction }: { roomId: string; reaction: RemoveRdbLastReactionInput }) => {
    const db = getFirebaseRealtimeDatabase();
    const lastReactionRef = ref(db, `lastReactions/${roomId}`);
    set(lastReactionRef, {
        action: 'remove',
        ...getRdbLastReactionInput({
            ...reaction,
            removedAt: 'serverTimestamp',
            addNotification: undefined,
        }),
    });
};
