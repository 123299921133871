import { FC, ReactNode } from 'react';

import { RoomMembersCtx } from '@/utils/ctxs';
import { useRoomMembers } from '@/utils/customHooks';

export const RoomMembersProvider: FC<{
    children: ReactNode;
}> = ({ children }) => {
    // HOOKS
    const { userIds, getRoomMemberItem, getRoomMemberItemAbsolutely, lastCheckin } = useRoomMembers();

    return (
        <RoomMembersCtx.Provider
            value={{
                userIds,
                getRoomMemberItem,
                getRoomMemberItemAbsolutely,
                lastCheckin,
            }}
        >
            {children}
        </RoomMembersCtx.Provider>
    );
};
RoomMembersProvider.displayName = 'RoomMembersProvider';
