import { FC } from 'react';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import { useRoomCtx, useRoomsCtx } from '@/utils/ctxs';
import { LoadingIndicator } from '@/components/0_atom/LoadingIndicator';
import { RoomTemplate } from '@/components/3_template/ConsoleTemplate/RoomTemplate';
import { RoomsTemplate } from '@/components/3_template/ConsoleTemplate/RoomsTemplate';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'row-reverse',
        height: '100vh',
    },

    rooms: {
        flexGrow: 0,
    },
    room: {
        flexGrow: 1,
        background: '#fafafa',
    },
    noroom: {
        flexGrow: 1,
        background: '#fafafa',
    },
}));

export const ConsoleTemplate: FC = () => {
    // STYLE
    const c = useStyles(useTheme());

    // HOOKS
    const { roomWithMemberships } = useRoomsCtx();
    const { current } = useRoomCtx();
    console.log({ current });
    return (
        <div className={c.root}>
            {!roomWithMemberships ? (
                <LoadingIndicator />
            ) : !current ? (
                <div className={c.noroom}></div>
            ) : (
                <RoomTemplate className={c.room} />
            )}
            <RoomsTemplate className={c.rooms} />
        </div>
    );
};
ConsoleTemplate.displayName = 'ConsoleTemplate';
