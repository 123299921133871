import { FC, useState, useEffect } from 'react';
import clsx from 'clsx';

import { useTheme, makeStyles } from '@material-ui/core/styles';

import { RoomApi, RoomMembershipApi, MessageApi } from '@/utils/PowerchatClient';
import { useRoomCtx, useRoomsCtx, useRoomCtxAbsolutely } from '@/utils/ctxs';
import { useLanguage, useRoomMembers } from '@/utils/customHooks';
import { LastReactionProvider, RoomMembersProvider } from '@/providers';
import { LoadingIndicator } from '@/components/0_atom/LoadingIndicator';
// import { PastMesagesView } from '@/components/3_template/ConsoleTemplate/RoomTemplate/PastMesagesView';
// import { MessagesView } from '@/components/3_template/ConsoleTemplate/RoomTemplate/MessagesView';
import { PastTimelineView } from '@/components/3_template/ConsoleTemplate/RoomTemplate/PastTimelineView';
import { LocalTimelineView } from '@/components/3_template/ConsoleTemplate/RoomTemplate/LocalTimelineView';
import { AddMessageInput } from '@/components/3_template/ConsoleTemplate/RoomTemplate/AddMessageInput';
import { MembersView } from '@/components/3_template/ConsoleTemplate/RoomTemplate/MembersView';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        padding: 12,
        height: '100vh',
    },
    roomContent: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    timeline: {
        background: '#F1F1F1',
        boxShadow: '-32px -32px 120px 0 rgba(0,0,0,.05) inset',
        borderRadius: 10,
        padding: 10,
        width: 310,
        flexGrow: 1,
        flexShrink: 1,
        overflow: 'scroll',
    },
    members: {
        marginLeft: 15,
    },
}));

export const RoomTemplate: FC<{
    className?: string;
}> = ({ className }) => {
    // STYLE
    const c = useStyles(useTheme());

    // HOOKS
    const {
        current: { currentRoomApi },
    } = useRoomCtxAbsolutely();
    const [replyingToMessageApi, setReplyingToMessageApi] = useState<MessageApi | undefined>(undefined);

    // // USEEFFECT
    // useEffect(()=>{

    // },[ currentRoomApi.id])

    return (
        <div className={clsx(c.root, className)}>
            <RoomMembersProvider>
                <div className={c.roomContent}>
                    <LastReactionProvider>
                        <div className={c.timeline}>
                            <PastTimelineView setReplyingToMessageApi={setReplyingToMessageApi} />
                            <LocalTimelineView setReplyingToMessageApi={setReplyingToMessageApi} />
                        </div>
                    </LastReactionProvider>
                    <AddMessageInput
                        replyingToMessageApi={replyingToMessageApi}
                        setReplyingToMessageApi={setReplyingToMessageApi}
                    />
                </div>
                <MembersView className={c.members} />
            </RoomMembersProvider>
        </div>
    );
};
RoomTemplate.displayName = 'RoomTemplate';
