import { FC, ReactNode, useEffect } from 'react';

import { startListeningToFCM } from '@/utils/GoogleCloud/CloudMessaging';
import { useUserCtxAbsolutely } from '@/utils/ctxs';
import { useLanguage } from '@/utils/customHooks';

export const CloudMessagingProvider: FC<{
    children: ReactNode;
}> = ({ children }) => {
    // HOOKS
    const { userClient } = useUserCtxAbsolutely();
    const { txt } = useLanguage();

    // USEEFFECT
    useEffect(() => {
        startListeningToFCM({ userClient, txt });
        // navigator.serviceWorker.addEventListener('message', (m) => {
        //     console.log('Got sw message:', m);
        // });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <>{children}</>;
};
CloudMessagingProvider.displayName = 'CloudMessagingProvider';
