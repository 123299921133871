import { FC } from 'react';
import clsx from 'clsx';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import MuiIconButton from '@material-ui/core/IconButton';

import SvgPlus from '@/svgs/fa-plus-solid';

import { MessageApi, ReactionApi } from '@/utils/PowerchatClient';
import { useRoomMembersCtx, useUserCtxAbsolutely } from '@/utils/ctxs';
import { useLanguage } from '@/utils/customHooks';
import { UserIconWithName } from '@/components/2_org/UserIconWithName';
import { AddReactionButton } from '@/components/2_org/AddReactionButton';
import { ReplyButton } from '@/components/2_org/ReplyButton';
import { useReactions } from '@/components/3_template/ConsoleTemplate/RoomTemplate/useReactions';
import { ReactionButton } from '@/components/3_template/ConsoleTemplate/RoomTemplate/ReactionButton';
import { MessageBodyViewer } from '@/components/3_template/ConsoleTemplate/RoomTemplate/MessageBodyViewer';
import { ReplyingToMessageCard } from '@/components/3_template/ConsoleTemplate/RoomTemplate/ReplyingToMessageCard';
import { CancelMessageButton } from '@/components/3_template/ConsoleTemplate/RoomTemplate/CancelMessageButton';

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: 10,
        display: 'flex',
        alignItems: 'flex-end',
        '&:hover .MessageCard-actions': {
            visibility: 'visible',
        },
        '&:not(:hover) .MessageCard-actions': {
            visibility: 'hidden',
        },
    },
    rootMe: {
        flexDirection: 'row-reverse',
    },
    rootOther: {},
    content: {
        flexShrink: 0,
        flexGrow: 0,
        width: '70%',
    },
    card: {
        background: '#fff',
        borderRadius: 12,
        boxShadow: '0 0 4px 0 rgba(0,0,0,.05)',
    },
    cardBottom: {
        padding: '12px 18px 8px 18px',
    },
    body: {
        fontSize: 12,
    },
    bodyCanceled: {
        textDecoration: 'line-through',
        opacity: 0.5,
    },
    topRow: {
        // marginBottom: 5,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    topRowMe: {
        flexDirection: 'row-reverse',
    },
    topRowOther: {},
    user: {
        fontSize: 10,
        marginRight: 12,
    },
    userIcon: {
        width: 18,
        height: 18,
        marginRight: 5,
    },
    createdAt: {
        fontSize: 10,
        color: theme.palette.text.disabled,
        flexShrink: 0,
        flexGrow: 0,
        paddingBottom: 6,
    },
    createdAtMe: {
        marginRight: 4,
    },
    createdAtOther: {
        marginLeft: 4,
    },
    addButton: {
        '& svg': {
            height: 10,
            width: 10,
        },
    },
    reactions: {
        marginTop: 8,
    },
    reactionsPlaceholder: {
        height: 4,
    },
    actions: {},
    replyingTo: {},
}));

export const MessageCard: FC<{
    className?: string;
    messageApi: MessageApi;
    initialReactionApis: ReactionApi[] | undefined;
    isLocal: boolean;
    setReplyingToMessageApi: (replyingToMessageApi?: MessageApi) => void;
    getMessageApi: (id: string) => MessageApi | undefined;
}> = ({ className, messageApi, initialReactionApis, isLocal, setReplyingToMessageApi, getMessageApi }) => {
    // STYLE
    const c = useStyles(useTheme());

    // HOOKS
    const { userClient } = useUserCtxAbsolutely();
    const { getRoomMemberItem } = useRoomMembersCtx();
    const { reactionIds, getReactionItemAbsolutely } = useReactions({
        messageId: messageApi.id,
        initialReactionApis: initialReactionApis || [],
    });
    const { txt, getDateTimeStr } = useLanguage();

    // DATA
    const reactionApisForLetters = reactionIds.reduce(
        (acc, prev) => {
            const reactionApi = getReactionItemAbsolutely(prev);
            const taregtItem = acc.find(({ letter }) => letter === reactionApi.letter);
            if (taregtItem) {
                return acc.map((item) => {
                    if (item.letter === reactionApi.letter) {
                        return {
                            letter: item.letter,
                            reactionApis: [...item.reactionApis, reactionApi],
                        };
                    }
                    return item;
                });
            }
            return [
                ...acc,
                {
                    letter: reactionApi.letter,
                    reactionApis: [reactionApi],
                },
            ];
        },
        [] as {
            letter: string;
            reactionApis: ReactionApi[];
        }[]
    );

    return (
        <div
            className={clsx(c.root, className, {
                [c.rootMe]: messageApi.userId === userClient.id,
                [c.rootOther]: messageApi.userId !== userClient.id,
            })}
        >
            <div className={c.content}>
                <div
                    className={clsx(c.topRow, {
                        [c.topRowMe]: messageApi.userId === userClient.id,
                        [c.topRowOther]: messageApi.userId !== userClient.id,
                    })}
                >
                    {(() => {
                        const member = getRoomMemberItem(messageApi.userId);
                        if (member) {
                            return (
                                <UserIconWithName
                                    userName={member.user.uniqueName}
                                    isHot={member.rdbUser?.currentRoomId === member.membershipApi.roomId}
                                    className={c.user}
                                    iconClassName={c.userIcon}
                                />
                            );
                        }
                        return (
                            <UserIconWithName
                                userName={txt({
                                    en: 'Missing member',
                                    ja: '存在しないメンバー',
                                })}
                                isHot={false}
                                className={c.user}
                                iconClassName={c.userIcon}
                            />
                        );
                    })()}
                    <div className={clsx(c.actions, 'MessageCard-actions')}>
                        <ReplyButton messageApi={messageApi} setReplyingToMessageApi={setReplyingToMessageApi} />
                        <AddReactionButton
                            onSelected={(letter) => {
                                messageApi.createReaction({ letter });
                            }}
                        />
                        {userClient.id === messageApi.userId && !messageApi.canceledAt && (
                            <CancelMessageButton messageApi={messageApi} />
                        )}
                    </div>
                </div>
                <div className={c.card}>
                    {messageApi.replyToMessageId && (
                        <ReplyingToMessageCard
                            className={c.replyingTo}
                            messageId={messageApi.replyToMessageId}
                            getMessageApi={getMessageApi}
                        />
                    )}
                    <div className={c.cardBottom}>
                        <div
                            className={clsx(c.body, {
                                [c.bodyCanceled]: !!messageApi.canceledAt,
                            })}
                        >
                            <MessageBodyViewer text={messageApi.body} />
                        </div>
                        {reactionApisForLetters.length > 0 ? (
                            <div className={c.reactions}>
                                {reactionApisForLetters.map(({ letter, reactionApis }) => (
                                    <ReactionButton
                                        key={letter}
                                        letter={letter}
                                        reactionApis={reactionApis}
                                        messageApi={messageApi}
                                    />
                                ))}
                            </div>
                        ) : (
                            <div className={c.reactionsPlaceholder}></div>
                        )}
                    </div>
                </div>
            </div>
            <div
                className={clsx(c.createdAt, {
                    [c.createdAtMe]: messageApi.userId === userClient.id,
                    [c.createdAtOther]: messageApi.userId !== userClient.id,
                })}
            >
                {getDateTimeStr({
                    date: messageApi.createdAt,
                    isOnlyTimeIfToday: isLocal,
                    isNoYearForThisYear: true,
                })}
            </div>
        </div>
    );
};
MessageCard.displayName = 'RoomTemplate/MessageCard';
