import { ref } from 'firebase/database';
import { getFirebaseRealtimeDatabase } from '@/utils/GoogleCloud/firebase';

export { onValue, serverTimestamp } from 'firebase/database';

export const getConnectedRef = () => {
    const db = getFirebaseRealtimeDatabase();
    const connectedRef = ref(db, '.info/connected');
    return {
        connectedRef,
    };
};
