import { GraphQLClient } from 'graphql-request';
import * as Dom from 'graphql-request/dist/types.dom';
import gql from 'graphql-tag';
export type Maybe<T> = T | null;
export type InputMaybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  GqlDateTime: string;
  GqlMessageBody: string;
  GqlSingleLetter: string;
};

export type GqlCancelMessageInput = {
  messageId: Scalars['ID'];
  roomId: Scalars['ID'];
};

export type GqlCancelMessagePayload = {
  message: GqlMessage;
};

export type GqlCancelUserPayload = {
  canceledUser: GqlUser;
};

export type GqlCheckin = {
  createdAt: Scalars['GqlDateTime'];
  id: Scalars['ID'];
  leftAt?: Maybe<Scalars['GqlDateTime']>;
  roomId: Scalars['ID'];
  serialNumber: Scalars['Int'];
  updatedAt?: Maybe<Scalars['GqlDateTime']>;
  userId: Scalars['ID'];
};

export type GqlCloseRoomInput = {
  roomId: Scalars['ID'];
};

export type GqlCloseRoomPayload = {
  closedRoom: GqlRoom;
};

export type GqlCreateFriendshipInput = {
  friendUserCode: Scalars['ID'];
};

export type GqlCreateFriendshipPayload = {
  friendship: GqlFriendship;
};

export type GqlCreateMessageInput = {
  body: Scalars['GqlMessageBody'];
  mentionToUserIds: Array<Scalars['ID']>;
  preparedId: Scalars['String'];
  replyToMessageId?: InputMaybe<Scalars['ID']>;
  roomId: Scalars['ID'];
};

export type GqlCreateMessagePayload = {
  message: GqlMessage;
};

export type GqlCreateReactionInput = {
  letter: Scalars['GqlSingleLetter'];
  messageId: Scalars['ID'];
  preparedId: Scalars['String'];
  roomId: Scalars['ID'];
};

export type GqlCreateReactionPayload = {
  reaction: GqlReaction;
};

export type GqlCreateRingInput = {
  preparedId: Scalars['String'];
  roomId: Scalars['ID'];
  toUserId: Scalars['ID'];
};

export type GqlCreateRingPayload = {
  ring: GqlRing;
};

export type GqlCreateRoomInput = {
  members: Array<GqlUserIdAndUniqueName>;
  name: Scalars['String'];
  ownerUniqueName: Scalars['String'];
};

export type GqlCreateRoomMembershipInput = {
  newMemberUserCode: Scalars['ID'];
  roomId: Scalars['ID'];
  uniqueName?: InputMaybe<Scalars['ID']>;
};

export type GqlCreateRoomMembershipPayload = {
  membership: GqlRoomMembership;
};

export type GqlCreateRoomPayload = {
  otherMemberships: Array<GqlRoomMembership>;
  ownerMembership: GqlRoomMembership;
  room: GqlRoom;
};

export type GqlCreateUserInput = {
  uniqueName: Scalars['ID'];
};

export type GqlCreateUserPayload = {
  user: GqlUser;
};

export type GqlFriendship = {
  friendMembership: GqlRoomMembership;
  friendUser: GqlUser;
  room: GqlRoom;
  userMembership: GqlRoomMembership;
};

export type GqlGetCheckinsFilter = {
  userId?: InputMaybe<Scalars['ID']>;
};

export type GqlGetCheckinsInput = {
  filter?: InputMaybe<GqlGetCheckinsFilter>;
  limitation?: InputMaybe<GqlGetCheckinsLimitation>;
  roomId: Scalars['ID'];
};

export type GqlGetCheckinsLimitation = {
  endBeforeCreatedAt?: InputMaybe<Scalars['GqlDateTime']>;
  endBeforeSerialNumber?: InputMaybe<Scalars['Int']>;
  maxAmount?: InputMaybe<Scalars['Int']>;
  startAfterCreatedAt?: InputMaybe<Scalars['GqlDateTime']>;
  startAfterSerialNumber?: InputMaybe<Scalars['Int']>;
};

export type GqlGetCheckinsPayload = {
  checkins: Array<GqlCheckin>;
};

export type GqlGetFriendshipsPayload = {
  friendships: Array<GqlFriendship>;
};

export type GqlGetIsUserUniqueNameAvailableInput = {
  userUniqueName: Scalars['String'];
};

export type GqlGetIsUserUniqueNameAvailablePayload = {
  isAvailable: Scalars['Boolean'];
  userUniqueName: Scalars['String'];
};

export type GqlGetMessageInput = {
  messageId: Scalars['ID'];
  roomId: Scalars['ID'];
};

export type GqlGetMessagePayload = {
  message: GqlMessage;
};

export type GqlGetMessageWithChildrenInput = {
  messageId: Scalars['ID'];
  roomId: Scalars['ID'];
};

export type GqlGetMessageWithChildrenPayload = {
  messageWithChildren: GqlMessageWithChildren;
};

export type GqlGetMessageWithChildrensFilter = {
  userId?: InputMaybe<Scalars['ID']>;
};

export type GqlGetMessageWithChildrensInput = {
  filter?: InputMaybe<GqlGetMessageWithChildrensFilter>;
  limitation?: InputMaybe<GqlGetMessageWithChildrensLimitation>;
  roomId: Scalars['ID'];
};

export type GqlGetMessageWithChildrensLimitation = {
  endBeforeCreatedAt?: InputMaybe<Scalars['GqlDateTime']>;
  endBeforeSerialNumber?: InputMaybe<Scalars['Int']>;
  maxAmount?: InputMaybe<Scalars['Int']>;
  startAfterCreatedAt?: InputMaybe<Scalars['GqlDateTime']>;
  startAfterSerialNumber?: InputMaybe<Scalars['Int']>;
};

export type GqlGetMessageWithChildrensPayload = {
  messageWithChildrens: Array<GqlMessageWithChildren>;
};

export type GqlGetPrivacyPolicyPayload = {
  privacyPolicy: Scalars['String'];
};

export type GqlGetRingsFilter = {
  fromUserId?: InputMaybe<Scalars['ID']>;
  toUserId?: InputMaybe<Scalars['ID']>;
};

export type GqlGetRingsInput = {
  filter?: InputMaybe<GqlGetRingsFilter>;
  limitation?: InputMaybe<GqlGetRingsLimitation>;
  roomId: Scalars['ID'];
};

export type GqlGetRingsLimitation = {
  endBeforeCreatedAt?: InputMaybe<Scalars['GqlDateTime']>;
  endBeforeSerialNumber?: InputMaybe<Scalars['Int']>;
  maxAmount?: InputMaybe<Scalars['Int']>;
  startAfterCreatedAt?: InputMaybe<Scalars['GqlDateTime']>;
  startAfterSerialNumber?: InputMaybe<Scalars['Int']>;
};

export type GqlGetRingsPayload = {
  rings: Array<GqlRing>;
};

export type GqlGetRoomMembershipWithUsersForRoomInput = {
  roomId: Scalars['ID'];
};

export type GqlGetRoomMembershipWithUsersForRoomPayload = {
  roomMembershipWithUsers: Array<GqlRoomMembershipWithUser>;
};

export type GqlGetRoomMembershipsForRoomInput = {
  roomId: Scalars['ID'];
};

export type GqlGetRoomMembershipsForRoomPayload = {
  roomMemberships: Array<GqlRoomMembership>;
};

export type GqlGetRoomWithMembershipsForUserPayload = {
  roomWithMemberships: Array<GqlRoomWithMembership>;
};

export type GqlGetServiceHealthPayload = {
  serviceHealth: GqlServiceHealth;
};

export type GqlGetTimelineFilter = {
  userId?: InputMaybe<Scalars['ID']>;
};

export type GqlGetTimelineInput = {
  filter?: InputMaybe<GqlGetTimelineFilter>;
  limitation?: InputMaybe<GqlGetTimelineLimitation>;
  roomId: Scalars['ID'];
};

export type GqlGetTimelineLimitation = {
  endBeforeAt?: InputMaybe<Scalars['GqlDateTime']>;
  maxAmount?: InputMaybe<Scalars['Int']>;
  startAfterAt?: InputMaybe<Scalars['GqlDateTime']>;
};

export type GqlGetTimelinePayload = {
  timelineRows: Array<GqlTimelineRow>;
};

export type GqlGetTosPayload = {
  tos: Scalars['String'];
};

export type GqlGetUserByCodeInput = {
  userCode: Scalars['ID'];
};

export type GqlGetUserByCodePayload = {
  user: GqlUser;
};

export type GqlGetUserPayload = {
  user?: Maybe<GqlUser>;
};

export type GqlMessage = {
  body: Scalars['GqlMessageBody'];
  canceledAt?: Maybe<Scalars['GqlDateTime']>;
  createdAt: Scalars['GqlDateTime'];
  id: Scalars['ID'];
  mentionToUserIds: Array<Scalars['ID']>;
  replyToMessageId?: Maybe<Scalars['ID']>;
  roomId: Scalars['ID'];
  serialNumber: Scalars['Int'];
  updatedAt?: Maybe<Scalars['GqlDateTime']>;
  userId: Scalars['ID'];
};

export type GqlMessageWithChildren = {
  message: GqlMessage;
  reactions: Array<GqlReaction>;
};

export type GqlReaction = {
  createdAt: Scalars['GqlDateTime'];
  id: Scalars['ID'];
  letter: Scalars['GqlSingleLetter'];
  messageId: Scalars['ID'];
  roomId: Scalars['ID'];
  userId: Scalars['ID'];
};

export type GqlRemoveReactionInput = {
  messageId: Scalars['ID'];
  reactionId: Scalars['ID'];
  roomId: Scalars['ID'];
};

export type GqlRemoveReactionPayload = {
  removedReactionId: Scalars['ID'];
};

export type GqlRemoveRoomMembershipInput = {
  membershipId: Scalars['ID'];
  roomId: Scalars['ID'];
};

export type GqlRemoveRoomMembershipPayload = {
  removedMembershipId: Scalars['ID'];
};

export type GqlRing = {
  createdAt: Scalars['GqlDateTime'];
  fromUserId: Scalars['ID'];
  id: Scalars['ID'];
  roomId: Scalars['ID'];
  serialNumber: Scalars['Int'];
  toUserId: Scalars['ID'];
};

export type GqlRoom = {
  closedAt?: Maybe<Scalars['GqlDateTime']>;
  createdAt: Scalars['GqlDateTime'];
  id: Scalars['ID'];
  isFriendship: Scalars['Boolean'];
  name: Scalars['String'];
  updatedAt?: Maybe<Scalars['GqlDateTime']>;
};

export type GqlRoomMembership = {
  createdAt: Scalars['GqlDateTime'];
  id: Scalars['ID'];
  role: RoomMemberRole;
  roomId: Scalars['ID'];
  serialNumber: Scalars['Int'];
  uniqueName: Scalars['ID'];
  updatedAt?: Maybe<Scalars['GqlDateTime']>;
  userId: Scalars['ID'];
};

export type GqlRoomMembershipWithUser = {
  membership: GqlRoomMembership;
  user: GqlUser;
};

export type GqlRoomWithMembership = {
  membership: GqlRoomMembership;
  room: GqlRoom;
};

export type GqlServiceHealth = {
  code: ServiceHealthCode;
  message?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type GqlTimelineRow = {
  checkin?: Maybe<GqlCheckin>;
  checkout?: Maybe<GqlCheckin>;
  messageWithChildren?: Maybe<GqlMessageWithChildren>;
  ring?: Maybe<GqlRing>;
};

export type GqlUser = {
  canceledAt?: Maybe<Scalars['GqlDateTime']>;
  createdAt: Scalars['GqlDateTime'];
  id: Scalars['ID'];
  uniqueCode: Scalars['ID'];
  uniqueName: Scalars['ID'];
  updatedAt?: Maybe<Scalars['GqlDateTime']>;
};

export type GqlUserIdAndUniqueName = {
  uniqueName: Scalars['ID'];
  userCode: Scalars['ID'];
};

export type Mutation = {
  cancelMessage: GqlCancelMessagePayload;
  cancelUser: GqlCancelUserPayload;
  closeRoom: GqlCloseRoomPayload;
  createFriendship: GqlCreateFriendshipPayload;
  createMessage: GqlCreateMessagePayload;
  createReaction: GqlCreateReactionPayload;
  createRing: GqlCreateRingPayload;
  createRoom: GqlCreateRoomPayload;
  createRoomMembership: GqlCreateRoomMembershipPayload;
  createUser: GqlCreateUserPayload;
  removeReaction: GqlRemoveReactionPayload;
  removeRoomMembership: GqlRemoveRoomMembershipPayload;
};


export type MutationCancelMessageArgs = {
  input: GqlCancelMessageInput;
};


export type MutationCloseRoomArgs = {
  input: GqlCloseRoomInput;
};


export type MutationCreateFriendshipArgs = {
  input: GqlCreateFriendshipInput;
};


export type MutationCreateMessageArgs = {
  input: GqlCreateMessageInput;
};


export type MutationCreateReactionArgs = {
  input: GqlCreateReactionInput;
};


export type MutationCreateRingArgs = {
  input: GqlCreateRingInput;
};


export type MutationCreateRoomArgs = {
  input: GqlCreateRoomInput;
};


export type MutationCreateRoomMembershipArgs = {
  input: GqlCreateRoomMembershipInput;
};


export type MutationCreateUserArgs = {
  input: GqlCreateUserInput;
};


export type MutationRemoveReactionArgs = {
  input: GqlRemoveReactionInput;
};


export type MutationRemoveRoomMembershipArgs = {
  input: GqlRemoveRoomMembershipInput;
};

export type Query = {
  getCheckins: GqlGetCheckinsPayload;
  getFriendships: GqlGetFriendshipsPayload;
  getIsUserUniqueNameAvailable: GqlGetIsUserUniqueNameAvailablePayload;
  getMessage: GqlGetMessagePayload;
  getMessageWithChildren: GqlGetMessageWithChildrenPayload;
  getMessageWithChildrens: GqlGetMessageWithChildrensPayload;
  getPrivacyPolicy: GqlGetPrivacyPolicyPayload;
  getRings: GqlGetRingsPayload;
  getRoomMembershipWithUsersForRoom: GqlGetRoomMembershipWithUsersForRoomPayload;
  getRoomMembershipsForRoom: GqlGetRoomMembershipsForRoomPayload;
  getRoomWithMembershipsForUser: GqlGetRoomWithMembershipsForUserPayload;
  getServiceHealth: GqlGetServiceHealthPayload;
  getTimeline: GqlGetTimelinePayload;
  getTos: GqlGetTosPayload;
  getUser: GqlGetUserPayload;
  getUserByCode: GqlGetUserByCodePayload;
};


export type QueryGetCheckinsArgs = {
  input: GqlGetCheckinsInput;
};


export type QueryGetIsUserUniqueNameAvailableArgs = {
  input: GqlGetIsUserUniqueNameAvailableInput;
};


export type QueryGetMessageArgs = {
  input: GqlGetMessageInput;
};


export type QueryGetMessageWithChildrenArgs = {
  input: GqlGetMessageWithChildrenInput;
};


export type QueryGetMessageWithChildrensArgs = {
  input: GqlGetMessageWithChildrensInput;
};


export type QueryGetRingsArgs = {
  input: GqlGetRingsInput;
};


export type QueryGetRoomMembershipWithUsersForRoomArgs = {
  input: GqlGetRoomMembershipWithUsersForRoomInput;
};


export type QueryGetRoomMembershipsForRoomArgs = {
  input: GqlGetRoomMembershipsForRoomInput;
};


export type QueryGetTimelineArgs = {
  input: GqlGetTimelineInput;
};


export type QueryGetUserByCodeArgs = {
  input: GqlGetUserByCodeInput;
};

export type RoomMemberRole =
  | 'general'
  | 'owner';

export type ServiceHealthCode =
  | 'ok'
  | 'outOfService';

export type CancelMessageMutationVariables = Exact<{
  input: GqlCancelMessageInput;
}>;


export type CancelMessageMutation = { cancelMessage: { message: { id: string, createdAt: string, updatedAt?: string | null, serialNumber: number, userId: string, roomId: string, body: string, replyToMessageId?: string | null, canceledAt?: string | null, mentionToUserIds: Array<string> } } };

export type CreateMessageMutationVariables = Exact<{
  input: GqlCreateMessageInput;
}>;


export type CreateMessageMutation = { createMessage: { message: { id: string, createdAt: string, updatedAt?: string | null, serialNumber: number, userId: string, roomId: string, body: string, replyToMessageId?: string | null, canceledAt?: string | null, mentionToUserIds: Array<string> } } };

export type CreateReactionMutationVariables = Exact<{
  input: GqlCreateReactionInput;
}>;


export type CreateReactionMutation = { createReaction: { reaction: { id: string, createdAt: string, userId: string, roomId: string, messageId: string, letter: string } } };

export type RemoveReactionMutationVariables = Exact<{
  input: GqlRemoveReactionInput;
}>;


export type RemoveReactionMutation = { removeReaction: { removedReactionId: string } };

export type CreateRingMutationVariables = Exact<{
  input: GqlCreateRingInput;
}>;


export type CreateRingMutation = { createRing: { ring: { id: string, createdAt: string, serialNumber: number, roomId: string, fromUserId: string, toUserId: string } } };

export type CloseRoomMutationVariables = Exact<{
  input: GqlCloseRoomInput;
}>;


export type CloseRoomMutation = { closeRoom: { closedRoom: { id: string, createdAt: string, updatedAt?: string | null, name: string, closedAt?: string | null, isFriendship: boolean } } };

export type CreateFriendshipMutationVariables = Exact<{
  input: GqlCreateFriendshipInput;
}>;


export type CreateFriendshipMutation = { createFriendship: { friendship: { room: { id: string, createdAt: string, updatedAt?: string | null, name: string, closedAt?: string | null, isFriendship: boolean }, userMembership: { id: string, createdAt: string, updatedAt?: string | null, serialNumber: number, userId: string, roomId: string, role: RoomMemberRole, uniqueName: string }, friendMembership: { id: string, createdAt: string, updatedAt?: string | null, serialNumber: number, userId: string, roomId: string, role: RoomMemberRole, uniqueName: string }, friendUser: { id: string, createdAt: string, updatedAt?: string | null, uniqueName: string, uniqueCode: string, canceledAt?: string | null } } } };

export type CreateRoomMutationVariables = Exact<{
  input: GqlCreateRoomInput;
}>;


export type CreateRoomMutation = { createRoom: { room: { id: string, createdAt: string, updatedAt?: string | null, name: string, closedAt?: string | null, isFriendship: boolean }, ownerMembership: { id: string, createdAt: string, updatedAt?: string | null, serialNumber: number, userId: string, roomId: string, role: RoomMemberRole, uniqueName: string }, otherMemberships: Array<{ id: string, createdAt: string, updatedAt?: string | null, serialNumber: number, userId: string, roomId: string, role: RoomMemberRole, uniqueName: string }> } };

export type CreateRoomMembershipMutationVariables = Exact<{
  input: GqlCreateRoomMembershipInput;
}>;


export type CreateRoomMembershipMutation = { createRoomMembership: { membership: { id: string, createdAt: string, updatedAt?: string | null, serialNumber: number, userId: string, roomId: string, role: RoomMemberRole, uniqueName: string } } };

export type RemoveRoomMembershipMutationVariables = Exact<{
  input: GqlRemoveRoomMembershipInput;
}>;


export type RemoveRoomMembershipMutation = { removeRoomMembership: { removedMembershipId: string } };

export type CancelUserMutationVariables = Exact<{ [key: string]: never; }>;


export type CancelUserMutation = { cancelUser: { canceledUser: { id: string, createdAt: string, updatedAt?: string | null, uniqueName: string, uniqueCode: string, canceledAt?: string | null } } };

export type CreateUserMutationVariables = Exact<{
  input: GqlCreateUserInput;
}>;


export type CreateUserMutation = { createUser: { user: { id: string, createdAt: string, updatedAt?: string | null, uniqueName: string, uniqueCode: string, canceledAt?: string | null } } };

export type GetCheckinsQueryVariables = Exact<{
  input: GqlGetCheckinsInput;
}>;


export type GetCheckinsQuery = { getCheckins: { checkins: Array<{ id: string, createdAt: string, updatedAt?: string | null, serialNumber: number, userId: string, roomId: string, leftAt?: string | null }> } };

export type GetFriendshipsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetFriendshipsQuery = { getFriendships: { friendships: Array<{ room: { id: string, createdAt: string, updatedAt?: string | null, name: string, closedAt?: string | null, isFriendship: boolean }, userMembership: { id: string, createdAt: string, updatedAt?: string | null, serialNumber: number, userId: string, roomId: string, role: RoomMemberRole, uniqueName: string }, friendMembership: { id: string, createdAt: string, updatedAt?: string | null, serialNumber: number, userId: string, roomId: string, role: RoomMemberRole, uniqueName: string }, friendUser: { id: string, createdAt: string, updatedAt?: string | null, uniqueName: string, uniqueCode: string, canceledAt?: string | null } }> } };

export type GetIsUserUniqueNameAvailableQueryVariables = Exact<{
  input: GqlGetIsUserUniqueNameAvailableInput;
}>;


export type GetIsUserUniqueNameAvailableQuery = { getIsUserUniqueNameAvailable: { userUniqueName: string, isAvailable: boolean } };

export type GetMessageQueryVariables = Exact<{
  input: GqlGetMessageInput;
}>;


export type GetMessageQuery = { getMessage: { message: { id: string, createdAt: string, updatedAt?: string | null, serialNumber: number, userId: string, roomId: string, body: string, replyToMessageId?: string | null, canceledAt?: string | null, mentionToUserIds: Array<string> } } };

export type GetMessageWithChildrenQueryVariables = Exact<{
  input: GqlGetMessageWithChildrenInput;
}>;


export type GetMessageWithChildrenQuery = { getMessageWithChildren: { messageWithChildren: { message: { id: string, createdAt: string, updatedAt?: string | null, serialNumber: number, userId: string, roomId: string, body: string, replyToMessageId?: string | null, canceledAt?: string | null, mentionToUserIds: Array<string> }, reactions: Array<{ id: string, createdAt: string, userId: string, roomId: string, messageId: string, letter: string }> } } };

export type GetMessageWithChildrensQueryVariables = Exact<{
  input: GqlGetMessageWithChildrensInput;
}>;


export type GetMessageWithChildrensQuery = { getMessageWithChildrens: { messageWithChildrens: Array<{ message: { id: string, createdAt: string, updatedAt?: string | null, serialNumber: number, userId: string, roomId: string, body: string, replyToMessageId?: string | null, canceledAt?: string | null, mentionToUserIds: Array<string> }, reactions: Array<{ id: string, createdAt: string, userId: string, roomId: string, messageId: string, letter: string }> }> } };

export type GetPrivacyPolicyQueryVariables = Exact<{ [key: string]: never; }>;


export type GetPrivacyPolicyQuery = { getPrivacyPolicy: { privacyPolicy: string } };

export type GetRingsQueryVariables = Exact<{
  input: GqlGetRingsInput;
}>;


export type GetRingsQuery = { getRings: { rings: Array<{ id: string, createdAt: string, serialNumber: number, roomId: string, fromUserId: string, toUserId: string }> } };

export type GetRoomMembershipWithUsersForRoomQueryVariables = Exact<{
  input: GqlGetRoomMembershipWithUsersForRoomInput;
}>;


export type GetRoomMembershipWithUsersForRoomQuery = { getRoomMembershipWithUsersForRoom: { roomMembershipWithUsers: Array<{ membership: { id: string, createdAt: string, updatedAt?: string | null, serialNumber: number, userId: string, roomId: string, role: RoomMemberRole, uniqueName: string }, user: { id: string, createdAt: string, updatedAt?: string | null, uniqueName: string, uniqueCode: string, canceledAt?: string | null } }> } };

export type GetRoomMembershipsForRoomQueryVariables = Exact<{
  input: GqlGetRoomMembershipsForRoomInput;
}>;


export type GetRoomMembershipsForRoomQuery = { getRoomMembershipsForRoom: { roomMemberships: Array<{ id: string, createdAt: string, updatedAt?: string | null, serialNumber: number, userId: string, roomId: string, role: RoomMemberRole, uniqueName: string }> } };

export type GetRoomWithMembershipsForUserQueryVariables = Exact<{ [key: string]: never; }>;


export type GetRoomWithMembershipsForUserQuery = { getRoomWithMembershipsForUser: { roomWithMemberships: Array<{ room: { id: string, createdAt: string, updatedAt?: string | null, name: string, closedAt?: string | null, isFriendship: boolean }, membership: { id: string, createdAt: string, updatedAt?: string | null, serialNumber: number, userId: string, roomId: string, role: RoomMemberRole, uniqueName: string } }> } };

export type GetServiceHealthQueryVariables = Exact<{ [key: string]: never; }>;


export type GetServiceHealthQuery = { getServiceHealth: { serviceHealth: { code: ServiceHealthCode, message?: string | null, url?: string | null } } };

export type GetTimelineQueryVariables = Exact<{
  input: GqlGetTimelineInput;
}>;


export type GetTimelineQuery = { getTimeline: { timelineRows: Array<{ messageWithChildren?: { message: { id: string, createdAt: string, updatedAt?: string | null, serialNumber: number, userId: string, roomId: string, body: string, replyToMessageId?: string | null, canceledAt?: string | null, mentionToUserIds: Array<string> }, reactions: Array<{ id: string, createdAt: string, userId: string, roomId: string, messageId: string, letter: string }> } | null, ring?: { id: string, createdAt: string, serialNumber: number, roomId: string, fromUserId: string, toUserId: string } | null, checkin?: { id: string, createdAt: string, updatedAt?: string | null, serialNumber: number, userId: string, roomId: string, leftAt?: string | null } | null, checkout?: { id: string, createdAt: string, updatedAt?: string | null, serialNumber: number, userId: string, roomId: string, leftAt?: string | null } | null }> } };

export type GetTosQueryVariables = Exact<{ [key: string]: never; }>;


export type GetTosQuery = { getTos: { tos: string } };

export type GetUserQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUserQuery = { getUser: { user?: { id: string, createdAt: string, updatedAt?: string | null, uniqueName: string, uniqueCode: string, canceledAt?: string | null } | null } };

export type GetUserByCodeQueryVariables = Exact<{
  input: GqlGetUserByCodeInput;
}>;


export type GetUserByCodeQuery = { getUserByCode: { user: { id: string, createdAt: string, updatedAt?: string | null, uniqueName: string, uniqueCode: string, canceledAt?: string | null } } };


export const CancelMessageDocument = gql`
    mutation cancelMessage($input: GqlCancelMessageInput!) {
  cancelMessage(input: $input) {
    message {
      id
      createdAt
      updatedAt
      serialNumber
      userId
      roomId
      body
      replyToMessageId
      canceledAt
      mentionToUserIds
    }
  }
}
    `;
export const CreateMessageDocument = gql`
    mutation createMessage($input: GqlCreateMessageInput!) {
  createMessage(input: $input) {
    message {
      id
      createdAt
      updatedAt
      serialNumber
      userId
      roomId
      body
      replyToMessageId
      canceledAt
      mentionToUserIds
    }
  }
}
    `;
export const CreateReactionDocument = gql`
    mutation createReaction($input: GqlCreateReactionInput!) {
  createReaction(input: $input) {
    reaction {
      id
      createdAt
      userId
      roomId
      messageId
      letter
    }
  }
}
    `;
export const RemoveReactionDocument = gql`
    mutation removeReaction($input: GqlRemoveReactionInput!) {
  removeReaction(input: $input) {
    removedReactionId
  }
}
    `;
export const CreateRingDocument = gql`
    mutation createRing($input: GqlCreateRingInput!) {
  createRing(input: $input) {
    ring {
      id
      createdAt
      serialNumber
      roomId
      fromUserId
      toUserId
    }
  }
}
    `;
export const CloseRoomDocument = gql`
    mutation closeRoom($input: GqlCloseRoomInput!) {
  closeRoom(input: $input) {
    closedRoom {
      id
      createdAt
      updatedAt
      name
      closedAt
      isFriendship
    }
  }
}
    `;
export const CreateFriendshipDocument = gql`
    mutation createFriendship($input: GqlCreateFriendshipInput!) {
  createFriendship(input: $input) {
    friendship {
      room {
        id
        createdAt
        updatedAt
        name
        closedAt
        isFriendship
      }
      userMembership {
        id
        createdAt
        updatedAt
        serialNumber
        userId
        roomId
        role
        uniqueName
      }
      friendMembership {
        id
        createdAt
        updatedAt
        serialNumber
        userId
        roomId
        role
        uniqueName
      }
      friendUser {
        id
        createdAt
        updatedAt
        uniqueName
        uniqueCode
        canceledAt
      }
    }
  }
}
    `;
export const CreateRoomDocument = gql`
    mutation createRoom($input: GqlCreateRoomInput!) {
  createRoom(input: $input) {
    room {
      id
      createdAt
      updatedAt
      name
      closedAt
      isFriendship
    }
    ownerMembership {
      id
      createdAt
      updatedAt
      serialNumber
      userId
      roomId
      role
      uniqueName
    }
    otherMemberships {
      id
      createdAt
      updatedAt
      serialNumber
      userId
      roomId
      role
      uniqueName
    }
  }
}
    `;
export const CreateRoomMembershipDocument = gql`
    mutation createRoomMembership($input: GqlCreateRoomMembershipInput!) {
  createRoomMembership(input: $input) {
    membership {
      id
      createdAt
      updatedAt
      serialNumber
      userId
      roomId
      role
      uniqueName
    }
  }
}
    `;
export const RemoveRoomMembershipDocument = gql`
    mutation removeRoomMembership($input: GqlRemoveRoomMembershipInput!) {
  removeRoomMembership(input: $input) {
    removedMembershipId
  }
}
    `;
export const CancelUserDocument = gql`
    mutation cancelUser {
  cancelUser {
    canceledUser {
      id
      createdAt
      updatedAt
      uniqueName
      uniqueCode
      canceledAt
    }
  }
}
    `;
export const CreateUserDocument = gql`
    mutation createUser($input: GqlCreateUserInput!) {
  createUser(input: $input) {
    user {
      id
      createdAt
      updatedAt
      uniqueName
      uniqueCode
      canceledAt
    }
  }
}
    `;
export const GetCheckinsDocument = gql`
    query getCheckins($input: GqlGetCheckinsInput!) {
  getCheckins(input: $input) {
    checkins {
      id
      createdAt
      updatedAt
      serialNumber
      userId
      roomId
      leftAt
    }
  }
}
    `;
export const GetFriendshipsDocument = gql`
    query getFriendships {
  getFriendships {
    friendships {
      room {
        id
        createdAt
        updatedAt
        name
        closedAt
        isFriendship
      }
      userMembership {
        id
        createdAt
        updatedAt
        serialNumber
        userId
        roomId
        role
        uniqueName
      }
      friendMembership {
        id
        createdAt
        updatedAt
        serialNumber
        userId
        roomId
        role
        uniqueName
      }
      friendUser {
        id
        createdAt
        updatedAt
        uniqueName
        uniqueCode
        canceledAt
      }
    }
  }
}
    `;
export const GetIsUserUniqueNameAvailableDocument = gql`
    query getIsUserUniqueNameAvailable($input: GqlGetIsUserUniqueNameAvailableInput!) {
  getIsUserUniqueNameAvailable(input: $input) {
    userUniqueName
    isAvailable
  }
}
    `;
export const GetMessageDocument = gql`
    query getMessage($input: GqlGetMessageInput!) {
  getMessage(input: $input) {
    message {
      id
      createdAt
      updatedAt
      serialNumber
      userId
      roomId
      body
      replyToMessageId
      canceledAt
      mentionToUserIds
    }
  }
}
    `;
export const GetMessageWithChildrenDocument = gql`
    query getMessageWithChildren($input: GqlGetMessageWithChildrenInput!) {
  getMessageWithChildren(input: $input) {
    messageWithChildren {
      message {
        id
        createdAt
        updatedAt
        serialNumber
        userId
        roomId
        body
        replyToMessageId
        canceledAt
        mentionToUserIds
      }
      reactions {
        id
        createdAt
        userId
        roomId
        messageId
        letter
      }
    }
  }
}
    `;
export const GetMessageWithChildrensDocument = gql`
    query getMessageWithChildrens($input: GqlGetMessageWithChildrensInput!) {
  getMessageWithChildrens(input: $input) {
    messageWithChildrens {
      message {
        id
        createdAt
        updatedAt
        serialNumber
        userId
        roomId
        body
        replyToMessageId
        canceledAt
        mentionToUserIds
      }
      reactions {
        id
        createdAt
        userId
        roomId
        messageId
        letter
      }
    }
  }
}
    `;
export const GetPrivacyPolicyDocument = gql`
    query getPrivacyPolicy {
  getPrivacyPolicy {
    privacyPolicy
  }
}
    `;
export const GetRingsDocument = gql`
    query getRings($input: GqlGetRingsInput!) {
  getRings(input: $input) {
    rings {
      id
      createdAt
      serialNumber
      roomId
      fromUserId
      toUserId
    }
  }
}
    `;
export const GetRoomMembershipWithUsersForRoomDocument = gql`
    query getRoomMembershipWithUsersForRoom($input: GqlGetRoomMembershipWithUsersForRoomInput!) {
  getRoomMembershipWithUsersForRoom(input: $input) {
    roomMembershipWithUsers {
      membership {
        id
        createdAt
        updatedAt
        serialNumber
        userId
        roomId
        role
        uniqueName
      }
      user {
        id
        createdAt
        updatedAt
        uniqueName
        uniqueCode
        canceledAt
      }
    }
  }
}
    `;
export const GetRoomMembershipsForRoomDocument = gql`
    query getRoomMembershipsForRoom($input: GqlGetRoomMembershipsForRoomInput!) {
  getRoomMembershipsForRoom(input: $input) {
    roomMemberships {
      id
      createdAt
      updatedAt
      serialNumber
      userId
      roomId
      role
      uniqueName
    }
  }
}
    `;
export const GetRoomWithMembershipsForUserDocument = gql`
    query getRoomWithMembershipsForUser {
  getRoomWithMembershipsForUser {
    roomWithMemberships {
      room {
        id
        createdAt
        updatedAt
        name
        closedAt
        isFriendship
      }
      membership {
        id
        createdAt
        updatedAt
        serialNumber
        userId
        roomId
        role
        uniqueName
      }
    }
  }
}
    `;
export const GetServiceHealthDocument = gql`
    query getServiceHealth {
  getServiceHealth {
    serviceHealth {
      code
      message
      url
    }
  }
}
    `;
export const GetTimelineDocument = gql`
    query getTimeline($input: GqlGetTimelineInput!) {
  getTimeline(input: $input) {
    timelineRows {
      messageWithChildren {
        message {
          id
          createdAt
          updatedAt
          serialNumber
          userId
          roomId
          body
          replyToMessageId
          canceledAt
          mentionToUserIds
        }
        reactions {
          id
          createdAt
          userId
          roomId
          messageId
          letter
        }
      }
      ring {
        id
        createdAt
        serialNumber
        roomId
        fromUserId
        toUserId
      }
      checkin {
        id
        createdAt
        updatedAt
        serialNumber
        userId
        roomId
        leftAt
      }
      checkout {
        id
        createdAt
        updatedAt
        serialNumber
        userId
        roomId
        leftAt
      }
    }
  }
}
    `;
export const GetTosDocument = gql`
    query getTos {
  getTos {
    tos
  }
}
    `;
export const GetUserDocument = gql`
    query getUser {
  getUser {
    user {
      id
      createdAt
      updatedAt
      uniqueName
      uniqueCode
      canceledAt
    }
  }
}
    `;
export const GetUserByCodeDocument = gql`
    query getUserByCode($input: GqlGetUserByCodeInput!) {
  getUserByCode(input: $input) {
    user {
      id
      createdAt
      updatedAt
      uniqueName
      uniqueCode
      canceledAt
    }
  }
}
    `;

export type SdkFunctionWrapper = <T>(action: (requestHeaders?:Record<string, string>) => Promise<T>, operationName: string) => Promise<T>;


const defaultWrapper: SdkFunctionWrapper = (action, _operationName) => action();

export function getSdk(client: GraphQLClient, withWrapper: SdkFunctionWrapper = defaultWrapper) {
  return {
    cancelMessage(variables: CancelMessageMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CancelMessageMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<CancelMessageMutation>(CancelMessageDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'cancelMessage');
    },
    createMessage(variables: CreateMessageMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CreateMessageMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<CreateMessageMutation>(CreateMessageDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'createMessage');
    },
    createReaction(variables: CreateReactionMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CreateReactionMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<CreateReactionMutation>(CreateReactionDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'createReaction');
    },
    removeReaction(variables: RemoveReactionMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<RemoveReactionMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<RemoveReactionMutation>(RemoveReactionDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'removeReaction');
    },
    createRing(variables: CreateRingMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CreateRingMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<CreateRingMutation>(CreateRingDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'createRing');
    },
    closeRoom(variables: CloseRoomMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CloseRoomMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<CloseRoomMutation>(CloseRoomDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'closeRoom');
    },
    createFriendship(variables: CreateFriendshipMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CreateFriendshipMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<CreateFriendshipMutation>(CreateFriendshipDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'createFriendship');
    },
    createRoom(variables: CreateRoomMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CreateRoomMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<CreateRoomMutation>(CreateRoomDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'createRoom');
    },
    createRoomMembership(variables: CreateRoomMembershipMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CreateRoomMembershipMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<CreateRoomMembershipMutation>(CreateRoomMembershipDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'createRoomMembership');
    },
    removeRoomMembership(variables: RemoveRoomMembershipMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<RemoveRoomMembershipMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<RemoveRoomMembershipMutation>(RemoveRoomMembershipDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'removeRoomMembership');
    },
    cancelUser(variables?: CancelUserMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CancelUserMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<CancelUserMutation>(CancelUserDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'cancelUser');
    },
    createUser(variables: CreateUserMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CreateUserMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<CreateUserMutation>(CreateUserDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'createUser');
    },
    getCheckins(variables: GetCheckinsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetCheckinsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetCheckinsQuery>(GetCheckinsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getCheckins');
    },
    getFriendships(variables?: GetFriendshipsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetFriendshipsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetFriendshipsQuery>(GetFriendshipsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getFriendships');
    },
    getIsUserUniqueNameAvailable(variables: GetIsUserUniqueNameAvailableQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetIsUserUniqueNameAvailableQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetIsUserUniqueNameAvailableQuery>(GetIsUserUniqueNameAvailableDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getIsUserUniqueNameAvailable');
    },
    getMessage(variables: GetMessageQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetMessageQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetMessageQuery>(GetMessageDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getMessage');
    },
    getMessageWithChildren(variables: GetMessageWithChildrenQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetMessageWithChildrenQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetMessageWithChildrenQuery>(GetMessageWithChildrenDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getMessageWithChildren');
    },
    getMessageWithChildrens(variables: GetMessageWithChildrensQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetMessageWithChildrensQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetMessageWithChildrensQuery>(GetMessageWithChildrensDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getMessageWithChildrens');
    },
    getPrivacyPolicy(variables?: GetPrivacyPolicyQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetPrivacyPolicyQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetPrivacyPolicyQuery>(GetPrivacyPolicyDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getPrivacyPolicy');
    },
    getRings(variables: GetRingsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetRingsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetRingsQuery>(GetRingsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getRings');
    },
    getRoomMembershipWithUsersForRoom(variables: GetRoomMembershipWithUsersForRoomQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetRoomMembershipWithUsersForRoomQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetRoomMembershipWithUsersForRoomQuery>(GetRoomMembershipWithUsersForRoomDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getRoomMembershipWithUsersForRoom');
    },
    getRoomMembershipsForRoom(variables: GetRoomMembershipsForRoomQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetRoomMembershipsForRoomQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetRoomMembershipsForRoomQuery>(GetRoomMembershipsForRoomDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getRoomMembershipsForRoom');
    },
    getRoomWithMembershipsForUser(variables?: GetRoomWithMembershipsForUserQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetRoomWithMembershipsForUserQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetRoomWithMembershipsForUserQuery>(GetRoomWithMembershipsForUserDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getRoomWithMembershipsForUser');
    },
    getServiceHealth(variables?: GetServiceHealthQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetServiceHealthQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetServiceHealthQuery>(GetServiceHealthDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getServiceHealth');
    },
    getTimeline(variables: GetTimelineQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetTimelineQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetTimelineQuery>(GetTimelineDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getTimeline');
    },
    getTos(variables?: GetTosQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetTosQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetTosQuery>(GetTosDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getTos');
    },
    getUser(variables?: GetUserQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetUserQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetUserQuery>(GetUserDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getUser');
    },
    getUserByCode(variables: GetUserByCodeQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetUserByCodeQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetUserByCodeQuery>(GetUserByCodeDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getUserByCode');
    }
  };
}
export type Sdk = ReturnType<typeof getSdk>;