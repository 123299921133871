import { getGraphqlSdk } from '@/utils/PowerchatClient/graphql/graphqlSdk';
import { PowerchatUserClient } from '@/utils/PowerchatClient/clients';
import { getUserFromGql } from '@/utils/PowerchatClient/models';
import { Language } from '@/utils/customHooks';

export const initPowerchatUserClient = async (input: {
    firebaseUid: string;
    firebaseUserIdToken: string;
    fcmToken: string;
    language: Language | undefined;
}) => {
    const graphqlSdk = getGraphqlSdk(input);
    const {
        getUser: { user },
    } = await graphqlSdk.getUser().catch(({ message }) => {
        console.error('message->', message);
        throw new Error('initPowerchatUserClient: invalid firebaseUserIdToken');
    });
    if (user) {
        return new PowerchatUserClient({
            graphqlSdk,
            currentFcmToken: input.fcmToken,
            language: input.language,
            ...getUserFromGql(user),
        });
    }
    throw new Error('initPowerchatUserClient: No user account');
};
