import { FC } from 'react';
import clsx from 'clsx';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import MuiTypography from '@material-ui/core/Typography';

import { LocalCheckinType } from '@/utils/PowerchatClient';
import { useRoomMembersCtx } from '@/utils/ctxs';
import { useLanguage } from '@/utils/customHooks';
import { UserIconWithName } from '@/components/2_org/UserIconWithName';

const useStyles = makeStyles((theme) => ({
    root: {
        // display: 'flex',
        marginTop: 10,
    },
    // isDisplayAsIn:{
    //     justifyContent:''
    // },isDisplayAsIn:{
    //     justifyContent:''
    // },
    inner: {
        display: 'flex',
        alignItems: 'center',
        width: '80%',
    },
    user: {
        fontSize: 10,
        marginRight: 6,
        flexGrow: 0,
        flexShrink: 0,
    },
    userIn: {},
    leftCheckinUser: {
        opacity: 0.5,
    },
    icon: {
        width: 18,
        height: 18,
        marginRight: 5,
    },
    text: {
        color: theme.palette.text.disabled,
    },
    hr: {
        margin: 0,
        flexGrow: 1,
        flexShrink: 0,
        border: 'none',
        background: 'rgba(0,0,0,.03)',
        height: 2,
    },
    hrIn: {},
    hrOut: {},
    content: {},
    contentIn: {
        paddingLeft: 8,
    },
    contentOut: {
        paddingRight: 12,
    },
}));

export const CheckinCard: FC<{
    className?: string;
    checkinData: LocalCheckinType;
    isLocal: boolean;
    isDisplayAsIn: boolean;
}> = ({ className, checkinData, isLocal, isDisplayAsIn }) => {
    // STYLE
    const c = useStyles(useTheme());

    // HOOKS
    const { getRoomMemberItem } = useRoomMembersCtx();
    const { txt, getDateTimeStr } = useLanguage();

    // DATA
    const roomMemberItem = getRoomMemberItem(checkinData.userId);

    return (
        <div
            className={clsx(c.root, className, {
                // [c.isDisplayAsIn]:isDisplayAsIn,
                // [c.isDisplayAsOut]:!isDisplayAsIn
            })}
        >
            <div className={c.inner}>
                {isDisplayAsIn && <hr className={clsx(c.hr, c.hrOut)} />}
                {roomMemberItem && (
                    <div
                        className={clsx(c.content, {
                            [c.contentIn]: isDisplayAsIn,
                            [c.contentOut]: !isDisplayAsIn,
                        })}
                    >
                        <UserIconWithName
                            userName={roomMemberItem.user.uniqueName}
                            isHot={!checkinData.leftAt && roomMemberItem.rdbUser?.currentRoomId === checkinData.roomId}
                            className={clsx(c.user, {
                                [c.leftCheckinUser]: !!checkinData.leftAt,
                            })}
                            iconClassName={c.icon}
                        />
                        <MuiTypography className={c.text} variant={'caption'}>
                            {isDisplayAsIn
                                ? txt({
                                      en: `checked in at ${getDateTimeStr({
                                          date: checkinData.createdAt,
                                          isOnlyTimeIfToday: isLocal,
                                          isNoYearForThisYear: true,
                                      })}`,
                                      ja: `が ${getDateTimeStr({
                                          date: checkinData.createdAt,
                                          isOnlyTimeIfToday: isLocal,
                                          isNoYearForThisYear: true,
                                      })} にチェックイン`,
                                  })
                                : checkinData.leftAt
                                ? txt({
                                      en: `checked out at ${getDateTimeStr({
                                          date: checkinData.leftAt,
                                          isOnlyTimeIfToday: isLocal,
                                          isNoYearForThisYear: true,
                                      })}`,
                                      ja: `が ${getDateTimeStr({
                                          date: checkinData.leftAt,
                                          isOnlyTimeIfToday: isLocal,
                                          isNoYearForThisYear: true,
                                      })} にチェックアウト`,
                                  })
                                : 'never'}
                        </MuiTypography>
                    </div>
                )}
                {!isDisplayAsIn && <hr className={clsx(c.hr, c.hrIn)} />}
            </div>
        </div>
    );
};
CheckinCard.displayName = 'RoomTemplate/CheckinCard';
