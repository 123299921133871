import { serverTimestamp } from 'firebase/database';
import { Language } from '@/utils/customHooks/useLanguage';

export type RdbUser = {
    id: string;
    updatedAt: number;
    isOnline: boolean;
    userUniqueName: string;
    currentRoomId: string | undefined;
    currentRoomName: string | undefined;
    language: Language | undefined;
};
export type RdbUserInput = {
    updatedAt: Date | 'serverTimestamp';
    isOnline: boolean;
    userUniqueName: string;
    currentRoomId: string | undefined;
    currentRoomName: string | undefined;
    language: Language | undefined;
    currentFcmToken: string | undefined;
};

export const getRdbUserInput = ({
    updatedAt,
    isOnline,
    userUniqueName,
    currentRoomId,
    currentRoomName,
    language,
    currentFcmToken,
}: RdbUserInput) => {
    return {
        updatedAt: updatedAt === 'serverTimestamp' ? serverTimestamp() : updatedAt.getTime(),
        isOnline,
        userUniqueName,
        currentRoomId: currentRoomId || null,
        currentRoomName: currentRoomName || null,
        language: language || null,
        currentFcmToken: currentFcmToken || null,
    };
};
