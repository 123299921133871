import { createContext, useContext } from 'react';

type ErrorCtxType = {
    is404: boolean;
    is500: boolean;
    setIs404: (is404: boolean) => void;
    setIs500: (is500: boolean) => void;
};
export const ErrorCtx = createContext<ErrorCtxType>({
    is404: false,
    is500: false,
    setIs404: () => undefined,
    setIs500: () => undefined,
});
export const useErrorCtx = () => {
    return useContext(ErrorCtx);
};
