import { GraphQLSdk } from '@/utils/PowerchatClient/graphql/graphqlSdk';
import {
    User,
    UserType,
    getUserFromGql,
    RoomApi,
    RoomMembership,
    RoomMembershipApi,
    getRoomFromGql,
    getRoomMembershipFromGql,
} from '@/utils/PowerchatClient/models';
import { startConnectingRealtimeDatabase, updateCurrentRoomId } from '@/utils/PowerchatClient/RealtimeDatabase';
import { Language } from '@/utils/customHooks';

type PowerchatUserClientType = {
    getRoomWithMembershipsForUser: () => Promise<
        {
            roomApi: RoomApi;
            membershipApi: RoomMembershipApi;
        }[]
    >;
    getFriendships: () => Promise<
        {
            roomApi: RoomApi;
            userMembership: RoomMembership;
            friendMembership: RoomMembership;
            friendUser: User;
        }[]
    >;
    getUserByCode: (input: { userCode: string }) => Promise<{
        user: User;
    }>;
    createRoom: (input: { roomName: string; members: { userCode: string; uniqueName: string }[] }) => Promise<{
        roomApi: RoomApi;
        membershipApi: RoomMembershipApi;
    }>;
    createFriendship: (input: { friendUserCode: string }) => Promise<{
        roomApi: RoomApi;
        userMembership: RoomMembership;
        friendMembership: RoomMembership;
        friendUser: User;
    }>;
    startConnectingRealtimeDatabase: (input: {
        initialRoomId: string | undefined;
        initialRoomName: string | undefined;
        onConnectionChanged: (input: { isOnline: boolean }) => void;
    }) => void;
    updateCurrentRoomId: (input: { currentRoomId: string | undefined; currentRoomName: string | undefined }) => void;
};

type ConstructorInput = UserType & {
    graphqlSdk: GraphQLSdk;
    currentFcmToken: string;
    language: Language | undefined;
};

export class PowerchatUserClient extends User implements PowerchatUserClientType {
    protected _graphqlSdk: GraphQLSdk;

    readonly currentFcmToken: string;

    isConnectingRealtimeDatabase: boolean;

    language: Language | undefined;

    constructor(input: ConstructorInput) {
        super(input);
        this._graphqlSdk = input.graphqlSdk;
        this.currentFcmToken = input.currentFcmToken;
        this.isConnectingRealtimeDatabase = false;
        this.language = input.language;
    }

    async getRoomWithMembershipsForUser() {
        const {
            getRoomWithMembershipsForUser: { roomWithMemberships },
        } = await this._graphqlSdk.getRoomWithMembershipsForUser();
        return roomWithMemberships.map(({ room, membership }) => ({
            roomApi: new RoomApi({
                graphqlSdk: this._graphqlSdk,
                currentFcmToken: this.currentFcmToken,
                clientUser: this,
                ...getRoomFromGql(room),
            }),
            membershipApi: new RoomMembershipApi({
                graphqlSdk: this._graphqlSdk,
                clientUser: this,
                room: getRoomFromGql(room),
                currentFcmToken: this.currentFcmToken,
                ...getRoomMembershipFromGql(membership),
            }),
        }));
    }

    async getFriendships() {
        const {
            getFriendships: { friendships },
        } = await this._graphqlSdk.getFriendships();
        return friendships.map(({ room, userMembership, friendMembership, friendUser }) => ({
            roomApi: new RoomApi({
                graphqlSdk: this._graphqlSdk,
                currentFcmToken: this.currentFcmToken,
                clientUser: this,
                ...getRoomFromGql(room),
            }),
            userMembership: getRoomMembershipFromGql(userMembership),
            friendMembership: getRoomMembershipFromGql(friendMembership),
            friendUser: getUserFromGql(friendUser),
        }));
    }

    async getUserByCode({ userCode }: { userCode: string }) {
        const {
            getUserByCode: { user },
        } = await this._graphqlSdk.getUserByCode({
            input: { userCode },
        });
        return {
            user: getUserFromGql(user),
        };
    }

    async createRoom({ roomName, members }: { roomName: string; members: { userCode: string; uniqueName: string }[] }) {
        const {
            createRoom: { room, ownerMembership },
        } = await this._graphqlSdk.createRoom({
            input: {
                name: roomName,
                members,
                ownerUniqueName: this.uniqueName,
            },
        });
        return {
            roomApi: new RoomApi({
                graphqlSdk: this._graphqlSdk,
                currentFcmToken: this.currentFcmToken,
                clientUser: this,
                ...getRoomFromGql(room),
            }),
            membershipApi: new RoomMembershipApi({
                graphqlSdk: this._graphqlSdk,
                clientUser: this,
                room: getRoomFromGql(room),
                currentFcmToken: this.currentFcmToken,
                ...getRoomMembershipFromGql(ownerMembership),
            }),
        };
    }

    async createFriendship({ friendUserCode }: { friendUserCode: string }) {
        const {
            createFriendship: {
                friendship: { room, userMembership, friendMembership, friendUser },
            },
        } = await this._graphqlSdk.createFriendship({
            input: {
                friendUserCode,
            },
        });
        return {
            roomApi: new RoomApi({
                graphqlSdk: this._graphqlSdk,
                currentFcmToken: this.currentFcmToken,
                clientUser: this,
                ...getRoomFromGql(room),
            }),
            userMembership: getRoomMembershipFromGql(userMembership),
            friendMembership: getRoomMembershipFromGql(friendMembership),
            friendUser: getUserFromGql(friendUser),
        };
    }

    startConnectingRealtimeDatabase({
        initialRoomId,
        initialRoomName,
        onConnectionChanged,
    }: {
        initialRoomId: string | undefined;
        initialRoomName: string | undefined;
        onConnectionChanged: (input: { isOnline: boolean }) => void;
    }) {
        if (this.isConnectingRealtimeDatabase) {
            console.error('PowerchatUserClient: already connected to RealtimeDatabase.');
            return;
        }
        startConnectingRealtimeDatabase({
            userId: this.id,
            userUniqueName: this.uniqueName,
            initialRoomId,
            initialRoomName,
            currentFcmToken: this.currentFcmToken,
            onChanged: ({ isOnline }) => {
                onConnectionChanged({ isOnline });
                this.isConnectingRealtimeDatabase = isOnline;
            },
            language: this.language,
        });
        this.isConnectingRealtimeDatabase = true;
    }

    updateCurrentRoomId({
        currentRoomId,
        currentRoomName,
    }: {
        currentRoomId: string | undefined;
        currentRoomName: string | undefined;
    }) {
        updateCurrentRoomId({
            currentRoomId,
            currentRoomName,
            currentFcmToken: this.currentFcmToken,
            userId: this.id,
            userUniqueName: this.uniqueName,
            language: this.language,
        });
    }
}
