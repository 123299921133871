import { ref, onValue } from 'firebase/database';
import { getFirebaseRealtimeDatabase } from '@/utils/GoogleCloud/firebase';
import { Ring } from '@/utils/PowerchatClient/models';
import { RdbLastRing } from '@/utils/PowerchatClient/RealtimeDatabase/RdbModels';

export const listenToRings = ({ roomId, onAdded }: { roomId: string; onAdded: (input: { newRing: Ring }) => void }) => {
    const db = getFirebaseRealtimeDatabase();
    const lastRingRef = ref(db, `lastRings/${roomId}`);
    const firedAt = new Date();
    const unsubscribeListenToRings = onValue(lastRingRef, (data) => {
        if (data.exists()) {
            const { action, ...ringData }: RdbLastRing = data.val();
            const ring = new Ring({
                id: ringData.id,
                createdAt: new Date(ringData.createdAt),
                serialNumber: undefined,
                roomId,
                fromUserId: ringData.fromUserId,
                toUserId: ringData.toUserId,
            });
            if (firedAt <= ring.createdAt && action === 'add') {
                onAdded({
                    newRing: ring,
                });
            }
        }
    });
    return {
        unsubscribeListenToRings,
    };
};
