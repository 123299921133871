import { useState, useReducer, useEffect } from 'react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type RecordWithId = Record<string, any> & {
    id: string;
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type ItemType<T extends Record<string, any>> = T & {
    id: string;
};

// const initIds = <T extends RecordWithId>(items?: ItemType<T>[]) => items?.map(({ id }) => id);
const initItems = <T extends RecordWithId>(items?: ItemType<T>[]) => items;

export const useLazyItems = <T extends RecordWithId>(items?: ItemType<T>[]) => {
    // HOOKS
    // const [ids, dispatchIds] = useReducer<
    //     (
    //         state: string[] | undefined,
    //         input: {
    //             add?: { id: string; isIgnoreDuplicationError?: boolean; isAddToTop?: boolean };
    //             remove?: { id: string };
    //             reset?: { items?: ItemType<T>[] };
    //         }
    //     ) => string[] | undefined,
    //     ItemType<T>[] | undefined
    // >(
    //     (state, { add, remove, reset }) => {
    //         if (add) {
    //             const sameIdItem = (state || []).find((item) => item === add.id);
    //             if (sameIdItem) {
    //                 if (add.isIgnoreDuplicationError) {
    //                     return state;
    //                 }
    //                 throw new Error('useLazyItem.addItem: !!sameIdItem.');
    //             }
    //             if (add.isAddToTop) {
    //                 return [add.id, ...(state || [])];
    //             }
    //             return [...(state || []), add.id];
    //         }
    //         if (remove && state) {
    //             return state.filter((item) => item !== remove.id);
    //         }
    //         if (reset) {
    //             return initIds(reset.items);
    //         }
    //         throw new Error('never');
    //     },
    //     items,
    //     initIds
    // );
    const [ids, setIds] = useState<string[] | undefined>(undefined);
    const [itemDatas, dispatchItemDatas] = useReducer<
        (
            state: ItemType<T>[] | undefined,
            input: {
                add?: { newItem: ItemType<T>; isIgnoreDuplicationError?: boolean; isAddToTop?: boolean };
                remove?: { id: string };
                // update?: { item: ItemType<T> };
                update?: {
                    id: string;
                    updator: (prevItem: ItemType<T>) => ItemType<T>;
                };
                reset?: { items?: ItemType<T>[] };
            }
        ) => ItemType<T>[] | undefined,
        ItemType<T>[] | undefined
    >(
        (state, { add, remove, update, reset }) => {
            if (add) {
                const sameIdItem = (state || []).find((item) => item.id === add.newItem.id);
                if (sameIdItem) {
                    if (add.isIgnoreDuplicationError) {
                        return state;
                    }
                    throw new Error('useLazyItem.addItem: !!sameIdItem.');
                }
                if (add.isAddToTop) {
                    return [add.newItem, ...(state || [])];
                }
                return [...(state || []), add.newItem];
            }
            if (remove && state) {
                return state.filter((item) => item.id !== remove.id);
            }
            // if (update && state) {
            //     return state.map((item) => {
            //         if (item.id === update.item.id) {
            //             return update.item;
            //         }
            //         return item;
            //     });
            // }
            if (update && state) {
                const { id, updator } = update;
                const prevItem = state.find((item) => item.id === id);
                if (!prevItem) {
                    console.error('useLazyItems.updator: !prevItem.', {
                        id,
                        updator,
                        state,
                    });
                    throw new Error('useLazyItems.updator: !prevItem.');
                }
                const newItem = updator(prevItem);
                return state.map((item) => {
                    if (item.id === newItem.id) {
                        return newItem;
                    }
                    return item;
                });
            }
            if (reset) {
                return initItems(reset.items);
            }
            throw new Error('never');
        },
        items,
        initItems
    );
    // const [currentItemData, setCurrentItemData] = useState<ItemType<T> | undefined>(undefined);
    // const [isUpdating, setIsUpdating] = useState(false);

    // USEEFFECT
    useEffect(() => {
        // when added
        if (itemDatas?.length !== ids?.length) {
            setIds(itemDatas?.map(({ id }) => id));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [itemDatas?.length]);
    // useEffect(() => {
    //     // when removed
    //     if (ids && itemDatas && ids.length < itemDatas.length) {
    //         const removedItemData = itemDatas.find((itemData) => {
    //             const itemDataIdInIds = ids.find((id) => id === itemData.id);
    //             return !itemDataIdInIds;
    //         });
    //         console.log({ removedItemData });
    //         if (removedItemData) {
    //             dispatchItemDatas({
    //                 remove: { id: removedItemData.id },
    //             });
    //         }
    //     }
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [ids?.length]);

    // DATA
    const getItemData = (id: string) => {
        if (!itemDatas) {
            // throw new Error('useLazyItems.getItemData: not initialized!');
            return undefined;
        }
        return itemDatas.find((item) => item.id === id);
    };

    const getItemDataAbsolutely = (id: string) => {
        const target = getItemData(id);
        if (target) {
            return target;
        }
        console.error('useLazyItems.getItemDataAbsolutely: item for id not found!', {
            givenId: id,
            ids,
            itemDatas,
        });
        throw new Error('useLazyItems.getItemDataAbsolutely: item for id not found!');
    };

    // HANDLER
    // const setSelectedItem = (id?: string) => {
    //     if (!id) {
    //         setCurrentItemData(undefined);
    //         return;
    //     }
    //     const itemData = getItemData(id);
    //     if (itemData) {
    //         setCurrentItemData(itemData);
    //     }
    // };
    // const updateItem = (item: ItemType<T>) => {
    //     // if (currentItemData?.id === item.id) {
    //     //     setCurrentItemData(item);
    //     // }
    //     dispatchItemDatas({ update: { item } });
    // };
    const updateItem = ({ id, updator }: { id: string; updator: (prevItem: ItemType<T>) => ItemType<T> }) => {
        // if (currentItemData?.id === item.id) {
        //     setCurrentItemData(item);
        // }
        dispatchItemDatas({
            update: {
                id,
                updator,
            },
        });
    };
    const removeItem = (id: string) => {
        // if (currentItemData?.id === id) {
        //     setCurrentItemData(undefined);
        // }
        // setIsUpdating(true);
        // dispatchIds({ remove: { id } });
        // setIsUpdating(false);
        setIds(ids?.filter((item) => item !== id));
        dispatchItemDatas({ remove: { id } });
    };
    const addItem = ({
        newItem,
        isIgnoreDuplicationError,
        isAddToTop,
    }: {
        newItem: ItemType<T>;
        isIgnoreDuplicationError?: boolean;
        isAddToTop?: boolean;
    }) => {
        // setIsUpdating(true);
        dispatchItemDatas({ add: { newItem, isIgnoreDuplicationError, isAddToTop } });
        // dispatchIds({ add: { id: newItem.id, isIgnoreDuplicationError, isAddToTop } });
        // setIsUpdating(false);
    };
    const initialize = (items?: ItemType<T>[]) => {
        dispatchItemDatas({ reset: { items } });
        // dispatchIds({ reset: { items } });
    };

    // const asyncUpdateItem = async ({
    //     id,
    //     updator,
    // }: {
    //     id: string;
    //     updator: (input: { prevItem: ItemType<T> }) => ItemType<T>;
    // }) => {
    //     await new Promise<void>((r) => {
    //         if (!isUpdating) {
    //             r();
    //         }
    //     });
    //     const targetItem = getItemDataAbsolutely(id);
    //     const newItem = updator({ prevItem: targetItem });
    //     updateItem(newItem);
    //     return newItem;
    // };

    return {
        ids,
        itemDatas,
        // currentItem: currentItemData,
        getItem: getItemData,
        getItemAbsolutely: getItemDataAbsolutely,
        // setSelectedItem,
        updateItem,
        // updateItem2,
        removeItem,
        addItem,
        initialize,
        // isUpdating, // removeを使う場合にはgetItemAbsolutelyを使わないのがよさそう。
        // asyncUpdateItem,
    };
};

export const useItems = <T extends RecordWithId>(items: ItemType<T>[]) => {
    // HOOKS
    const { ids, itemDatas, getItem, getItemAbsolutely, updateItem, removeItem, addItem, initialize } =
        useLazyItems(items);

    // USEEFFECT
    // useEffect(() => {
    //     initialize(items);
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [items]);

    return {
        ids: ids || [],
        itemDatas: itemDatas || [],
        getItem,
        getItemAbsolutely,
        updateItem,
        removeItem,
        addItem,
        initialize: (items: ItemType<T>[]) => initialize(items),
    };
};
