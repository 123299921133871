import { useState, useEffect, useCallback } from 'react';
import { TimelineRow } from '@/utils/PowerchatClient';
import { useRoomCtxAbsolutely } from '@/utils/ctxs';
import { useLazyItems } from '@/utils/customHooks';

export const usePastTimeline = () => {
    // HOOKS
    const {
        current: { currentRoomApi },
    } = useRoomCtxAbsolutely();
    const {
        ids,
        itemDatas,
        getItem,
        getItemAbsolutely,
        initialize: initializeItems,
        updateItem,
    } = useLazyItems<
        TimelineRow & {
            id: string;
        }
    >(undefined);
    const [isFetching, setIsFetching] = useState(false);
    const [mountedAt, setMountedAt] = useState<Date | undefined>(new Date());
    const [isAllFetched, setIsAllFetched] = useState<boolean | undefined>(undefined);

    // INITILALIZER
    const initialize = useCallback(() => {
        setMountedAt(new Date());
        initializeItems(undefined);
        setIsAllFetched(undefined);
        setIsFetching(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentRoomApi.id]);

    // DATA
    const oldestMessageApi = (() => {
        if (itemDatas) {
            // findの仕様として、配列の頭からみていくので
            return itemDatas.find(({ messageWithChildren }) => !!messageWithChildren)?.messageWithChildren?.messageApi;
        }
        return undefined;
    })();

    const fetchPastTimeline = async () => {
        if (oldestMessageApi?.serialNumber === 1 || isAllFetched) {
            return;
        }
        setIsFetching(true);
        const fetchedItems = await currentRoomApi.getTimeline({
            maxAmount: 10,
            startAfterAt: oldestMessageApi?.createdAt || mountedAt,
        });

        if (fetchedItems.length === 0) {
            setIsAllFetched(true);
            return;
        }
        const fetchedItemsAsc = fetchedItems.reverse();
        initializeItems([
            ...fetchedItemsAsc.map(({ messageWithChildren, ring, checkin, checkout }) => ({
                id:
                    messageWithChildren?.messageApi.id ||
                    ring?.id ||
                    (checkin ? `checkin_${checkin.id}` : undefined) ||
                    (checkout ? `checkout_${checkout.id}` : undefined) ||
                    'never',
                messageWithChildren,
                ring,
                checkin,
                checkout,
            })),
            ...(itemDatas || []),
        ]);
        if (fetchedItemsAsc.find(({ messageWithChildren }) => messageWithChildren?.messageApi.serialNumber === 1)) {
            setIsAllFetched(true);
        }
        setIsFetching(false);
    };

    // USEEFFECT
    useEffect(() => {
        return () => {
            initialize();
        };
    }, [initialize]);
    // USEEFFECT
    useEffect(() => {
        const { unsubscribeListenToMessages } = currentRoomApi.listenToMessages({
            onAdded: () => undefined,
            onModified: ({ modifiedMessageApi }) => {
                console.log({ modifiedMessageApi, mountedAt });
                if (mountedAt && mountedAt > modifiedMessageApi.createdAt) {
                    console.log('fired');
                    updateItem({
                        id: modifiedMessageApi.id,
                        updator: (prevItem) => {
                            return {
                                ...prevItem,
                                messageApi: modifiedMessageApi,
                                messageWithChildren: {
                                    messageApi: modifiedMessageApi,
                                    reactionApis: prevItem.messageWithChildren?.reactionApis || [],
                                },
                            };
                        },
                    });
                    // const targetItem = getItemAbsolutely(modifiedMessageApi.id);
                    // updateItem({
                    //     ...targetItem,
                    //     messageWithChildren: {
                    //         messageApi: modifiedMessageApi,
                    //         reactionApis: targetItem.messageWithChildren?.reactionApis || [],
                    //     },
                    // });
                }
            },
        });
        return () => {
            unsubscribeListenToMessages();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentRoomApi.id]);
    console.log({ ids, oldestMessageApi, itemDatas });
    return {
        pastTimelineIds: ids,
        getPastTimelineItem: getItem,
        getPastTimelineItemAbsolutely: getItemAbsolutely,
        fetchPastTimeline,
        isFetchingPastTimeline: isFetching,
        oldestMessageApi,
        isAllFetched,
    };
};
