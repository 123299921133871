import { createContext, useContext } from 'react';

import { RoomApi, RoomMembershipApi } from '@/utils/PowerchatClient';

export type RoomCtxType = {
    current:
        | {
              currentRoomApi: RoomApi;
              currentMembershipApi: RoomMembershipApi;
          }
        | undefined;
    // refetchCurrentRoom: () => Promise<void>;
    setRoomId: (input: { roomId: string }) => void; // isRefetch?: boolean 今はまだrefetchできない（API用意してない）
};

export const RoomCtx = createContext<RoomCtxType>({
    current: undefined,
    // refetchCurrentRoom: () =>
    //     new Promise((r) => {
    //         r();
    //     }),
    setRoomId: () => undefined,
});
export const useRoomCtx = () => {
    return useContext(RoomCtx);
};
export const useRoomCtxAbsolutely = () => {
    const { current, ...otherProps } = useRoomCtx();
    if (!current) {
        throw new Error('useRoomCtxAbsolutely: ! current.');
    }
    return {
        current,
        ...otherProps,
    };
};
