import { FC, useState, useCallback, useEffect } from 'react';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import MuiTypography from '@material-ui/core/Typography';

import { User } from '@/utils/PowerchatClient';
import { useUserCtxAbsolutely } from '@/utils/ctxs';
import { useLanguage } from '@/utils/customHooks';
import { TextInput } from '@/components/0_atom/Input';
import { Button } from '@/components/0_atom/Button';
import { InlineInputAndButton } from '@/components/1_mol/InlineInputAndButton';
import { FindUserInput } from '@/components/2_org/FindUserInput';

const useStyles = makeStyles((theme) => ({
    root: {},
    current: {},
    button: {
        paddingLeft: 24,
        paddingRight: 24,
    },
    or: {
        // color: theme.palette.text.disabled,
        margin: '30px 0',
        textAlign: 'center',
        fontWeight: 600,
    },
    descriptionBox: {
        display: 'flex',
        justifyContent: 'center',
    },
    description: {
        marginBottom: 60,
    },
}));

export const AddRoomButtonStep1: FC<{
    className?: string;
    setNewRoomName: (newRoomName: string | undefined) => void;
    setIsOpen: (isOpen: boolean) => void;
}> = ({ className, setNewRoomName, setIsOpen }) => {
    // STYLE
    const c = useStyles(useTheme());

    // HOOKS
    const { txt } = useLanguage();
    const [roomName, setRoomName] = useState<string | undefined>(undefined);
    const [fetchedUser, setFetchedUser] = useState<User | undefined>(undefined);
    const { userClient } = useUserCtxAbsolutely();

    // CALLBACK
    const createFriendship = useCallback(
        async (friendUserCode: string) => {
            const { friendUser } = await userClient.createFriendship({ friendUserCode });
            return friendUser;
        },
        [userClient]
    );

    // USEEFFECT
    useEffect(() => {
        if (fetchedUser) {
            setIsOpen(false);
        }
    }, [fetchedUser, setIsOpen]);

    return (
        <>
            <div className={c.descriptionBox}>
                <MuiTypography variant={'body1'} className={c.description}>
                    {txt({
                        en: 'Create a new room or start a direct chat.',
                        ja: '新しいルームを作成、またはダイレクトチャットを開始します。',
                    })}
                </MuiTypography>
            </div>
            <InlineInputAndButton
                title={txt({
                    en: 'Create a new room',
                    ja: '新しいルームを作成',
                })}
                input={
                    <TextInput
                        value={roomName}
                        setValue={setRoomName}
                        placeholder={txt({
                            en: 'Name for the new room',
                            ja: '新しいルームの名前',
                        })}
                    />
                }
                button={
                    <Button
                        disabled={!roomName}
                        onClick={() => setNewRoomName(roomName)}
                        className={c.button}
                        role={roomName ? 'submit' : undefined}
                    >
                        {txt({
                            en: 'Next',
                            ja: '次へ',
                        })}
                    </Button>
                }
            />
            <MuiTypography variant={'body1'} className={c.or}>
                {txt({
                    en: 'OR',
                    ja: 'または',
                })}
            </MuiTypography>
            <FindUserInput
                title={txt({
                    en: 'Start a direct chat',
                    ja: 'ダイレクトチャットを開始',
                })}
                setFetchedUser={setFetchedUser}
                buttonText={txt({
                    en: 'Start',
                    ja: '開始',
                })}
                fetchUser={createFriendship}
            />
        </>
    );
};
AddRoomButtonStep1.displayName = 'RoomsTemplate/AddRoomButton/Step1';
