import { createTheme } from '@material-ui/core/styles';

export default createTheme({
    palette: {
        primary: {
            main: '#FF3364',
        },
        secondary: {
            main: '#F746FB',
        },
        error: {
            main: '#FF4747',
        },
        warning: {
            main: '#FFD338',
        },
        info: {
            main: '#64b5f6',
        },
        success: {
            main: '#60D34E',
        },
        background: {
            default: '#fff',
            paper: '#fff',
        },
        text: {
            primary: 'rgba(0, 0, 0, .75)',
            secondary: 'rgba(0, 0, 0, .20)',
            disabled: 'rgba(0, 0, 0, .20)',
            // paper
        },
        divider: 'rgba(0, 0, 0, 0.10)',
        // action: {
        //     active: '#000',
        // },
    },
    typography: {
        fontFamily: [
            'Poppins',
            'Montserrat Alternates',
            'Montserrat',
            'Avenir Next',
            'Avenir',
            'メイリオ',
            'Meiryo',
            'Hiragino Kaku Gothic Pro',
            'Hiragino Kaku Gothic ProN',
            'Yu Gothic',
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        h1: {
            fontSize: 23,
            fontWeight: 600,
        },
        h2: {
            fontSize: 15,
            fontWeight: 600,
        },
        h3: {
            // fontSize: '2rem',
            // fontWeight: 600,
        },
        h4: {
            // fontSize: '0.9rem',
            // fontWeight: 600,
        },
        h5: {
            // fontSize: '0.9rem',
            // fontWeight: 600,
        },
        h6: {
            // fontSize: '0.9rem',
            // fontWeight: 600,
        },
        subtitle1: {
            fontWeight: 600,
        },
        button: {
            textTransform: 'none',
            fontSize: 12,
        },
        body1: {
            fontSize: 15,
            lineHeight: '1.6',
        },
        body2: {
            fontSize: 12,
            lineHeight: '1.6',
        },
        caption: {
            fontSize: 10,
        },
    },
    props: {
        MuiTextField: {
            variant: 'outlined',
        },
    },
    spacing: 15,
    overrides: {
        // MuiCardContent: {
        //     root: {
        //         padding: '2.1rem 2rem',
        //         '&:last-child': {
        //             paddingBottom: '2.1rem',
        //         },
        //         '&:not(:first-child)': {
        //             paddingTop: 0,
        //         },
        //     },
        // },
        MuiCssBaseline: {
            '@global': {
                // html: {
                //     boxSizing: 'border-box',
                // },
                // '*': {
                //     boxSizing: 'border-box',
                // },
                input: {
                    boxSizing: 'border-box',
                    WebkitAppearance: 'none',
                    // '&::placeholder': {
                    //     WebkitAppearance: 'none',
                    //     display: 'none',
                    // },
                },
                body: {
                    fontSize: 15,
                    scrollBehavior: 'smooth',
                    '& a': {
                        color: 'inherit',
                        textDecoration: 'none',
                    },
                },
            },
        },
        MuiPaper: {
            elevation0: {
                boxShadow: 'none',
            },
            elevation1: {
                boxShadow: '0 0px 4px 0 rgba(0, 0, 0, .02)',
            },
            elevation2: {
                boxShadow: '0 0px 4px 0 rgba(0, 0, 0, .1)',
            },
            elevation8: {
                boxShadow: '0 24px 32px 0 rgba(0, 0, 0, .1)',
            },
            elevation16: {
                boxShadow: '0 64px 64px 0 rgba(0, 0, 0, .2)',
            },
            rounded: {
                borderRadius: 2,
            },
        },
        MuiButton: {
            root: {
                padding: '6px 12px',
                color: 'inherit',
                fontSize: 15,
                '&:hover': {
                    backgroundColor: 'rgba(0,0,0,.2)',
                },
                borderRadius: 2,
            },
            text: {
                padding: undefined,
                color: 'inherit',
            },
            contained: {
                color: 'inherit',
                backgroundColor: '#fff',
                boxShadow: 'none',
                '&:hover': {
                    boxShadow: 'none',
                    backgroundColor: '#f3f3f3',
                },
            },
            sizeSmall: {
                padding: '6px 12px',
                fontSize: 10,
                minWidth: 80,
            },
            startIcon: {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginLeft: 0,
                '& svg': {
                    height: '100%',
                },
            },
            endIcon: {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginLeft: 0,
            },
        },
        MuiIconButton: {
            root: {
                color: 'inherit',
            },
        },
        MuiAccordion: {
            root: {
                padding: undefined,
                boxShadow: 'none',
                backgroundColor: 'inherit',
                '&$expanded': {
                    margin: undefined,
                },
                '&::before': {
                    content: 'none',
                },
            },
        },
        MuiAccordionSummary: {
            root: {
                padding: undefined,
                minHeight: undefined,
                '&$expanded': {
                    minHeight: undefined,
                },
            },
            content: {
                margin: undefined,
                '&$expanded': {
                    margin: undefined,
                },
            },
        },
        MuiAccordionDetails: {
            root: {
                padding: undefined,
                display: undefined,
            },
        },
        // MuiCardActionArea: {
        //     root: {
        //         '&:hover': {
        //             backgroundColor: '#fff !important',
        //         },
        //     },
        // },
        // MuiInputBase: {
        //     input: {
        //         minWidth: 200,
        //     },
        // },
    },
});
