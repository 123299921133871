import { GqlRoom } from '@/utils/PowerchatClient/graphql/generated/graphqlClient';
import { isoToDate, isoToDateOptionally } from '@/utils/PowerchatClient/graphql/customScalars';

export type RoomType = {
    id: string;
    createdAt: Date;
    updatedAt: Date | undefined;
    name: string;
    closedAt: Date | undefined;
    isFriendship: boolean;
};

export class Room implements RoomType {
    readonly id: string;

    readonly createdAt: Date;

    updatedAt: Date | undefined;

    name: string;

    closedAt: Date | undefined;

    readonly isFriendship: boolean;

    constructor({ id, createdAt, updatedAt, name, closedAt, isFriendship }: RoomType) {
        this.id = id;
        this.createdAt = createdAt;
        this.updatedAt = updatedAt;
        this.name = name;
        this.closedAt = closedAt;
        this.isFriendship = isFriendship;
    }

    async _updateClosedAt({ closedAt, updatedAt }: { closedAt: Date; updatedAt: Date }) {
        this.closedAt = closedAt;
        this.updatedAt = updatedAt;
    }

    async _updateName({ name, updatedAt }: { name: string; updatedAt: Date }) {
        this.name = name;
        this.updatedAt = updatedAt;
    }
}

export const getRoomFromGql = ({ id, createdAt, updatedAt, name, closedAt, isFriendship }: GqlRoom) => {
    return new Room({
        id,
        createdAt: isoToDate(createdAt),
        updatedAt: isoToDateOptionally(updatedAt),
        name,
        closedAt: isoToDateOptionally(closedAt),
        isFriendship,
    });
};
