/* eslint-disable import/no-extraneous-dependencies */
import { getCLS, getFID, getFCP, getLCP, getTTFB } from 'web-vitals';
import { getEnvVariables } from '@/utils/envVariables';

const reportWebVitals = () => {
    const { uodkaEnv } = getEnvVariables();
    if (uodkaEnv === 'development' || uodkaEnv === 'staging') {
        const onPerfEntry = console.log;
        getCLS(onPerfEntry);
        getFID(onPerfEntry);
        getFCP(onPerfEntry);
        getLCP(onPerfEntry);
        getTTFB(onPerfEntry);
    }
};

export default reportWebVitals;
