import { FC } from 'react';
import clsx from 'clsx';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import MuiButton from '@material-ui/core/Button';

import { generateColorForText } from '@/utils/utilFunctions';

const useStyles = makeStyles((theme) => ({
    root: {
        borderRadius: 15,
        display: 'inline-flex',
        width: 48,
        height: 48,
        justifyContent: 'center',
        alignItems: 'center',
        fontWeight: 600,
    },
    isHot: {
        outline: 'solid 2px #fff',
        boxShadow: `0 0 0 4px ${theme.palette.primary.main}`,
    },
}));

export const RoomIcon: FC<{
    className?: string;
    onClick?: () => void;
    roomName: string;
    roomId: string;
    isHot?: boolean;
}> = ({ className, onClick, roomName, roomId, isHot }) => {
    // STYLE
    const c = useStyles(useTheme());

    // DATA
    const { color, textColor } = generateColorForText({ text: roomId.toLowerCase() });

    return onClick ? (
        <MuiButton
            className={clsx(c.root, className, {
                [c.isHot]: isHot,
            })}
            onClick={onClick}
            style={{
                background: color,
                color: textColor,
            }}
        >
            {roomName[0]?.toUpperCase()}
        </MuiButton>
    ) : (
        <span
            className={clsx(c.root, className, {
                [c.isHot]: isHot,
            })}
            style={{
                background: color,
                color: textColor,
            }}
        >
            {roomName[0]?.toUpperCase()}
        </span>
    );
};
RoomIcon.displayName = 'RoomIcon';
