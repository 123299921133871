import { forwardRef, ComponentProps, useState, MouseEvent, MouseEventHandler } from 'react';

import { TextMenu, TextMenuItemProps } from '@/components/0_atom/Menu';
import { Button } from '@/components/0_atom/Button';

export const ButtonWithTextMenu = forwardRef<
    HTMLButtonElement,
    ComponentProps<typeof Button> & {
        items: TextMenuItemProps[];
        menuPaperClassName?: string;
        menuEmptyPlaceholder: string;
    }
>(({ items, onClick, menuPaperClassName, menuEmptyPlaceholder, ...otherProps }, ref) => {
    // HOOKS
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | undefined>(undefined);

    // HANDLER
    const handleOpen: MouseEventHandler<HTMLButtonElement> = (event: MouseEvent<HTMLButtonElement>) => {
        if (otherProps.disabled) {
            return;
        }
        setAnchorEl(event.currentTarget);
        onClick && onClick(event);
    };
    const handleClose = () => {
        setAnchorEl(undefined);
    };

    return (
        <>
            <Button ref={ref} {...otherProps} onClick={handleOpen} />
            <TextMenu
                emptyPlaceHolder={menuEmptyPlaceholder}
                anchorEl={anchorEl}
                open={!!anchorEl}
                onClose={handleClose}
                items={items}
                classes={{ paper: menuPaperClassName }}
            />
        </>
    );
});
ButtonWithTextMenu.displayName = 'ButtonWithTextMenu';
