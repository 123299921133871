import { StrictMode } from 'react';
import { render } from 'react-dom';
import reportWebVitals from 'reportWebVitals';
import { AppRoot } from '@/AppRoot';

render(
    <StrictMode>
        <AppRoot />
    </StrictMode>,
    document.getElementById('root')
);

reportWebVitals();
