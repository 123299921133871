import { FC, ReactNode } from 'react';
import clsx from 'clsx';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import FormHelperText from '@material-ui/core/FormHelperText';

export const useStyles = makeStyles((theme) => ({
    root: {
        '& input': {
            padding: '22px 14px',
            // fontWeight: 600,
            border: 'solid 1px rgba(0,0,0,.1)',
            borderRadius: 2,
            transitionProperty: 'border-color',
            transitionDuration: '.1s',
            lineHeight: 'normal',
            fontSize: 16,
            '&:focus': {
                borderColor: 'rgba(0,0,0,.5)',
            },
        },
        '& textarea': {
            padding: '22px 14px',
            // fontWeight: 600,
            border: 'solid 1px rgba(0,0,0,.1)',
            borderRadius: 2,
            transitionProperty: 'border-color',
            transitionDuration: '.1s',
            lineHeight: 1.5,
            minHeight: 60,
            '&:focus': {
                borderColor: 'rgba(0,0,0,.5)',
            },
        },
        '&.MuiInputBase-multiline': {
            padding: 0,
        },
        '&.Mui-error input': {
            borderColor: `${theme.palette.error.light} !important`,
        },
        '&.MuiInputBase-adornedStart': {
            position: 'relative',
            '& input': {
                paddingLeft: 36,
            },
            '& .MuiInputAdornment-positionStart': {
                paddingLeft: 16,
                position: 'absolute',
                '& .MuiTypography-root': {
                    fontWeight: 600,
                    color: theme.palette.text.primary,
                    fontSize: theme.typography.body2.fontSize,
                },
            },
        },
        '&.MuiInputBase-adornedEnd': {
            position: 'relative',
            '& input': {
                paddingRight: 36,
            },
            '& .MuiInputAdornment-positionEnd': {
                paddingRight: 16,
                position: 'absolute',
                right: 0,
                '& .MuiTypography-root': {
                    fontWeight: 600,
                    color: theme.palette.text.primary,
                    fontSize: theme.typography.body2.fontSize,
                },
            },
        },
    },
    rootSatisfied: {
        '& input': {
            borderColor: `${theme.palette.success.light} !important`,
        },
        '& textarea': {
            borderColor: `${theme.palette.success.light} !important`,
        },
    },
}));

export const getValidNumber = (text: string) => {
    if (!text) {
        return undefined;
    }
    const numberedText = Number(text);
    if (!Number.isNaN(numberedText)) {
        return numberedText;
    }
    return undefined;
};
export const getInt = ({ value, method }: { value: number; method: 'round' | 'roundDown' | 'roundUp' }) => {
    if (method === 'roundUp') {
        return Math.ceil(value);
    }
    if (method === 'roundDown') {
        return Math.floor(value);
    }
    return Math.round(value);
};

const useHelperStyles = makeStyles((theme) => ({
    root: {
        fontWeight: 600,
    },
    success: {
        color: theme.palette.success.light,
    },
}));

export const InputHelpers: FC<{
    items?: {
        isVisible: boolean;
        isKeepSpace?: boolean;
        variant?: 'error' | 'success' | 'plain';
        message: ReactNode;
    }[];
}> = ({ items }) => {
    const c = useHelperStyles(useTheme());
    return (
        <>
            {items?.map(({ message, isVisible, isKeepSpace, variant }, i) => (
                <FormHelperText
                    key={i}
                    error={variant === 'error'}
                    className={clsx(c.root, {
                        [c.success]: variant === 'success',
                    })}
                    style={{
                        display: isVisible || isKeepSpace ? undefined : 'none',
                        visibility: !isVisible && isKeepSpace ? 'hidden' : undefined,
                    }}
                >
                    {message}
                </FormHelperText>
            ))}
        </>
    );
};
