import { FC, ReactNode, useState } from 'react';

import { ErrorCtx } from '@/utils/ctxs';

export const ErrorProvider: FC<{
    children: ReactNode;
}> = ({ children }) => {
    // HOOKS
    const [is404, setIs404] = useState(false);
    const [is500, setIs500] = useState(false);

    // HANDLER
    const handleSetIs404 = (newValue: boolean) => {
        if (newValue !== is404) {
            setIs404(newValue);
        }
    };
    const handleSetIs500 = (newValue: boolean) => {
        if (newValue !== is500) {
            setIs500(newValue);
        }
    };

    return (
        <ErrorCtx.Provider value={{ is404, is500, setIs404: handleSetIs404, setIs500: handleSetIs500 }}>
            {children}
        </ErrorCtx.Provider>
    );
};
ErrorProvider.displayName = 'ErrorProvider';
