import { useState, useCallback, useEffect } from 'react';

export type Language = 'en' | 'ja';
export type MultilingualText = {
    en: string;
    ja?: string;
};

export const useLanguage = () => {
    // HOOKS
    const [language, setLanguage] = useState<Language | undefined>(undefined);

    // CALLBACK
    const setHtmlLang = useCallback(() => {
        const htmlElm = document.getElementsByTagName('html')[0];
        if (htmlElm && language) {
            htmlElm.setAttribute('lang', language);
        }
    }, [language]);

    const getBrowserLanguage = useCallback(() => {
        const langCode = window.navigator.language.slice(0, 2);
        if (langCode === 'ja') {
            setLanguage('ja');
        } else {
            setLanguage('en');
        }
    }, []);

    // USEEFFECT
    useEffect(() => {
        getBrowserLanguage();
    }, [getBrowserLanguage]);
    useEffect(() => {
        setHtmlLang();
    }, [setHtmlLang]);

    // DATA
    const txt = (multilingualText: MultilingualText) => {
        return multilingualText[language || 'en'] || multilingualText.en;
    };
    const getDateStr = ({ date, isNoYearForThisYear }: { date: Date; isNoYearForThisYear?: boolean }) => {
        const monthNumber = date.getMonth();
        const dateNumber = date.getDate();
        const yearNumber = date.getFullYear();
        const { termDays, isThisYear } = (() => {
            const today = new Date();
            const dateDate = new Date(date.getFullYear(), date.getMonth(), date.getDate());
            const todayDate = new Date(today.getFullYear(), today.getMonth(), today.getDate());
            return {
                termDays: Math.floor((todayDate.getTime() - dateDate.getTime()) / 86400000),
                isThisYear: today.getFullYear() === yearNumber,
            };
        })();
        if (termDays === 0) {
            return txt({ en: 'Today', ja: '今日' });
        }
        if (termDays === 1) {
            return txt({ en: 'Yesterday', ja: '昨日' });
        }
        if (language === 'ja') {
            if (isNoYearForThisYear && isThisYear) {
                return `${String(monthNumber + 1)}/${String(dateNumber)}`;
            }
            return `${String(yearNumber)}/${String(monthNumber + 1)}/${String(dateNumber)}`;
        }
        const month = (() => {
            if (monthNumber === 0) {
                return 'Jan.';
            }
            if (monthNumber === 1) {
                return 'Feb.';
            }
            if (monthNumber === 2) {
                return 'Mar.';
            }
            if (monthNumber === 3) {
                return 'Apr.';
            }
            if (monthNumber === 4) {
                return 'May.';
            }
            if (monthNumber === 5) {
                return 'Jun.';
            }
            if (monthNumber === 6) {
                return 'Jul.';
            }
            if (monthNumber === 7) {
                return 'Aug.';
            }
            if (monthNumber === 8) {
                return 'Sep.';
            }
            if (monthNumber === 9) {
                return 'Oct.';
            }
            if (monthNumber === 10) {
                return 'Nov.';
            }
            return 'dec.';
        })();
        if (isNoYearForThisYear && isThisYear) {
            return `${month} ${String(dateNumber)}`;
        }
        return `${month} ${String(dateNumber)}, ${String(yearNumber)}`;
    };
    const getDateTimeStr = ({
        date,
        isOnlyTimeIfToday,
        isNoYearForThisYear,
    }: {
        date: Date;
        isOnlyTimeIfToday?: boolean;
        isNoYearForThisYear?: boolean;
    }) => {
        const hours = String(date.getHours());
        const minutes = (() => {
            const num = date.getMinutes();
            if (num < 10) {
                return `0${String(num)}`;
            }
            return String(num);
        })();
        const termDays = (() => {
            const today = new Date();
            const dateDate = new Date(date.getFullYear(), date.getMonth(), date.getDate());
            const todayDate = new Date(today.getFullYear(), today.getMonth(), today.getDate());
            return Math.floor((todayDate.getTime() - dateDate.getTime()) / 86400000);
        })();
        if (termDays === 0) {
            if (isOnlyTimeIfToday) {
                return `${hours}:${minutes}`;
            }
            return `${txt({ en: 'Today,', ja: '今日' })} ${hours}:${minutes}`;
        }
        if (termDays === 1) {
            return `${txt({ en: 'Yesterday,', ja: '昨日' })} ${hours}:${minutes}`;
        }
        return `${getDateStr({ date, isNoYearForThisYear })} ${hours}:${minutes}`;
    };

    return {
        language,
        txt,
        getDateStr,
        getDateTimeStr,
    };
};
