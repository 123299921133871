import { serverTimestamp } from 'firebase/database';

type RdbLastReactionAddNotification = {
    messageUserId: string;
    userName: string;
    roomName: string;
};
export type RdbLastReaction = {
    action: 'add' | 'remove';
    id: string;
    createdAt: number;
    removedAt: number | undefined;
    userId: string;
    messageId: string;
    letter: string;
    // for notification
    addNotification: RdbLastReactionAddNotification | undefined;
};
export type RdbLastReactionInput = {
    id: string;
    createdAt: Date | 'serverTimestamp';
    removedAt: Date | 'serverTimestamp' | undefined;
    userId: string;
    messageId: string;
    letter: string;
    // for notification
    addNotification: RdbLastReactionAddNotification | undefined;
};
export type AddRdbLastReactionInput = {
    removedAt: Date | 'serverTimestamp' | undefined;
    userId: string;
    messageId: string;
    letter: string;
    // for notification
    addNotification: RdbLastReactionAddNotification;
};
export type RemoveRdbLastReactionInput = {
    id: string;
    createdAt: Date | 'serverTimestamp';
    userId: string;
    messageId: string;
    letter: string;
};

export const getRdbLastReactionInput = ({
    id,
    createdAt,
    removedAt,
    userId,
    messageId,
    letter,
    addNotification,
}: RdbLastReactionInput) => {
    return {
        id,
        createdAt: createdAt === 'serverTimestamp' ? serverTimestamp() : createdAt.getTime(),
        removedAt: removedAt === 'serverTimestamp' ? serverTimestamp() : removedAt ? removedAt.getTime() : null,
        userId,
        messageId,
        letter,
        addNotification: (() => {
            if (addNotification) {
                return {
                    messageUserId: addNotification.messageUserId,
                    userName: addNotification.userName,
                    roomName: addNotification.roomName,
                };
            }
            return null;
        })(),
    };
};
