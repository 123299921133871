import { FC } from 'react';
import clsx from 'clsx';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import SvgEllipsis from '@/svgs/ellipsis';

const useStyles = makeStyles((theme) => ({
    '@keyframes dot': {
        '0%': {
            color: 'rgba(255,255,255,.8)',
        },
        '30%': {
            color: 'rgba(255,255,255,.3)',
        },
        '100%': {
            color: 'rgba(255,255,255,.1)',
        },
    },
    root: {
        display: 'flex',
        alignItems: 'center',
        '& svg': {
            width: 2,
            '&:not(:last-child)': {
                marginRight: 3,
            },
        },
    },
    rootInProgress: {
        '& svg:nth-child(1)': {
            animation: '$dot .8s linear infinite',
        },
        '& svg:nth-child(2)': {
            animation: '$dot .8s linear infinite',
            animationDelay: '0.2s',
        },
        '& svg:nth-child(3)': {
            animation: '$dot .8s linear infinite',
            animationDelay: '0.4s',
        },
        '& svg:nth-child(4)': {
            animation: '$dot .8s linear infinite',
            animationDelay: '0.6s',
        },
    },
    rootCompleted: {
        color: 'rgba(255,255,255,.8)',
    },
    rootOff: {
        color: 'rgba(255,255,255,.2)',
    },
    ellipsis: {},
}));

const DotsProgress: FC<{
    state: 'inProgress' | 'completed' | 'off';
    className?: string;
}> = ({ state, className }) => {
    // STYLE
    const c = useStyles(useTheme());

    return (
        <div
            className={clsx(c.root, className, {
                [c.rootInProgress]: state === 'inProgress',
                [c.rootCompleted]: state === 'completed',
                [c.rootOff]: state === 'off',
            })}
        >
            <SvgEllipsis className={c.ellipsis} />
            <SvgEllipsis className={c.ellipsis} />
            <SvgEllipsis className={c.ellipsis} />
            <SvgEllipsis className={c.ellipsis} />
        </div>
    );
};
DotsProgress.displayName = 'DotsProgress';
export default DotsProgress;
