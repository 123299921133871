import { serverTimestamp } from 'firebase/database';

export type RdbLastRing = {
    action: 'add';
    id: string;
    createdAt: number;
    fromUserId: string;
    toUserId: string;
    // for notification
    fromUserName: string;
    roomName: string;
};
export type RdbLastRingInput = {
    id: string;
    createdAt: Date | 'serverTimestamp';
    fromUserId: string;
    toUserId: string;
    // for notification
    fromUserName: string;
    roomName: string;
};
export type AddRdbLastRingInput = {
    fromUserId: string;
    toUserId: string;
    // for notification
    fromUserName: string;
    roomName: string;
};

export const getRdbLastRingInput = ({
    id,
    createdAt,
    fromUserId,
    toUserId,
    fromUserName,
    roomName,
}: RdbLastRingInput) => {
    return {
        id,
        createdAt: createdAt === 'serverTimestamp' ? serverTimestamp() : createdAt.getTime(),
        fromUserId,
        toUserId,
        fromUserName,
        roomName,
    };
};
