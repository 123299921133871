import { FC } from 'react';

const Svg: FC<{ className?: string }> = ({ className }) => {
    return (
        <svg className={className} viewBox="0 0 594 135" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M181.796 134C172.18 134 163.514 131.921 155.797 127.762C148.08 123.604 142.025 117.842 137.633 110.475C133.359 102.99 131.222 94.5545 131.222 85.1684C131.222 75.7822 133.359 67.406 137.633 60.0396C142.025 52.6733 148.08 46.9109 155.797 42.7525C163.514 38.5941 172.18 36.5149 181.796 36.5149C191.531 36.5149 200.257 38.5941 207.974 42.7525C215.691 46.9109 221.686 52.6733 225.96 60.0396C230.353 67.406 232.549 75.7822 232.549 85.1684C232.549 94.5545 230.353 102.99 225.96 110.475C221.686 117.842 215.691 123.604 207.974 127.762C200.257 131.921 191.531 134 181.796 134ZM181.796 114.931C189.988 114.931 196.755 112.198 202.098 106.733C207.44 101.267 210.111 94.0792 210.111 85.1684C210.111 76.2575 207.44 69.0693 202.098 63.604C196.755 58.1387 189.988 55.406 181.796 55.406C173.605 55.406 166.838 58.1387 161.495 63.604C156.272 69.0693 153.66 76.2575 153.66 85.1684C153.66 94.0792 156.272 101.267 161.495 106.733C166.838 112.198 173.605 114.931 181.796 114.931Z"
                fill="#30A1E1"
            />
            <path
                d="M399.817 38V90.5742C399.817 104.475 396.196 115.287 388.955 123.01C381.713 130.614 371.325 134.416 357.791 134.416C343.188 134.416 332.681 129.485 326.27 119.624C322.946 124.614 318.672 128.356 313.449 130.851C308.344 133.228 302.111 134.416 294.75 134.416C281.216 134.416 270.828 130.614 263.586 123.01C256.463 115.287 252.901 104.475 252.901 90.5742V38H275.161V88.6139C275.161 97.6436 276.705 104.297 279.791 108.574C282.997 112.851 288.043 114.99 294.928 114.99C301.814 114.99 306.919 112.851 310.243 108.574C313.567 104.178 315.229 97.5247 315.229 88.6139V38H337.489V88.6139C337.489 97.5247 339.152 104.178 342.476 108.574C345.8 112.851 350.905 114.99 357.791 114.99C364.676 114.99 369.663 112.851 372.749 108.574C375.955 104.297 377.557 97.6436 377.557 88.6139V38H399.817Z"
                fill="#05D41A"
            />
            <path
                d="M473.448 115.594C483.777 115.594 492.384 112.149 499.27 105.257L511.023 118.98C506.868 124.089 501.525 127.95 494.996 130.564C488.466 133.178 481.105 134.485 472.914 134.485C462.466 134.485 453.266 132.406 445.311 128.248C437.357 124.089 431.184 118.327 426.791 110.96C422.517 103.475 420.38 95.0396 420.38 85.6535C420.38 76.3861 422.458 68.0693 426.613 60.703C430.887 53.2178 436.704 47.396 444.065 43.2376C451.544 39.0792 459.973 37 469.352 37C478.256 37 486.329 38.9604 493.571 42.8812C500.932 46.6832 506.749 52.2079 511.023 59.4554C515.297 66.5842 517.434 74.9604 517.434 84.5842L444.599 98.8416C446.855 104.307 450.476 108.465 455.462 111.317C460.448 114.168 466.444 115.594 473.448 115.594ZM469.352 54.8218C461.16 54.8218 454.512 57.4951 449.407 62.8416C444.421 68.1881 441.928 75.3762 441.928 84.4059V84.5842L495.708 74.2475C494.165 68.4257 491.019 63.7327 486.27 60.1683C481.64 56.604 476 54.8218 469.352 54.8218Z"
                fill="#D4CC05"
            />
            <path
                d="M560.012 52.9703C566.422 42.9901 577.701 38 593.847 38V59.2079C591.947 58.8515 590.226 58.6733 588.683 58.6733C580.016 58.6733 573.249 61.2277 568.381 66.3366C563.514 71.3267 561.08 78.5743 561.08 88.0792V134.238H538.82V39.0693H560.012V52.9703Z"
                fill="#05D496"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M116.069 58C116.069 90.0325 90.1011 116 58.0686 116C44.151 116 31.3782 111.098 21.3829 102.926C21.832 118.22 22.5528 134 22.5528 134H1.55279C1.55279 134 0.300435 106.582 0.0686421 89C-0.0858026 77.2853 0.0686421 59 0.0686421 59H0.0770506C0.0714267 58.6673 0.0686035 58.334 0.0686035 58C0.0686035 25.9675 26.0361 0 58.0686 0C90.1011 0 116.069 25.9675 116.069 58ZM21.0686 59.0045C21.0686 59.0015 21.0686 59 21.0686 59H21.0686C21.0686 39.1177 37.1864 23 57.0686 23C76.9509 23 93.0686 39.1177 93.0686 59C93.0686 78.8822 76.9509 95 57.0686 95C37.1879 95 21.071 78.8847 21.0686 59.0045Z"
                fill="#FB8585"
            />
        </svg>
    );
};
Svg.displayName = 'svg/brand-color';
export default Svg;
