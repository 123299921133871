import { FC } from 'react';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import { useErrorCtx, useUserCtx, useHealthCtx } from '@/utils/ctxs';
import { ErrorDisplay } from '@/components/3_template/PreConsoleTemplate/ErrorDisplay';
import { PreAuthDisplay } from '@/components/3_template/PreConsoleTemplate/PreAuthDisplay';
import { SignupDisplay } from '@/components/3_template/PreConsoleTemplate/SignupDisplay';
import { BrowserNotSupportedDisplay } from '@/components/3_template/PreConsoleTemplate/BrowserNotSupportedDisplay';
import { NotificationGrantDisplay } from '@/components/3_template/PreConsoleTemplate/NotificationGrantDisplay';
import { FetchingText } from '@/components/0_atom/FetchingText';

import SvgBrand from '@/svgs/brand-color';

const useStyles = makeStyles((theme) => ({
    root: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        margin: 0,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        background: '#fff',
    },
    inner: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translateY(-50%) translateX(-50%)',
        margin: 'auto',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    brand: {
        height: 40,
    },
    content: {
        marginTop: 24,
    },
}));

export const PreConsoleTemplate: FC = () => {
    // STYLE
    const c = useStyles(useTheme());

    // HOOKS
    const { is404, is500 } = useErrorCtx();
    const { fetchStatus } = useHealthCtx();
    const { firebaseUser, userClient, isFetching, isNeedToGrantNotification, isBrowserNotSupported } = useUserCtx();

    return (
        <div className={c.root}>
            <div className={c.inner}>
                <SvgBrand className={c.brand} />
                <div className={c.content}>
                    {fetchStatus === 'fetchError' ? (
                        <ErrorDisplay errorCode={'network'} />
                    ) : fetchStatus === 'fetching' || isFetching ? (
                        <FetchingText />
                    ) : isBrowserNotSupported ? (
                        <BrowserNotSupportedDisplay />
                    ) : isNeedToGrantNotification ? (
                        <NotificationGrantDisplay />
                    ) : is500 ? (
                        <ErrorDisplay errorCode={500} />
                    ) : is404 ? (
                        <ErrorDisplay errorCode={404} />
                    ) : firebaseUser && !userClient ? (
                        <SignupDisplay />
                    ) : !firebaseUser && !userClient ? (
                        <PreAuthDisplay />
                    ) : (
                        <>{'Unexpected case'}</>
                    )}
                </div>
            </div>
        </div>
    );
};
PreConsoleTemplate.displayName = 'PreConsoleTemplate';
