import { GqlReaction } from '@/utils/PowerchatClient/graphql/generated/graphqlClient';
import { isoToDate } from '@/utils/PowerchatClient/graphql/customScalars';

export type ReactionType = {
    id: string;
    createdAt: Date;
    userId: string;
    roomId: string;
    messageId: string;
    letter: string;
};

export class Reaction implements ReactionType {
    readonly id: string;

    readonly createdAt: Date;

    readonly userId: string;

    readonly roomId: string;

    readonly messageId: string;

    readonly letter: string;

    constructor({ id, createdAt, userId, roomId, messageId, letter }: ReactionType) {
        this.id = id;
        this.createdAt = createdAt;
        this.userId = userId;
        this.roomId = roomId;
        this.messageId = messageId;
        this.letter = letter;
    }
}

export const getReactionFromGql = ({ id, createdAt, userId, roomId, messageId, letter }: GqlReaction) => {
    return new Reaction({
        id,
        createdAt: isoToDate(createdAt),
        userId,
        roomId,
        messageId,
        letter,
    });
};
