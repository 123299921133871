import { FC } from 'react';
import clsx from 'clsx';

import { useTheme, makeStyles } from '@material-ui/core/styles';

import { FormattedTextRenderer } from '@/components/0_atom/FormattedTextRenderer';

const useStyles = makeStyles((theme) => ({
    root: {
        overflowWrap: 'break-word',
        '& a': {
            whiteSpace: 'pre-wrap',
            wordBreak: 'break-all',
            color: '#0a7',
            textDecoration: 'underline',
        },
        '& p': {
            margin: 0,
        },
        '& ul': {
            margin: 0,
            padding: '0 0 0 15px',
        },
        '& code': {
            background: '#eee',
            border: 'solid 1px rgba(0,0,0,.05)',
            borderRadius: 2,
            padding: '0 2px',
            margin: 0,
        },
        '& pre': {
            background: '#eee',
            border: 'solid 1px rgba(0,0,0,.05)',
            padding: 6,
            borderRadius: 2,
            overflow: 'scroll',
            margin: 0,
            fontSize: 10,
        },
    },
}));

export const MessageBodyViewer: FC<{
    className?: string;
    text: string;
}> = ({ className, text }) => {
    // STYLE
    const c = useStyles(useTheme());

    return (
        <FormattedTextRenderer
            className={clsx(c.root, className)}
            text={text}
            renderOption={{
                pre: true,
                ul: true,
                br: true,
                code: true,
                a: true,
            }}
        />
    );
};
MessageBodyViewer.displayName = 'RoomTemplate/MessageBodyViewer';
