import { GraphQLSdk } from '@/utils/PowerchatClient/graphql/graphqlSdk';
import { postRdbReaction, modifyRdbMessage } from '@/utils/PowerchatClient/RealtimeDatabase';
import { User } from '@/utils/PowerchatClient/models/User';
import { ReactionApi, getReactionFromGql } from '@/utils/PowerchatClient/models/Reaction';
import { Room } from '@/utils/PowerchatClient/models/Room';
import { Message, MessageType } from '@/utils/PowerchatClient/models/Message/data/Message';

type MessageApiType = {
    cancel: () => Promise<void>;
    createReaction: (input: { letter: string }) => Promise<{
        reactionApi: ReactionApi;
    }>;
};

type ConstructorInput = MessageType & {
    graphqlSdk: GraphQLSdk;
    currentFcmToken: string;
    clientUser: User;
    room: Room;
};

export class MessageApi extends Message implements MessageApiType {
    protected _graphqlSdk: GraphQLSdk;

    protected _currentFcmToken: string;

    protected _clientUser: User;

    protected _room: Room;

    constructor(input: ConstructorInput) {
        super(input);
        this._graphqlSdk = input.graphqlSdk;
        this._currentFcmToken = input.currentFcmToken;
        this._clientUser = input.clientUser;
        this._room = input.room;
    }

    _updateCanceledAt({ canceledAt, updatedAt }: { canceledAt: Date | undefined; updatedAt: Date | undefined }) {
        this.updatedAt = updatedAt;
        this.canceledAt = canceledAt;
    }

    async cancel() {
        const now = new Date();
        this._updateCanceledAt({ canceledAt: now, updatedAt: now });
        modifyRdbMessage({
            roomId: this.roomId,
            message: this,
        });
        await this._graphqlSdk.cancelMessage({
            input: {
                roomId: this.roomId,
                messageId: this.id,
            },
        });
    }

    async createReaction({ letter }: { letter: string }) {
        const {
            newReaction: { id: preparedId },
        } = postRdbReaction({
            roomId: this.roomId,
            newReaction: {
                userId: this._clientUser.id,
                messageId: this.id,
                letter,
                removedAt: undefined,
                addNotification: {
                    userName: this._clientUser.uniqueName,
                    roomName: this._room.name,
                    messageUserId: this.userId,
                },
            },
        });
        const {
            createReaction: { reaction },
        } = await this._graphqlSdk.createReaction({
            input: {
                preparedId,
                roomId: this.roomId,
                messageId: this.id,
                letter,
            },
        });
        return {
            reactionApi: new ReactionApi({
                graphqlSdk: this._graphqlSdk,
                clientUser: this._clientUser,
                room: this._room,
                currentFcmToken: this._currentFcmToken,
                ...getReactionFromGql(reaction),
            }),
        };
    }
}
