import { FC, useState, useCallback, useEffect } from 'react';
import clsx from 'clsx';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import MuiIconButton from '@material-ui/core/IconButton';

import SvgPlus from '@/svgs/fa-plus-solid';

import { MessageApi, ReactionApi } from '@/utils/PowerchatClient';
import { useRoomCtxAbsolutely, useRoomMembersCtx, useUserCtxAbsolutely } from '@/utils/ctxs';
import { useLanguage } from '@/utils/customHooks';
import { LoadingIndicator } from '@/components/0_atom/LoadingIndicator';
import { UserIconWithName } from '@/components/2_org/UserIconWithName';
import { AddReactionButton } from '@/components/2_org/AddReactionButton';
import { ReplyButton } from '@/components/2_org/ReplyButton';
import { useReactions } from '@/components/3_template/ConsoleTemplate/RoomTemplate/useReactions';
import { ReactionButton } from '@/components/3_template/ConsoleTemplate/RoomTemplate/ReactionButton';
import { MessageBodyViewer } from '@/components/3_template/ConsoleTemplate/RoomTemplate/MessageBodyViewer';
import { useReplyingToMessage } from '@/components/3_template/ConsoleTemplate/RoomTemplate/useReplyingToMessage';

const useStyles = makeStyles((theme) => ({
    root: {
        borderBottom: 'solid 1px rgba(0,0,0,.05)',
        padding: '8px 9px 8px 9px',
    },
    user: {
        fontSize: 9,
        alignItems: 'flex-start',
        lineHeight: '1',
    },
    userIcon: {
        width: 15,
        height: 15,
        marginRight: 5,
    },
    body: {
        fontSize: 9,
        marginLeft: 20,
        marginTop: -3,
        color: theme.palette.text.disabled,
    },
}));

export const ReplyingToMessageCard: FC<{
    className?: string;
    messageId: string;
    getMessageApi: (id: string) => MessageApi | undefined;
}> = ({ className, messageId, getMessageApi }) => {
    // STYLE
    const c = useStyles(useTheme());

    // HOOKS
    const { userClient } = useUserCtxAbsolutely();
    const { getRoomMemberItem } = useRoomMembersCtx();
    const {
        current: { currentRoomApi },
    } = useRoomCtxAbsolutely();
    const { txt, getDateTimeStr } = useLanguage();
    const { replyingToMessageApi } = useReplyingToMessage({ messageId, getMessageApi });

    // DATA
    const member = (() => {
        if (replyingToMessageApi) {
            return getRoomMemberItem(replyingToMessageApi.userId);
        }
        return undefined;
    })();

    return (
        <div className={clsx(c.root, className)}>
            {replyingToMessageApi && member ? (
                <div>
                    <UserIconWithName
                        userName={member.user.uniqueName}
                        isHot={member.rdbUser?.currentRoomId === currentRoomApi.id}
                        className={c.user}
                        iconClassName={c.userIcon}
                    />
                    <div className={c.body}>
                        <MessageBodyViewer text={replyingToMessageApi.body} />
                    </div>
                </div>
            ) : (
                <LoadingIndicator />
            )}
        </div>
    );
};
ReplyingToMessageCard.displayName = 'RoomTemplate/ReplyingToMessageCard';
