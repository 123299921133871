import { FC, ReactNode } from 'react';

import { ConnectionCtx } from '@/utils/ctxs';
import { useConnection } from '@/utils/customHooks';

export const ConnectionProvider: FC<{
    children: ReactNode;
}> = ({ children }) => {
    // HOOKS
    const { isConnected } = useConnection();

    return (
        <ConnectionCtx.Provider
            value={{
                isConnected,
            }}
        >
            {children}
        </ConnectionCtx.Provider>
    );
};
ConnectionProvider.displayName = 'ConnectionProvider';
