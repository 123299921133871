/* eslint-disable import/no-extraneous-dependencies */
// import dotenv from 'dotenv';

// dotenv.config();

type UodkaEnv = 'development' | 'staging' | 'production';

const getUodkaEnv = (): UodkaEnv => {
    const str = process.env.REACT_APP_UODKA_ENV;
    if (str === 'production') {
        return 'production';
    }
    if (str === 'staging') {
        return 'staging';
    }
    if (str === 'development') {
        return 'development';
    }
    throw new Error(`getUodkaEnv: Unexpected process.env.REACT_APP_UODKA_ENV got->${str}`);
};

type EnvVariables = {
    uodkaEnv: UodkaEnv;
    rootUrl: string;
    api: {
        graphqlEndpointUrl: string;
    };
    firebase: {
        core: {
            projectId: string;
            authDomain: string;
            apiKey: string;
            databaseURL: string;
            storageBucket: string;
            messagingVapidKey: string;
            messagingSenderId: string;
            appId: string;
        };
    };
};

export const getEnvVariables = (): EnvVariables => {
    return {
        uodkaEnv: getUodkaEnv(),
        rootUrl: process.env.REACT_APP_ROOT_URL || '',
        api: {
            graphqlEndpointUrl: process.env.REACT_APP_API_GRAPHQL_ENDPOINT_URL || '',
        },
        firebase: {
            core: {
                projectId: process.env.REACT_APP_FIREBASE_CORE_PROJECT_ID || '',
                authDomain: process.env.REACT_APP_FIREBASE_CORE_AUTH_DOMAIN || '',
                apiKey: process.env.REACT_APP_FIREBASE_CORE_API_KEY || '',
                databaseURL: process.env.REACT_APP_FIREBASE_CORE_DATABASE_URL || '',
                storageBucket: process.env.REACT_APP_FIREBASE_CORE_STORAGE_BUCKET || '',
                messagingVapidKey: process.env.REACT_APP_FIREBASE_CORE_MESSAGING_VAPID_KEY || '',
                messagingSenderId: process.env.REACT_APP_FIREBASE_CORE_MESSAGING_SENDER_ID || '',
                appId: process.env.REACT_APP_FIREBASE_CORE_APP_ID || '',
            },
        },
    };
};
