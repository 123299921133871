import { ref, set } from 'firebase/database';
import { collection, doc } from 'firebase/firestore';
import { getFirebaseRealtimeDatabase, getFirebaseFirestore } from '@/utils/GoogleCloud/firebase';
import { Reaction } from '@/utils/PowerchatClient';
import { AddRdbLastReactionInput, getRdbLastReactionInput } from '@/utils/PowerchatClient/RealtimeDatabase/RdbModels';

export const postRdbReaction = ({ roomId, newReaction }: { roomId: string; newReaction: AddRdbLastReactionInput }) => {
    const firestoreDb = getFirebaseFirestore();
    const { id } = doc(collection(firestoreDb, `Room/${roomId}/Message/${newReaction.messageId}/Reaction`));
    const realtimeDb = getFirebaseRealtimeDatabase();
    const lastReactionRef = ref(realtimeDb, `lastReactions/${roomId}`);
    set(lastReactionRef, {
        action: 'add',
        ...getRdbLastReactionInput({
            ...newReaction,
            id,
            createdAt: 'serverTimestamp',
        }),
    });
    return {
        newReaction: new Reaction({
            id,
            createdAt: new Date(),
            userId: newReaction.userId,
            roomId,
            messageId: newReaction.messageId,
            letter: newReaction.letter,
        }),
    };
};
