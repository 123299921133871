import { GqlCheckin } from '@/utils/PowerchatClient/graphql/generated/graphqlClient';
import { isoToDate, isoToDateOptionally } from '@/utils/PowerchatClient/graphql/customScalars';

export type CheckinType = {
    id: string;
    createdAt: Date;
    updatedAt: Date | undefined;
    serialNumber: number;
    userId: string;
    roomId: string;
    leftAt: Date | undefined;
};

export class Checkin implements CheckinType {
    readonly id: string;

    readonly createdAt: Date;

    updatedAt: Date | undefined;

    readonly serialNumber: number;

    readonly userId: string;

    readonly roomId: string;

    leftAt: Date | undefined;

    constructor({ id, createdAt, updatedAt, serialNumber, userId, roomId, leftAt }: CheckinType) {
        this.id = id;
        this.createdAt = createdAt;
        this.updatedAt = updatedAt;
        this.serialNumber = serialNumber;
        this.userId = userId;
        this.roomId = roomId;
        this.leftAt = leftAt;
    }

    _updateLeftAt({ leftAt, updatedAt }: { leftAt: Date; updatedAt: Date }) {
        this.leftAt = leftAt;
        this.updatedAt = updatedAt;
    }

    markAsCheckout() {
        const now = new Date();
        this._updateLeftAt({
            leftAt: now,
            updatedAt: now,
        });
    }
}

export const getCheckinFromGql = ({ id, createdAt, updatedAt, serialNumber, userId, roomId, leftAt }: GqlCheckin) => {
    return new Checkin({
        id,
        createdAt: isoToDate(createdAt),
        updatedAt: isoToDateOptionally(updatedAt),
        serialNumber,
        userId,
        roomId,
        leftAt: isoToDateOptionally(leftAt),
    });
};
