import { FC } from 'react';
import clsx from 'clsx';

import { useTheme, makeStyles } from '@material-ui/core/styles';

import { Button } from '@/components/0_atom/Button';
import { UserIcon } from '@/components/2_org/UserIcon';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
    },
}));

export const UserIconWithName: FC<{
    className?: string;
    onClick?: () => void;
    userName: string;
    isHot?: boolean;
    iconClassName?: string;
}> = ({ className, onClick, userName, isHot, iconClassName }) => {
    // STYLE
    const c = useStyles(useTheme());

    // DATA

    return onClick ? (
        <Button className={clsx(c.root, className)} onClick={onClick}>
            <UserIcon userName={userName} isHot={isHot} className={iconClassName} />
            {(userName[0]?.toUpperCase() || '') + userName.slice(1)}
        </Button>
    ) : (
        <span className={clsx(c.root, className)}>
            <UserIcon userName={userName} isHot={isHot} className={iconClassName} />
            {(userName[0]?.toUpperCase() || '') + userName.slice(1)}
        </span>
    );
};
UserIconWithName.displayName = 'UserIconWithName';
