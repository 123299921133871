import { FC, useState, useCallback, useEffect } from 'react';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import MuiTypography from '@material-ui/core/Typography';
import MuiList from '@material-ui/core/List';
import MuiListItem from '@material-ui/core/ListItem';
import MuiListItemIcon from '@material-ui/core/ListItemIcon';
import MuiListItemText from '@material-ui/core/ListItemText';
import MuiListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';

import { User, RoomMembership, RoomMembershipApi } from '@/utils/PowerchatClient';
import { useUserCtxAbsolutely } from '@/utils/ctxs';
import { useLanguage } from '@/utils/customHooks';
import { TextInput } from '@/components/0_atom/Input';
import { Button } from '@/components/0_atom/Button';
import { InlineInputAndButton } from '@/components/1_mol/InlineInputAndButton';
import { UserIconWithName } from '@/components/2_org/UserIconWithName';
import { FindUserInput } from '@/components/2_org/FindUserInput';

const useStyles = makeStyles((theme) => ({
    root: {},
    members: {
        marginTop: 30,
    },
    header: {
        fontWeight: 600,
    },
    addMember: {
        marginTop: 30,
    },
    user: {},
    userIcon: {
        width: 38,
        height: 38,
        marginRight: 10,
    },
    li: {
        paddingBottom: 0,
    },
}));

export const AddRoomButtonStep2: FC<{
    className?: string;
    members: {
        userCode: string;
        uniqueName: string;
    }[];
    setMembers: (
        members: {
            userCode: string;
            uniqueName: string;
        }[]
    ) => void;
}> = ({ className, members, setMembers }) => {
    // STYLE
    const c = useStyles(useTheme());

    // HOOKS
    const { txt } = useLanguage();
    const { userClient } = useUserCtxAbsolutely();

    return (
        <>
            <div className={c.members}>
                <MuiTypography variant={'body1'} className={c.header}>
                    {txt({
                        en: 'Members',
                        ja: 'メンバー',
                    })}
                </MuiTypography>
                <MuiList disablePadding>
                    <MuiListItem disableGutters className={c.li}>
                        <UserIconWithName
                            userName={userClient.uniqueName}
                            className={c.user}
                            iconClassName={c.userIcon}
                        />
                    </MuiListItem>
                    {members.map((member) => (
                        <MuiListItem key={member.userCode} disableGutters className={c.li}>
                            <UserIconWithName
                                userName={member.uniqueName}
                                className={c.user}
                                iconClassName={c.userIcon}
                            />
                            <MuiListItemSecondaryAction>
                                <Button
                                    onClick={() => {
                                        setMembers(members.filter(({ userCode }) => userCode !== member.userCode));
                                    }}
                                >
                                    {txt({
                                        en: 'Remove',
                                        ja: '削除',
                                    })}
                                </Button>
                            </MuiListItemSecondaryAction>
                        </MuiListItem>
                    ))}
                </MuiList>
            </div>
            <FindUserInput
                className={c.addMember}
                title={txt({
                    en: 'Add member',
                    ja: 'メンバーを追加',
                })}
                setFetchedUser={(fetchedUser) => {
                    if (fetchedUser) {
                        setMembers([
                            ...members,
                            {
                                userCode: fetchedUser.uniqueCode,
                                uniqueName: fetchedUser.uniqueName,
                            },
                        ]);
                    }
                }}
                buttonText={txt({
                    en: 'Add',
                    ja: '追加',
                })}
                onFetched={({ setUserCode, fetchedUser }) => fetchedUser && setUserCode(undefined)}
            />
        </>
    );
};
AddRoomButtonStep2.displayName = 'RoomsTemplate/AddRoomButton/Step2';
