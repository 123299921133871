import { useEffect, useState } from 'react';

import { useErrorCtx } from '@/utils/ctxs';
import { getFirebaseAuth } from '@/utils/GoogleCloud/firebase';

type FirebaseUser = {
    uid?: string;
    email?: string;
    idToken?: string;
    isFetching: boolean;
    isError?: boolean;
};

const getIdTokenWithRetry = ({
    authUser,
    onSuccess,
    onFailed,
}: {
    authUser: {
        getIdToken: (forceRefresh?: boolean | undefined) => Promise<string>;
    };
    onSuccess: (idToken: string) => void;
    onFailed: (e: Error) => void;
}) => {
    const run = async () => {
        authUser
            .getIdToken(true)
            .then(onSuccess)
            .catch((e) => {
                onFailed(e);
                setTimeout(run, 5000);
            });
    };
    run();
};

export const useFirebaseUser = () => {
    // HOOKS
    const { setIs500 } = useErrorCtx();
    const [firebaseUser, setFirebaseUser] = useState<FirebaseUser>({
        isFetching: true,
    });

    // USEEFFECT
    useEffect(() => {
        const auth = getFirebaseAuth();
        const unsubscribe = auth.onAuthStateChanged((authUser) => {
            if (authUser) {
                const googleProviderData = authUser.providerData?.find(
                    (provider) => provider?.providerId === 'google.com'
                );
                const email = googleProviderData?.email || undefined;
                getIdTokenWithRetry({
                    authUser,
                    onSuccess: (idToken) => {
                        setFirebaseUser({
                            uid: authUser.uid,
                            email,
                            idToken,
                            isFetching: false,
                        });
                        setIs500(false);
                    },
                    onFailed: (e) => {
                        console.error('firebaseUser.getIdToken: Error->', e);
                        setFirebaseUser({
                            uid: authUser.uid,
                            email,
                            isFetching: false,
                            isError: true,
                        });
                        setIs500(true);
                    },
                });
            } else {
                setFirebaseUser({
                    isFetching: false,
                });
            }
        });
        return () => unsubscribe();
    }, [setIs500]);

    const handleSignOut = async () => {
        const auth = getFirebaseAuth();
        await auth.signOut().catch(() => {
            console.log('signout failed');
        });
        console.log('signout success');
    };
    console.log({ firebaseUser });
    return {
        firebaseUser,
        signOut: handleSignOut,
    };
};
