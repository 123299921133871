import { FC, useState, MouseEventHandler, MouseEvent } from 'react';
import clsx from 'clsx';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import MuiIconButton from '@material-ui/core/IconButton';

import SvgSmilePlus from '@/svgs/fa-smile-plus-solid';

import { ButtonWithMenu } from '@/components/1_mol/ButtonWithMenu';
import { Menu } from '@/components/0_atom/Menu';

const useStyles = makeStyles((theme) => ({
    button: {
        padding: 6,
    },
    svg: {
        width: 15,
        height: 15,
    },
    menuPaper: {
        width: 200,
        '& .MuiMenu-list': {
            '& .MuiMenuItem-root': {
                display: 'inline-block',
                fontSize: 15,
            },
        },
    },
}));

const Emojis = ['😇', '🤓', '👍', '👏', '😭', '✔️'];

export const AddReactionButton: FC<{
    className?: string;
    onSelected: (letter: string) => void;
}> = ({ className, onSelected }) => {
    // STYLE
    const c = useStyles(useTheme());

    // HOOKS
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | undefined>(undefined);

    // HANDLER
    const handleOpen: MouseEventHandler<HTMLButtonElement> = (event: MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(undefined);
    };

    return (
        <>
            <MuiIconButton className={clsx(c.button, className)} onClick={handleOpen}>
                <SvgSmilePlus className={c.svg} />
            </MuiIconButton>
            <Menu
                emptyPlaceHolder={'never'}
                anchorEl={anchorEl}
                open={!!anchorEl}
                onClose={handleClose}
                items={Emojis.map((letter) => ({
                    children: letter,
                    onClick: () => onSelected(letter),
                }))}
                classes={{ paper: c.menuPaper }}
            />
        </>
    );
};
AddReactionButton.displayName = 'AddReactionButton';
