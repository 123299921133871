import { FC, ReactNode, useCallback } from 'react';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import MuiTypography from '@material-ui/core/Typography';
import MuiSlide from '@material-ui/core/Slide';

import { AddMutationInput, getWrappedMutation } from '@/utils/ctxs';
import { useLanguage } from '@/utils/customHooks';
import { Modal } from '@/components/0_atom/Modal';
import { MutationButton } from '@/components/0_atom/MutationButton';
import { Button } from '@/components/0_atom/Button';

const useStyles = makeStyles((theme) => ({
    content: {
        position: 'relative',
        height: '100%',
        overflow: 'hidden',
    },
    title: {
        marginBottom: 23, // prev 33
        textAlign: 'center',
    },
    children: {},
    submits: {
        display: 'flex',
        // marginTop: 65,
        position: 'absolute',
        width: '100%',
        bottom: 0,
        left: 0,
    },
    cancelButton: {
        flexGrow: 1,
        width: 'calc(50% - 14px)',
    },
    submitButton: {
        flexGrow: 1,
        marginLeft: 14,
        width: 'calc(50% - 14px)',
    },
    backdropWarning: {
        backgroundColor: 'rgba(0,0,0,.3)',
    },
}));

export const ConfirmationModal: FC<{
    isOpen: boolean;
    animation?: 'left' | 'right' | 'up' | 'down' | 'grow' | 'fade' | undefined;
    onClose: () => void;
    title: string;
    mutation: AddMutationInput;
    submitText: string;
    children: ReactNode;
    isWarning?: boolean;
    isHideSubmits?: boolean;
}> = ({ isOpen, animation, onClose, title, mutation, submitText, children, isWarning, isHideSubmits }) => {
    // STYLE
    const c = useStyles(useTheme());

    // HOOKS
    const { txt } = useLanguage();
    const handleClose = useCallback(() => {
        onClose();
    }, [onClose]);

    // DATA
    const wrappedMutation = getWrappedMutation({
        mutation,
        additionalOnThen: (result) => {
            if (result === 'success') {
                handleClose();
            }
        },
    });

    return (
        <Modal
            {...{ open: isOpen, animation, onClose }}
            BackdropProps={{
                className: isWarning ? c.backdropWarning : undefined,
            }}
        >
            <div className={c.content}>
                <MuiTypography variant={'h1'} className={c.title}>
                    {title}
                </MuiTypography>
                <div className={c.children}>{children}</div>
                <MuiSlide in={!isHideSubmits} direction={'up'} timeout={{ enter: 600, exit: 300 }}>
                    <div className={c.submits}>
                        <Button onClick={handleClose} className={c.cancelButton} role={'cancel'} variant={'text'}>
                            {txt({ en: 'Cancel', ja: 'やっぱやめる' })}
                        </Button>
                        <MutationButton
                            mutation={wrappedMutation}
                            className={c.submitButton}
                            role={isWarning ? 'dangerouslySubmit' : 'submit'}
                        >
                            {submitText}
                        </MutationButton>
                    </div>
                </MuiSlide>
            </div>
        </Modal>
    );
};
ConfirmationModal.displayName = 'ConfirmationModal';
