import { ref, set } from 'firebase/database';
import { collection, doc } from 'firebase/firestore';
import { getFirebaseRealtimeDatabase, getFirebaseFirestore } from '@/utils/GoogleCloud/firebase';
import { Message } from '@/utils/PowerchatClient/models';
import { getRdbLastMessageInput, AddRdbLastMessageInput } from '@/utils/PowerchatClient/RealtimeDatabase/RdbModels';

export const postRdbMessage = ({ roomId, newMessage }: { roomId: string; newMessage: AddRdbLastMessageInput }) => {
    const firestoreDb = getFirebaseFirestore();
    const { id } = doc(collection(firestoreDb, `Room/${roomId}/Message`));
    const realtimeDb = getFirebaseRealtimeDatabase();
    const lastMessageRef = ref(realtimeDb, `lastMessages/${roomId}`);
    const { replyToMessageId, mentionToUserIds } = {
        replyToMessageId: newMessage.addNotification.replyToUser?.id,
        mentionToUserIds: newMessage.addNotification.mentions?.map(({ id }) => id) || [],
    };
    set(lastMessageRef, {
        action: 'add',
        ...getRdbLastMessageInput({
            ...newMessage,
            id,
            createdAt: 'serverTimestamp',
            updatedAt: undefined,
            canceledAt: undefined,
            replyToMessageId,
            mentionToUserIds,
        }),
    });
    return {
        newMessage: new Message({
            id,
            createdAt: new Date(),
            updatedAt: undefined,
            serialNumber: undefined,
            userId: newMessage.userId,
            roomId,
            body: newMessage.body,
            replyToMessageId,
            mentionToUserIds,
            canceledAt: undefined,
        }),
    };
};
