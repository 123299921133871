import { FC } from 'react';
import clsx from 'clsx';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import MuiCircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
    '@keyframes gradation': {
        '0%': {
            color: '#FB8585',
        },
        '25%': {
            color: '#30A1E1',
        },
        '50%': {
            color: '#05D41A',
        },
        '75%': {
            color: '#D4CC05',
        },
        '100%': {
            color: '#05D496',
        },
    },
    root: {
        animation: '$gradation 4s linear infinite alternate',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '130px 0',
    },
    progress: {
        color: 'inherit',
    },
}));

export const LoadingIndicator: FC<{
    className?: string;
}> = ({ className }) => {
    // STYLE
    const c = useStyles(useTheme());

    return (
        <div className={clsx(c.root, className)}>
            <MuiCircularProgress size={24} className={c.progress} />
        </div>
    );
};
LoadingIndicator.displayName = 'LoadingIndicator';
