import { FC, ReactNode } from 'react';
import clsx from 'clsx';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import MuiDrawer from '@material-ui/core/Drawer';
import MuiTypography from '@material-ui/core/Typography';

import SvgChevronUp from '@/svgs/fa-chevron-up-regular';

import { useLanguage } from '@/utils/customHooks';
import { Button } from '@/components/0_atom/Button';

const useStyles = makeStyles((theme) => ({
    root: {
        overflowX: 'hidden',
        width: '100%',
        position: 'relative',
        '& .MuiDrawer-paper': {
            top: 'auto',
            right: 'auto',
            bottom: 'auto',
            left: 'auto',
            border: 'none',
            position: 'absolute',
            width: '100%',
        },
    },
    header: {
        marginBottom: 15,
    },
    backButton: {
        position: 'absolute',
        '& svg': {
            transform: 'rotate(270deg)',
        },
    },
    title: {
        textAlign: 'center',
        padding: '12px 140px',
        fontWeight: 600,
    },
    drawer: {
        transition: 'opacity .15s',
    },
    prev: {
        opacity: 0,
    },
}));

export const PagingView: FC<{
    className?: string;
    contents: {
        children: ReactNode;
        title?: string;
        isShowTitle: boolean;
    }[];
    currentIndex: number;
    setCurrentIndex: (currentIndex: number) => void;
    height: string | number;
}> = ({ className, contents, currentIndex, setCurrentIndex, height }) => {
    // STYLE
    const c = useStyles(useTheme());

    // HOOKS
    const { txt } = useLanguage();

    return (
        <div className={clsx(c.root, className)} style={{ height }}>
            {contents.map(({ children, title, isShowTitle }, i) => (
                <MuiDrawer
                    key={i}
                    anchor={'right'}
                    open={i <= currentIndex}
                    variant={'persistent'}
                    hideBackdrop
                    transitionDuration={{ enter: 400, exit: 300 }}
                    className={clsx(c.drawer, {
                        [c.prev]: i < currentIndex,
                    })}
                >
                    <div className={c.header}>
                        {i > 0 && (
                            <Button
                                startIcon={<SvgChevronUp />}
                                onClick={() => setCurrentIndex(i - 1)}
                                className={c.backButton}
                            >
                                {contents[i - 1]?.title ||
                                    txt({
                                        en: 'Back',
                                        ja: '戻る',
                                    })}
                            </Button>
                        )}
                        {isShowTitle && (
                            <MuiTypography variant={'body1'} className={c.title}>
                                {title}
                            </MuiTypography>
                        )}
                    </div>
                    <div>{children}</div>
                </MuiDrawer>
            ))}
        </div>
    );
};
PagingView.displayName = 'PagingView';
