import { GqlRing } from '@/utils/PowerchatClient/graphql/generated/graphqlClient';
import { isoToDate } from '@/utils/PowerchatClient/graphql/customScalars';

export type RingType = {
    id: string;
    createdAt: Date;
    serialNumber: number | undefined;
    roomId: string;
    fromUserId: string;
    toUserId: string;
};

export class Ring implements RingType {
    readonly id: string;

    readonly createdAt: Date;

    readonly serialNumber: number | undefined;

    readonly roomId: string;

    readonly fromUserId: string;

    readonly toUserId: string;

    constructor({ id, createdAt, serialNumber, roomId, fromUserId, toUserId }: RingType) {
        this.id = id;
        this.createdAt = createdAt;
        this.serialNumber = serialNumber;
        this.roomId = roomId;
        this.fromUserId = fromUserId;
        this.toUserId = toUserId;
    }
}

export const getRingFromGql = ({ id, createdAt, serialNumber, roomId, fromUserId, toUserId }: GqlRing) => {
    return new Ring({
        id,
        createdAt: isoToDate(createdAt),
        serialNumber,
        roomId,
        fromUserId,
        toUserId,
    });
};
