import { FC } from 'react';
import clsx from 'clsx';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import MuiListItem from '@material-ui/core/ListItem';
import MuiListItemIcon from '@material-ui/core/ListItemIcon';
import MuiListItemText from '@material-ui/core/ListItemText';
import MuiListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';

import { RoomApi, RoomMembershipApi } from '@/utils/PowerchatClient';
import { useRoomCtx, useRoomsCtx } from '@/utils/ctxs';
import { useHotMembers } from '@/utils/customHooks';
import { RoomIcon } from '@/components/2_org/RoomIcon';
import { UserIcon } from '@/components/2_org/UserIcon';

const useStyles = makeStyles((theme) => ({
    root: {},
    current: {
        background: 'rgba(0,0,0,.04)',
    },
    hotUser: {
        width: 18,
        height: 18,
        fontSize: 10,
    },
}));

export const RoomItem: FC<{
    className?: string;
    roomApi: RoomApi;
    membershipApi: RoomMembershipApi;
}> = ({ className, roomApi, membershipApi }) => {
    // STYLE
    const c = useStyles(useTheme());

    // HOOKS
    const { current, setRoomId } = useRoomCtx();
    const { hotUserIds, getHotUserItem } = useHotMembers({ roomId: roomApi.id });
    console.log({ hotUserIds });
    return (
        <MuiListItem
            className={clsx(c.root, className, {
                [c.current]: !!current && current.currentRoomApi.id === roomApi.id,
            })}
            onClick={() => setRoomId({ roomId: roomApi.id })}
            button
        >
            <MuiListItemIcon>
                <RoomIcon roomName={roomApi.name} roomId={roomApi.id} isHot={hotUserIds && hotUserIds.length > 1} />
            </MuiListItemIcon>
            <MuiListItemText primary={roomApi.name} />
            <MuiListItemSecondaryAction>
                {hotUserIds &&
                    hotUserIds.map((hotUserId) => {
                        console.log({ hotUserId });
                        const user = getHotUserItem(hotUserId);
                        if (user) {
                            return (
                                <UserIcon
                                    key={hotUserId}
                                    userName={user.userUniqueName}
                                    isHot={true}
                                    className={c.hotUser}
                                />
                            );
                        }
                        return <></>;
                    })}
            </MuiListItemSecondaryAction>
        </MuiListItem>
    );
};
RoomItem.displayName = 'RoomsTemplate/RoomItem';
