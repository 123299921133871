import { createContext, useContext } from 'react';

import { RoomMemberItem, LastCheckin } from '@/utils/customHooks';

export type RoomMembersCtxType = {
    userIds: string[] | undefined;
    getRoomMemberItem: (id: string) => RoomMemberItem | undefined;
    getRoomMemberItemAbsolutely: (id: string) => RoomMemberItem;
    lastCheckin: LastCheckin | undefined;
};

export const RoomMembersCtx = createContext<RoomMembersCtxType>({
    userIds: undefined,
    getRoomMemberItem: () => undefined,
    getRoomMemberItemAbsolutely: () => {
        throw new Error('RoomMembersCtx: not initialized!');
    },
    lastCheckin: undefined,
});
export const useRoomMembersCtx = () => {
    return useContext(RoomMembersCtx);
};
