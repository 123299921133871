import { FC } from 'react';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import MuiTypography from '@material-ui/core/Typography';
import { useUserCtx } from '@/utils/ctxs';
import { useLanguage } from '@/utils/customHooks';
import { Button } from '@/components/0_atom/Button';

const useStyles = makeStyles((theme) => ({
    root: {},
    buttonBox: {
        textAlign: 'right',
    },
    button: {
        marginTop: 15,
    },
}));

export const NotificationGrantDisplay: FC = () => {
    // STYLE
    const c = useStyles(useTheme());

    // HOOKS
    const { retryGetFcmToken } = useUserCtx();
    const { txt } = useLanguage();

    return (
        <div className={c.root}>
            <MuiTypography variant={'h1'}>
                {txt({
                    en: 'To use Power, you need to allow web notifications.',
                    ja: 'Powerを利用するには、Web通知を許可する必要があります。',
                })}
            </MuiTypography>
            <div className={c.buttonBox}>
                <Button role={'submit'} onClick={retryGetFcmToken} className={c.button}>
                    {txt({
                        en: 'Allow notifications',
                        ja: '通知を許可',
                    })}
                </Button>
            </div>
        </div>
    );
};
NotificationGrantDisplay.displayName = 'NotificationGrantDisplay';
