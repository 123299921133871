import { FC } from 'react';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import MuiGrow from '@material-ui/core/Grow';
import MuiPaper from '@material-ui/core/Paper';
import MuiList from '@material-ui/core/List';
import { useUserCtx, useHealthCtx, useMutationsCtx, useConnectionCtx, ProcessingMutation } from '@/utils/ctxs';
import { useLanguage } from '@/utils/customHooks';
import Health, { ServerState } from '@/components/2_org/NetworkIndicator/Health';
import Slot from '@/components/2_org/NetworkIndicator/Slot';

const useStyles = makeStyles((theme) => ({
    root: {
        position: 'fixed',
        zIndex: 10000,
        top: 14,
        right: 14,
        padding: '15px 16px',
        background: '#555',
        color: 'rgba(255,255,255,.8)',
        maxWidth: 240,
    },
    newWork: {},
    newWorkTitle: {},
    newWorkMessage: {},
    newWorkUrl: {},
    slots: {
        paddingBottom: 0,
    },
}));

export const NetworkIndicator: FC = () => {
    // STYLE
    const c = useStyles(useTheme());

    // HOOKS
    const { health, fetchStatus } = useHealthCtx();
    const { isConnected } = useConnectionCtx();
    const { isFirebaseError } = useUserCtx();
    const { slot1, slot2, slot3, slot4 } = useMutationsCtx();
    const { txt } = useLanguage();

    // DATA
    const serverState: ServerState = (() => {
        if (isFirebaseError) {
            return {
                status: 'authFetchError' as const,
                message: txt({
                    en: "Couldn't connect to the authentication server. Please check your network connection and reload.",
                    ja: '認証サーバーに接続できませんでした。ネットワーク接続を確認し、リロードしてください。',
                }),
            };
        }
        if (isConnected === false) {
            if (fetchStatus === 'fetching') {
                return {
                    status: 'fetching' as const,
                };
            }
            return {
                status: 'noConnection' as const,
            };
        }
        if (health) {
            const status = (() => {
                if (health.code === 'ok') {
                    return 'ok' as const;
                }
                return 'outOfService' as const;
            })();
            return {
                status,
                message: health.message,
                url: health.url,
            };
        }
        if (fetchStatus === 'fetchError') {
            return {
                status: 'fetchError' as const,
            };
        }
        return {
            status: 'fetching' as const,
        };
    })();
    const slots = [slot1, slot2, slot3, slot4].reduce((acc, prev) => {
        if (prev) {
            return [...acc, prev].sort((a, b) => (a.addedAt > b.addedAt ? -1 : 1));
        }
        return acc;
    }, [] as ProcessingMutation[]);
    console.log({ serverState, fetchStatus, isConnected, health });
    return (
        <MuiGrow in={!(serverState.status === 'ok') || slots.length > 0}>
            <MuiPaper className={c.root} elevation={16}>
                <Health serverState={serverState} />
                {slots.length > 0 && (
                    <MuiList className={c.slots}>
                        {slots.map((slot, i) => (
                            <Slot key={i} slot={slot} />
                        ))}
                    </MuiList>
                )}
            </MuiPaper>
        </MuiGrow>
    );
};
NetworkIndicator.displayName = 'NetworkIndicator';
