import { createContext, useContext } from 'react';
import { ReactionApi } from '@/utils/PowerchatClient';

export type LastReactionLog = {
    action: 'add' | 'remove';
    reactionApi: ReactionApi;
};

type LastReactionCtxType = {
    lastReactionLog: LastReactionLog | undefined;
};
export const LastReactionCtx = createContext<LastReactionCtxType>({
    lastReactionLog: undefined,
});
export const useLastReactionCtx = () => {
    return useContext(LastReactionCtx);
};
