import { FC } from 'react';
import clsx from 'clsx';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import MuiIconButton from '@material-ui/core/IconButton';

import SvgReply from '@/svgs/fa-reply-solid';

import { MessageApi } from '@/utils/PowerchatClient';
import { ButtonWithMenu } from '@/components/1_mol/ButtonWithMenu';

const useStyles = makeStyles((theme) => ({
    button: {
        padding: 6,
    },
    svg: {
        width: 13,
        height: 13,
    },
}));

export const ReplyButton: FC<{
    className?: string;
    messageApi: MessageApi;
    setReplyingToMessageApi: (replyingToMessageApi?: MessageApi) => void;
}> = ({ className, messageApi, setReplyingToMessageApi }) => {
    // STYLE
    const c = useStyles(useTheme());

    return (
        <>
            <MuiIconButton className={clsx(c.button, className)} onClick={() => setReplyingToMessageApi(messageApi)}>
                <SvgReply className={c.svg} />
            </MuiIconButton>
        </>
    );
};
ReplyButton.displayName = 'ReplyButton';
