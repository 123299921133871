import { FC, useState } from 'react';
import clsx from 'clsx';

import { useTheme, makeStyles } from '@material-ui/core/styles';

import { RoomApi, RoomMembershipApi, MessageApi, ReactionApi } from '@/utils/PowerchatClient';
import { useRoomCtx, useRoomsCtx, useRoomCtxAbsolutely, useUserCtxAbsolutely } from '@/utils/ctxs';
import { useLanguage } from '@/utils/customHooks';
import { Button } from '@/components/0_atom/Button';
import { RoomItem } from '@/components/3_template/ConsoleTemplate/RoomsTemplate/RoomItem';
import { useReactions } from '@/components/3_template/ConsoleTemplate/RoomTemplate/useReactions';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: '6px 9px',
        minWidth: 0,
        minHeight: 0,
        borderRadius: 13.5,
        '& .MuiButton-label': {
            lineHeight: '1',
        },
        '&:not(:first-child)': {
            marginLeft: 2,
        },
    },
    isUsers: {
        background: 'rgba(0,0,0,.03)',
    },
    emoji: {
        display: 'inline-block',
    },
    count: {
        fontSize: 10,
        display: 'inline-block',
        marginLeft: 4,
    },
}));

export const ReactionButton: FC<{
    className?: string;
    messageApi: MessageApi;
    letter: string;
    reactionApis: ReactionApi[];
}> = ({ className, messageApi, letter, reactionApis }) => {
    // STYLE
    const c = useStyles(useTheme());

    // HOOKS
    const { userClient } = useUserCtxAbsolutely();

    // DATA
    const userReactionApi = reactionApis.find(({ userId }) => userClient.id === userId);
    console.log({ userReactionApi, letter, reactionApis });
    // HANDLER
    const handleClick = () => {
        if (userReactionApi) {
            // remove
            userReactionApi.remove();
            return;
        }
        // add
        messageApi.createReaction({ letter });
    };

    return (
        <Button
            className={clsx(c.root, className, {
                [c.isUsers]: !!userReactionApi,
            })}
            onClick={handleClick}
        >
            <span className={c.emoji}>{letter}</span>
            <span className={c.count}>{reactionApis.length}</span>
        </Button>
    );
};
ReactionButton.displayName = 'RoomTemplate/ReactionButton';
