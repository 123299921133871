import { FC } from 'react';
import clsx from 'clsx';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import MuiList from '@material-ui/core/List';
import MuiListItem from '@material-ui/core/ListItem';
import MuiListItemText from '@material-ui/core/ListItemText';

import { useRoomsCtx } from '@/utils/ctxs';
import { useLanguage } from '@/utils/customHooks';
import { LoadingIndicator } from '@/components/0_atom/LoadingIndicator';
import { RoomItem } from '@/components/3_template/ConsoleTemplate/RoomsTemplate/RoomItem';
import { AddRoomButton } from '@/components/3_template/ConsoleTemplate/RoomsTemplate/AddRoomButton';

const useStyles = makeStyles((theme) => ({
    root: {
        width: 360,
        borderRight: 'solid 1px rgba(0,0,0,.03)',
        background: '#fff',
        // boxShadow: '0 0 4px 0 rgba(0,0,0,.02)',
        padding: '7px 14px',
    },
    actions: {
        textAlign: 'right',
    },
    norooms: {
        color: theme.palette.text.disabled,
        fontWeight: 600,
    },
}));

export const RoomsTemplate: FC<{
    className?: string;
}> = ({ className }) => {
    // STYLE
    const c = useStyles(useTheme());

    // HOOKS
    const { txt } = useLanguage();
    const { roomWithMemberships } = useRoomsCtx();

    return (
        <div className={clsx(c.root, className)}>
            <div className={c.actions}>
                <AddRoomButton />
            </div>
            <MuiList>
                {!roomWithMemberships ? (
                    <LoadingIndicator />
                ) : roomWithMemberships.length > 0 ? (
                    roomWithMemberships.map(({ roomApi, membershipApi }) => (
                        <RoomItem key={roomApi.id} roomApi={roomApi} membershipApi={membershipApi} />
                    ))
                ) : (
                    <MuiListItem className={c.norooms}>
                        <MuiListItemText
                            primary={txt({
                                en: 'No rooms',
                                ja: 'ルームがありません',
                            })}
                        />
                    </MuiListItem>
                )}
            </MuiList>
        </div>
    );
};
RoomsTemplate.displayName = 'RoomsTemplate';
