import { GraphQLSdk } from '@/utils/PowerchatClient/graphql/graphqlSdk';
import { ServiceHealth, getServiceHealthFromGql } from '@/utils/PowerchatClient/models';

type PowerchatPublicClientType = {
    getTos: () => Promise<{
        tos: string;
    }>;
    getPrivacyPolicy: () => Promise<{
        privacyPolicy: string;
    }>;
    getServiceHealth: () => Promise<{
        serviceHealth: ServiceHealth;
    }>;
};

type ConstructorInput = {
    graphqlSdk: GraphQLSdk;
};

export class PowerchatPublicClient implements PowerchatPublicClientType {
    protected _graphqlSdk: GraphQLSdk;

    constructor({ graphqlSdk }: ConstructorInput) {
        this._graphqlSdk = graphqlSdk;
    }

    async getTos() {
        const {
            getTos: { tos },
        } = await this._graphqlSdk.getTos();
        return {
            tos,
        };
    }

    async getPrivacyPolicy() {
        const {
            getPrivacyPolicy: { privacyPolicy },
        } = await this._graphqlSdk.getPrivacyPolicy();
        return {
            privacyPolicy,
        };
    }

    async getServiceHealth() {
        const {
            getServiceHealth: { serviceHealth },
        } = await this._graphqlSdk.getServiceHealth();
        return {
            serviceHealth: getServiceHealthFromGql(serviceHealth),
        };
    }
}
