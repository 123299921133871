import { createContext, useContext } from 'react';

type ConnectionCtxType = {
    isConnected: boolean | undefined;
    // updateCurrentRoomId: ((input: { currentRoomId: string | undefined }) => void) | undefined;
};
export const ConnectionCtx = createContext<ConnectionCtxType>({
    isConnected: undefined,
    // updateCurrentRoomId: undefined,
});
export const useConnectionCtx = () => {
    return useContext(ConnectionCtx);
};
export const useConnectionCtxAbsolutely = () => {
    const { isConnected } = useConnectionCtx();
    if (isConnected === undefined) {
        throw new Error('useConnectionCtxAbsolutely: isConnected === undefined.');
    }
    // if (isConnected === undefined || updateCurrentRoomId === undefined) {
    //     throw new Error('useConnectionCtxAbsolutely: isConnected === undefined || updateCurrentRoomId === undefined.');
    // }
    return {
        isConnected,
        // updateCurrentRoomId,
    };
};
