import { FC } from 'react';

const renderPre = (text: string) => {
    return text.replace(/\n```[\s*]?\n([^```]+)\n```[\s*]?\n/g, '\n<pre>$1</pre>\n');
};
const renderCode = (text: string) => {
    return text.replace(/`([^`]+)`/g, '<code>$1</code>');
};
const renderUl = (text: string) => {
    return text.replace(/((\n-\s[^\n]+)+)/g, (matchedStr) => {
        const lis = matchedStr.replace(/\n-\s([^\n]+)/g, '<li>$1</li>');
        return `<ul>${lis}</ul>`;
    });
};
const renderAtag = (text: string) => {
    return text.replace(
        /(https?:\/\/[^\s]+)/g,
        (matchedStr) =>
            `<a href="${encodeURI(matchedStr)}" target="_blank" rel="nofollow noopener noreferrer">${
                matchedStr.length > 40 ? `${matchedStr.substring(0, 40)}...` : matchedStr
            }</a>`
    );
};
const renderBr = (text: string) => {
    return text.replace(/\n/g, '<br/>');
};

type RenderOption = {
    pre: boolean;
    ul: boolean;
    br: boolean;
    code: boolean;
    a: boolean;
};

const escapeHTML = (raw: string) => {
    return raw.replace(
        /[&'`"<>]/g,
        (match) =>
            ({
                '&': '&amp;',
                "'": '&#x27;',
                '`': '&#x60;',
                '"': '&quot;',
                '<': '&lt;',
                '>': '&gt;',
                ';': '&59;',
            }[match] || '')
    );
};

const renderHtmlTextFromFormattedText = ({
    formattedText,
    renderOption,
}: {
    formattedText: string;
    renderOption: RenderOption;
}) => {
    let renderedText = escapeHTML(formattedText);
    if (renderOption.pre) {
        renderedText = renderPre(renderedText);
    }
    if (renderOption.code) {
        renderedText = renderCode(renderedText);
    }
    if (renderOption.ul) {
        renderedText = renderUl(renderedText);
    }
    if (renderOption.a) {
        renderedText = renderAtag(renderedText);
    }
    if (renderOption.br) {
        renderedText = renderBr(renderedText);
    }
    return renderedText;
};

export const FormattedTextRenderer: FC<{
    className?: string;
    text: string;
    renderOption: RenderOption;
}> = ({ className, text, renderOption }) => {
    return (
        <div
            className={className}
            dangerouslySetInnerHTML={{
                __html: renderHtmlTextFromFormattedText({
                    formattedText: text,
                    renderOption,
                }),
                // __html: 'First &middot; Second',
            }}
        />
    );
};

FormattedTextRenderer.displayName = 'FormattedTextRenderer';
