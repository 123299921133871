/* eslint-disable import/no-extraneous-dependencies */
import { GraphQLClient } from 'graphql-request';
import { getSdk, ServiceHealthCode } from '@/utils/PowerchatClient/graphql/generated/graphqlClient';
import { graphqlEndPoint } from '@/utils/PowerchatClient/config';

export const getGraphqlSdk = ({
    firebaseUserIdToken,
    fcmToken,
}: {
    firebaseUserIdToken: string | undefined;
    fcmToken: string | undefined;
}) => {
    const client = new GraphQLClient(graphqlEndPoint, {
        headers: {
            browsertoken: firebaseUserIdToken || '',
            fcmtoken: fcmToken || '',
        },
    });
    return getSdk(client);
};

export type GraphQLSdk = ReturnType<typeof getGraphqlSdk>;

export type ServiceHealth = {
    code: ServiceHealthCode;
    message?: string;
    url?: string;
};
export const getServiceHealth = async (): Promise<ServiceHealth> => {
    const {
        getServiceHealth: { serviceHealth },
    } = await getGraphqlSdk({ firebaseUserIdToken: undefined, fcmToken: undefined }).getServiceHealth();
    return {
        code: serviceHealth.code,
        message: serviceHealth.message || undefined,
        url: serviceHealth.url || undefined,
    };
};
