import { useState, useCallback, useEffect } from 'react';

import { MessageApi } from '@/utils/PowerchatClient';
import { useRoomCtxAbsolutely } from '@/utils/ctxs';

export const useReplyingToMessage = ({
    messageId,
    getMessageApi,
}: {
    messageId: string;
    getMessageApi: (id: string) => MessageApi | undefined;
}) => {
    // HOOKS
    const {
        current: { currentRoomApi },
    } = useRoomCtxAbsolutely();
    const [messageApi, setMessageApi] = useState<MessageApi | undefined>(undefined);

    // CALLBACK
    const fetchMessageApi = useCallback(async () => {
        const messageRowInLocal = getMessageApi(messageId);
        if (messageRowInLocal) {
            setMessageApi(messageRowInLocal);
            return messageRowInLocal;
        }
        const result = await currentRoomApi.getMessage({ messageId });
        setMessageApi(result.messageApi);
        return result;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // USEEFFECT
    useEffect(() => {
        fetchMessageApi();
    }, [fetchMessageApi]);

    return {
        replyingToMessageApi: messageApi,
    };
};
