import { createContext, useContext } from 'react';

import { RoomApi, RoomMembershipApi } from '@/utils/PowerchatClient';

export type RoomsCtxType = {
    roomWithMemberships:
        | {
              roomApi: RoomApi;
              membershipApi: RoomMembershipApi;
          }[]
        | undefined;
    refetchRooms: () => Promise<void>;
};

export const RoomsCtx = createContext<RoomsCtxType>({
    roomWithMemberships: undefined,
    refetchRooms: () =>
        new Promise((r) => {
            r();
        }),
});
export const useRoomsCtx = () => {
    return useContext(RoomsCtx);
};
export const useRoomsCtxAbsolutely = () => {
    const { roomWithMemberships, ...otherProps } = useRoomsCtx();
    if (!roomWithMemberships) {
        throw new Error('useRoomsCtxAbsolutely: !roomWithMemberships.');
    }
    return {
        roomWithMemberships,
        ...otherProps,
    };
};
