import { FC } from 'react';

import CircularProgress from '@material-ui/core/CircularProgress';
import { ProcessingMutation } from '@/utils/ctxs';

import SvgCheck from '@/svgs/fa-check-light';
import SvgTimes from '@/svgs/fa-exclamation';

export type ActionState = 'trying' | 'success' | 'error';

const MutationStateIcon: FC<{ actionState?: ActionState }> = ({ actionState }) => {
    return actionState === 'trying' ? (
        <CircularProgress size={14} color={'inherit'} />
    ) : actionState === 'error' ? (
        <SvgTimes />
    ) : actionState === 'success' ? (
        <SvgCheck />
    ) : (
        <></>
    );
};
MutationStateIcon.displayName = 'MutationStateIcon';
export default MutationStateIcon;

export const getActionState = (currentSlot?: ProcessingMutation): ActionState | undefined => {
    return currentSlot?.status === 'fetching'
        ? 'trying'
        : currentSlot?.status === 'success'
        ? 'success'
        : currentSlot
        ? 'error'
        : undefined;
};
export const getMutationStateIcon = ({ actionState }: { actionState?: ActionState }) => {
    if (actionState) {
        return <MutationStateIcon actionState={actionState} />;
    }
    return undefined;
};
