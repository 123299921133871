import { createContext, useContext } from 'react';

export type MutationResult = 'success' | 'failed';
export type ProcessingMutationStatus = MutationResult | 'fetching' | 'fetchError' | 'canceled';
export type ProcessingMutation = {
    description: string;
    retry: () => void;
    cancel: () => void;
    addedAt: Date;
    status: ProcessingMutationStatus;
};

export type AddMutationInput = {
    run: () => Promise<MutationResult> | never;
    description: string;
    onThen?: (result: MutationResult) => void;
};
export type WrappedAddMutationInput<T> = {
    run: (input: T) => Promise<MutationResult> | never;
    description: string;
    onThen?: (result: MutationResult) => void;
};

type MutationsCtxType = {
    slot1?: ProcessingMutation;
    slot2?: ProcessingMutation;
    slot3?: ProcessingMutation;
    slot4?: ProcessingMutation;
    addMutation: (newMutation: AddMutationInput) => 1 | 2 | 3 | 4 | never;
};

export const MutationsCtx = createContext<MutationsCtxType>({
    addMutation: () => 1,
});
export const useMutationsCtx = () => {
    return useContext(MutationsCtx);
};

export const getWrappedMutation = ({
    mutation,
    additionalOnThen,
}: {
    mutation: AddMutationInput;
    additionalOnThen: (result: MutationResult) => void;
}) => ({
    ...mutation,
    onThen: (result: MutationResult) => {
        mutation.onThen && mutation.onThen(result);
        additionalOnThen(result);
    },
});
