import { serverTimestamp } from 'firebase/database';

export type MentionToUser = {
    id: string;
    uniqueName: string;
};
export type ReplyToUser = {
    id: string;
    uniqueName: string;
};
type RdbLastMessageAddNotification = {
    replyToUser: ReplyToUser | undefined;
    mentions: MentionToUser[] | undefined;
    userName: string;
    roomName: string;
};
export type RdbLastMessage = {
    action: 'add' | 'modify';
    id: string;
    createdAt: number;
    updatedAt: number | undefined;
    userId: string;
    body: string;
    canceledAt: number | undefined;
    replyToMessageId: string | undefined;
    mentionToUserIds: string[] | undefined;
    // for notification
    addNotification: RdbLastMessageAddNotification | undefined;
};
export type RdbLastMessageInput = {
    id: string;
    createdAt: Date | 'serverTimestamp';
    updatedAt: Date | 'serverTimestamp' | undefined;
    userId: string;
    body: string;
    canceledAt: Date | 'serverTimestamp' | undefined;
    replyToMessageId: string | undefined;
    mentionToUserIds: string[] | undefined;
    // for notification
    addNotification: RdbLastMessageAddNotification | undefined;
};
export type AddRdbLastMessageInput = {
    userId: string;
    body: string;
    // for notification
    addNotification: RdbLastMessageAddNotification;
};
export type ModifyRdbLastMessageInput = {
    id: string;
    createdAt: Date | 'serverTimestamp';
    userId: string;
    body: string;
    canceledAt: Date | 'serverTimestamp' | undefined;
    replyToMessageId: string | undefined;
    mentionToUserIds: string[] | undefined;
};

export const getRdbLastMessageInput = ({
    id,
    createdAt,
    updatedAt,
    userId,
    body,
    canceledAt,
    replyToMessageId,
    mentionToUserIds,
    addNotification,
}: RdbLastMessageInput) => {
    return {
        id,
        createdAt: createdAt === 'serverTimestamp' ? serverTimestamp() : createdAt.getTime(),
        updatedAt: updatedAt === 'serverTimestamp' ? serverTimestamp() : updatedAt ? updatedAt.getTime() : null,
        userId,
        body,
        canceledAt: canceledAt === 'serverTimestamp' ? serverTimestamp() : canceledAt ? canceledAt.getTime() : null,
        replyToMessageId: replyToMessageId || null,
        mentionToUserIds: mentionToUserIds || null,
        addNotification: (() => {
            if (addNotification) {
                return {
                    mentions: addNotification.mentions?.map(({ id, uniqueName }) => ({ id, uniqueName })) || null,
                    replyToUser: (() => {
                        if (addNotification.replyToUser) {
                            return {
                                id: addNotification.replyToUser.id,
                                uniqueName: addNotification.replyToUser.uniqueName,
                            };
                        }
                        return null;
                    })(),
                    roomName: addNotification.roomName,
                    userName: addNotification.userName,
                };
            }
            return null;
        })(),
    };
};
