const getColorFromNumbers = ({ numbers, textColor }: { numbers: [number, number, number]; textColor: string }) => {
    if (numbers.find((num) => Number.isNaN(num))) {
        return textColor;
    }
    const sum = numbers[0] + numbers[1] + numbers[2];
    if (sum > 400) {
        return textColor;
    }
    return '#fff';
};

export const getTextColorForBackgroundColor = ({
    textColor,
    backgroundColor,
}: {
    textColor: string;
    backgroundColor: string;
}) => {
    if (backgroundColor.match(/^#/)) {
        const str = backgroundColor.replace(/^#/, ''); // 'FFFFFF'
        if (str.length === 6) {
            const hexStrs = [
                str.charAt(0) + str.charAt(1),
                str.charAt(2) + str.charAt(3),
                str.charAt(4) + str.charAt(5),
            ]; // ['FF', 'FF', 'FF']
            const numbers = hexStrs.map((hexStr) => parseInt(hexStr, 16)) as [number, number, number]; // [255, 255, 255]
            return getColorFromNumbers({ numbers, textColor });
        }
        if (str.length === 3) {
            const hexStrs = [str.charAt(0), str.charAt(1), str.charAt(2)]; // ['F', 'F', 'F']
            const numbers = hexStrs.map((hexStr) => parseInt(hexStr + hexStr, 16)) as [number, number, number]; // [255, 255, 255]
            return getColorFromNumbers({ numbers, textColor });
        }
        return textColor;
    }
    if (backgroundColor.match(/^rgb\(/)) {
        const withComma = backgroundColor.replace(/^rgb\(/, '').replace(/\).*$/, ''); // '255,255,255'
        const numberStrs = withComma.split(','); // ['255', '255', '255']
        const numbers = numberStrs.map((numberStr) => Number(numberStr)) as [number, number, number]; // [255, 255, 255]
        return getColorFromNumbers({ numbers, textColor });
    }
    return textColor;
};
