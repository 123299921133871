import { ref, onValue } from 'firebase/database';
import { getFirebaseRealtimeDatabase } from '@/utils/GoogleCloud/firebase';
import { Reaction } from '@/utils/PowerchatClient';
import { RdbLastReaction } from '@/utils/PowerchatClient/RealtimeDatabase/RdbModels';

export const listenToReactions = ({
    roomId,
    onAdded,
    onRemoved,
}: {
    roomId: string;
    onAdded: (input: { newReaction: Reaction }) => void;
    onRemoved: (input: { removedReaction: Reaction }) => void;
}) => {
    const db = getFirebaseRealtimeDatabase();
    const lastReactionRef = ref(db, `lastReactions/${roomId}`);
    const firedAt = new Date();
    const unsubscribeListenToReactions = onValue(lastReactionRef, (data) => {
        if (data.exists()) {
            const { action, removedAt, ...reactionData }: RdbLastReaction = data.val();
            const reaction = new Reaction({
                id: reactionData.id,
                createdAt: new Date(reactionData.createdAt),
                userId: reactionData.userId,
                roomId,
                messageId: reactionData.messageId,
                letter: reactionData.letter,
            });
            if (firedAt <= reaction.createdAt && action === 'add') {
                onAdded({
                    newReaction: reaction,
                });
            } else if (removedAt && firedAt <= new Date(removedAt) && action === 'remove') {
                onRemoved({
                    removedReaction: reaction,
                });
            }
        }
    });
    return {
        unsubscribeListenToReactions,
    };
};
