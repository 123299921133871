import { ref, set } from 'firebase/database';
import { getFirebaseRealtimeDatabase } from '@/utils/GoogleCloud/firebase';
import { getRdbUserInput } from '@/utils/PowerchatClient/RealtimeDatabase/RdbModels';
import { Language } from '@/utils/customHooks/useLanguage';

export const updateCurrentRoomId = ({
    userId,
    currentRoomId,
    currentRoomName,
    currentFcmToken,
    userUniqueName,
    language,
}: {
    userId: string;
    currentRoomId: string | undefined;
    currentRoomName: string | undefined;
    currentFcmToken: string;
    userUniqueName: string;
    language: Language | undefined;
}) => {
    const db = getFirebaseRealtimeDatabase();
    const userRef = ref(db, `users/${userId}`);

    set(
        userRef,
        getRdbUserInput({
            updatedAt: 'serverTimestamp',
            currentRoomId,
            currentRoomName,
            isOnline: true,
            currentFcmToken,
            userUniqueName,
            language,
        })
    );
};
