import { useEffect, useState } from 'react';

import { LocalTimelineRow } from '@/utils/PowerchatClient';
import { useRoomCtxAbsolutely, useRoomMembersCtx } from '@/utils/ctxs';
import { useLazyItems } from '@/utils/customHooks';

export const useLocalTimeline = () => {
    // HOOKS
    const {
        current: { currentRoomApi },
    } = useRoomCtxAbsolutely();
    const { lastCheckin } = useRoomMembersCtx();
    const { ids, getItem, getItemAbsolutely, addItem, initialize, updateItem } = useLazyItems<
        LocalTimelineRow & { id: string }
    >([]);
    const [mountedAt, setMountedAt] = useState(new Date());

    // USEEFFECT
    useEffect(() => {
        const { unsubscribeListenToMessages } = currentRoomApi.listenToMessages({
            onAdded: ({ newMessageApi }) => {
                addItem({
                    newItem: {
                        id: newMessageApi.id,
                        messageApi: newMessageApi,
                        ring: undefined,
                        checkin: undefined,
                        checkout: undefined,
                    },
                    isIgnoreDuplicationError: true,
                });
            },
            onModified: ({ modifiedMessageApi }) => {
                console.log({ modifiedMessageApi });
                if (modifiedMessageApi.createdAt >= mountedAt) {
                    updateItem({
                        id: modifiedMessageApi.id,
                        updator: (prevItem) => {
                            return {
                                ...prevItem,
                                messageApi: modifiedMessageApi,
                            };
                        },
                    });
                    // const targetItem = getItemAbsolutely(modifiedMessageApi.id);
                    // updateItem({
                    //     ...targetItem,
                    //     messageApi: modifiedMessageApi,
                    // });
                }
            },
        });
        return () => {
            unsubscribeListenToMessages();
            initialize([]);
            setMountedAt(new Date());
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentRoomApi.id]);
    useEffect(() => {
        if (lastCheckin) {
            addItem({
                newItem: {
                    id: `localCheckin_${lastCheckin.userId}_${lastCheckin.createdAt.toString()}`,
                    checkin: !lastCheckin.leftAt ? lastCheckin : undefined,
                    checkout: lastCheckin.leftAt ? lastCheckin : undefined,
                    messageApi: undefined,
                    ring: undefined,
                },
                isIgnoreDuplicationError: true,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lastCheckin]);

    return {
        timelineRowIds: ids,
        getTimelineRow: getItem,
        getTimelineRowAbsolutely: getItemAbsolutely,
    };
};
