import { useEffect, useState, useCallback } from 'react';

import { useErrorCtx } from '@/utils/ctxs';
import { getFirebaseCloudMessaging } from '@/utils/GoogleCloud/firebase';

export const useFirebaseCloudMessaging = () => {
    // HOOKS
    const { setIs500 } = useErrorCtx();
    const [isBrowserNotSupported, setIsBrowserNotSupported] = useState<boolean | undefined>(undefined);
    const [token, setToken] = useState<string | undefined>(undefined);

    // CALLBACK
    const getToken = useCallback(async () => {
        const notificationPermission = Notification.permission;
        if (notificationPermission !== 'granted') {
            const newPermission = await Notification.requestPermission();
            if (newPermission !== 'granted') {
                return;
            }
        }
        const { getFcmToken } = getFirebaseCloudMessaging();
        const fcmToken = await getFcmToken().catch((e) => {
            console.log({ e });
            setIsBrowserNotSupported(true);
            setIs500(true);
            return undefined;
        });
        setToken(fcmToken);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // USEEFFECT
    useEffect(() => {
        getToken();
    }, [getToken]);

    return {
        fcmToken: token,
        isNeedToGrantNotification: Notification.permission !== 'granted',
        isBrowserNotSupported,
        retryGetFcmToken: getToken,
    };
};
