import { useEffect, useState, useCallback } from 'react';

import { getConnectedRef, onValue } from '@/utils/GoogleCloud/realtimeDatabase';

export const useConnection = () => {
    // HOOKS
    const [isConnected, setIsConnected] = useState<boolean | undefined>(undefined);
    // const [updateCurrentRoomId, setUpdateCurrentRoomId] = useState<
    //     (({ currentRoomId }: { currentRoomId: string | undefined }) => void) | undefined
    // >(undefined);

    // CALLBACK
    const initConnection = useCallback(async () => {
        const { connectedRef } = getConnectedRef();
        // setUpdateCurrentRoomId(gotUpdateCurrentRoomId);
        let isInitialized = false;
        onValue(connectedRef, (snap) => {
            if (snap.val()) {
                setIsConnected(true);
                isInitialized = true;
            } else if (isInitialized) {
                setIsConnected(false);
            }
        });
    }, []);

    // USEEFFECT
    useEffect(() => {
        initConnection();
    }, [initConnection]);

    return {
        isConnected,
        // updateCurrentRoomId,
    };
};
