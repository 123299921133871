import { FC, useState, useEffect, useCallback } from 'react';
import clsx from 'clsx';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import MuiIconButton from '@material-ui/core/IconButton';
import MuiTypography from '@material-ui/core/Typography';
import MuiAvatar from '@material-ui/core/Avatar';
import MuiListItemText from '@material-ui/core/ListItemText';

import SvgPlus from '@/svgs/fa-plus-solid';

import { RoomApi, RoomMembershipApi } from '@/utils/PowerchatClient';
import { useRoomCtx, useRoomsCtx, useUserCtxAbsolutely } from '@/utils/ctxs';
import { useLanguage } from '@/utils/customHooks';
import { PagingView } from '@/components/0_atom/PagingView';
import { ConfirmationModal } from '@/components/1_mol/ConfirmationModal';
import { AddRoomButtonStep1 } from '@/components/3_template/ConsoleTemplate/RoomsTemplate/AddRoomButton/Step1';
import { AddRoomButtonStep2 } from '@/components/3_template/ConsoleTemplate/RoomsTemplate/AddRoomButton/Step2';

const useStyles = makeStyles((theme) => ({
    root: {
        '& svg': {
            width: 14,
            height: 14,
        },
    },
    current: {},
}));

export const AddRoomButton: FC<{
    className?: string;
}> = ({ className }) => {
    // STYLE
    const c = useStyles(useTheme());

    // HOOKS
    const { userClient } = useUserCtxAbsolutely();
    const { txt } = useLanguage();
    const { refetchRooms } = useRoomsCtx();
    const [isOpen, setIsOpen] = useState(false);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [newRoomName, setNewRoomName] = useState<string | undefined>(undefined);
    const [members, setMembers] = useState<
        {
            userCode: string;
            uniqueName: string;
        }[]
    >([]);

    // CALLBACK
    const runCreateRoom = useCallback(async () => {
        if (newRoomName) {
            await userClient.createRoom({ roomName: newRoomName, members });
            refetchRooms();
            setIsOpen(false);
            return 'success' as const;
        }
        throw new Error('never');
    }, [newRoomName, members, userClient, refetchRooms]);

    // DATA
    // const currentIndex = newRoomName ? 1 : 0;

    // USEEFFECT
    useEffect(() => {
        return () => {
            setCurrentIndex(0);
            setNewRoomName(undefined);
            setMembers([]);
        };
    }, [isOpen]);

    return (
        <>
            <MuiIconButton className={clsx(c.root, className)} onClick={() => setIsOpen(true)}>
                <SvgPlus />
            </MuiIconButton>
            <ConfirmationModal
                isOpen={isOpen}
                onClose={() => setIsOpen(false)}
                title={txt({
                    en: 'New chat',
                    ja: '新しいチャット',
                })}
                mutation={{
                    run: runCreateRoom,
                    description: txt({
                        en: 'Create new chat',
                        ja: '新しいルームを作成',
                    }),
                }}
                submitText={txt({
                    en: 'Create',
                    ja: '作成',
                })}
                isHideSubmits={currentIndex === 0}
            >
                <PagingView
                    contents={[
                        {
                            title: undefined,
                            children: (
                                <AddRoomButtonStep1
                                    setNewRoomName={(newValue) => {
                                        setNewRoomName(newValue);
                                        if (newValue) {
                                            setCurrentIndex(1);
                                        }
                                    }}
                                    setIsOpen={setIsOpen}
                                />
                            ),
                            isShowTitle: false,
                        },
                        {
                            title: newRoomName || 'never',
                            children: <AddRoomButtonStep2 members={members} setMembers={setMembers} />,
                            isShowTitle: true,
                        },
                    ]}
                    currentIndex={currentIndex}
                    setCurrentIndex={setCurrentIndex}
                    height={420}
                />
            </ConfirmationModal>
        </>
    );
};
AddRoomButton.displayName = 'RoomsTemplate/AddRoomButton';
