import { FC } from 'react';
import clsx from 'clsx';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import MuiTypography from '@material-ui/core/Typography';

import SvgEllipsis from '@/svgs/ellipsis';

import { useRoomCtxAbsolutely, useRoomMembersCtx } from '@/utils/ctxs';
import { useLanguage, RoomMemberItem } from '@/utils/customHooks';
import { LoadingIndicator } from '@/components/0_atom/LoadingIndicator';
import { RoomIcon } from '@/components/2_org/RoomIcon';
import { UserIconWithName } from '@/components/2_org/UserIconWithName';
import { useMessageItems } from '@/components/3_template/ConsoleTemplate/RoomTemplate/useMessageItems';
import { MessageCard } from '@/components/3_template/ConsoleTemplate/RoomTemplate/MessageCard';

const useStyles = makeStyles((theme) => ({
    root: {},
    room: {
        display: 'flex',
        alignItems: 'center',
    },
    roomIcon: {
        marginRight: 12,
    },
    roomName: {
        fontWeight: 600,
        color: '#000',
    },
    members: {
        marginTop: 24,
    },
    heading: {
        display: 'flex',
        alignItems: 'center',
        fontSize: 12,
        lineHeight: '1',
        '& svg': {
            width: 10,
            height: 10,
            marginRight: 4,
        },
    },
    hotColor: {
        color: theme.palette.primary.main,
    },
    coldColor: {
        color: theme.palette.text.disabled,
    },
    member: {
        display: 'block',
        fontWeight: 600,
        marginTop: 10,
        color: '#000',
    },
    memberIcon: {
        width: 26,
        height: 26,
        marginRight: 10,
    },
    coldMember: {
        opacity: 0.5,
    },
}));

export const MembersView: FC<{
    className?: string;
}> = ({ className }) => {
    // STYLE
    const c = useStyles(useTheme());

    // HOOKS
    const {
        current: { currentRoomApi },
    } = useRoomCtxAbsolutely();
    const { userIds, getRoomMemberItem } = useRoomMembersCtx();
    const { txt } = useLanguage();

    // DATA
    const { hotMembers, coldMembers } = (() => {
        if (userIds) {
            return userIds.reduce(
                (acc, userId) => {
                    const member = getRoomMemberItem(userId);
                    if (member && member.rdbUser?.currentRoomId === member.membershipApi.roomId) {
                        return {
                            hotMembers: [...acc.hotMembers, member],
                            coldMembers: acc.coldMembers,
                        };
                    }
                    if (member) {
                        return {
                            hotMembers: acc.hotMembers,
                            coldMembers: [...acc.coldMembers, member],
                        };
                    }
                    return acc;
                },
                { hotMembers: [], coldMembers: [] } as {
                    hotMembers: RoomMemberItem[];
                    coldMembers: RoomMemberItem[];
                }
            );
        }
        return { hotMembers: undefined, coldMembers: undefined };
    })();

    console.log({ hotMembers, coldMembers });

    return (
        <div className={clsx(c.root, className)}>
            <div className={c.room}>
                <RoomIcon
                    roomName={currentRoomApi.name}
                    roomId={currentRoomApi.id}
                    isHot={!!hotMembers && hotMembers.length > 1}
                    className={c.roomIcon}
                />
                <MuiTypography variant={'body1'} className={c.roomName}>
                    {currentRoomApi.name}
                </MuiTypography>
            </div>
            <div className={c.members}>
                <div className={clsx(c.heading, c.hotColor)}>
                    <SvgEllipsis />
                    {txt({
                        en: 'ONLINE',
                        ja: 'オンライン',
                    })}
                </div>
                {hotMembers ? (
                    hotMembers.map((hotMember) => (
                        <UserIconWithName
                            key={hotMember.user.id}
                            userName={hotMember.user.uniqueName}
                            isHot={true}
                            className={c.member}
                            iconClassName={c.memberIcon}
                        />
                    ))
                ) : (
                    <LoadingIndicator />
                )}
            </div>
            <div className={c.members}>
                <div className={clsx(c.heading, c.coldColor)}>
                    <SvgEllipsis />
                    {txt({
                        en: 'OFFLINE',
                        ja: 'オフライン',
                    })}
                </div>
                {coldMembers ? (
                    coldMembers.map((coldMember) => (
                        <UserIconWithName
                            key={coldMember.user.id}
                            userName={coldMember.user.uniqueName}
                            isHot={false}
                            className={clsx(c.member, c.coldMember)}
                            iconClassName={c.memberIcon}
                        />
                    ))
                ) : (
                    <LoadingIndicator />
                )}
            </div>
        </div>
    );
};
MembersView.displayName = 'RoomTemplate/MembersView';
