import { ref, onValue } from 'firebase/database';
import { getFirebaseRealtimeDatabase } from '@/utils/GoogleCloud/firebase';
import { Message } from '@/utils/PowerchatClient/models';
import { RdbLastMessage } from '@/utils/PowerchatClient/RealtimeDatabase/RdbModels';

export const listenToMessages = ({
    roomId,
    onAdded,
    onModified,
}: {
    roomId: string;
    onAdded: (input: { newMessage: Message }) => void;
    onModified: (input: { modifiedMessage: Message }) => void;
}) => {
    const db = getFirebaseRealtimeDatabase();
    const lastMessageRef = ref(db, `lastMessages/${roomId}`);
    const firedAt = new Date();
    const unsubscribeListenToMessages = onValue(lastMessageRef, (data) => {
        if (data.exists()) {
            const { action, ...messageData }: RdbLastMessage = data.val();
            const message = new Message({
                id: messageData.id,
                createdAt: new Date(messageData.createdAt),
                updatedAt: messageData.updatedAt ? new Date(messageData.updatedAt) : undefined,
                serialNumber: undefined,
                userId: messageData.userId,
                roomId,
                body: messageData.body,
                replyToMessageId: messageData.replyToMessageId,
                mentionToUserIds: messageData.mentionToUserIds || [],
                canceledAt: messageData.canceledAt ? new Date(messageData.canceledAt) : undefined,
            });
            if (firedAt <= message.createdAt && action === 'add') {
                onAdded({
                    newMessage: message,
                });
            } else if (message.updatedAt && firedAt <= message.updatedAt && action === 'modify') {
                onModified({
                    modifiedMessage: message,
                });
            }
        }
    });
    return {
        unsubscribeListenToMessages,
    };
};
