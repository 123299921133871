import { FC } from 'react';
import clsx from 'clsx';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import MuiIconButton from '@material-ui/core/IconButton';

import { generateColorForText } from '@/utils/utilFunctions';

const useStyles = makeStyles((theme) => ({
    root: {
        borderRadius: '50%',
        display: 'inline-flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontWeight: 600,
    },
    online: {
        outline: 'solid 1px #fff',
        boxShadow: `0 0 0 2px ${theme.palette.primary.main}`,
    },
}));

export const UserIcon: FC<{
    className?: string;
    onClick?: () => void;
    userName: string;
    isHot?: boolean;
}> = ({ className, onClick, userName, isHot }) => {
    // STYLE
    const c = useStyles(useTheme());

    // DATA
    const { color, textColor } = generateColorForText({ text: userName });

    return onClick ? (
        <MuiIconButton
            className={clsx(c.root, className)}
            onClick={onClick}
            style={{
                background: color,
                color: textColor,
            }}
        >
            {userName[0]?.toUpperCase()}
        </MuiIconButton>
    ) : (
        <span
            className={clsx(c.root, className, {
                [c.online]: isHot,
            })}
            style={{
                background: color,
                color: textColor,
            }}
        >
            {userName[0]?.toUpperCase()}
        </span>
    );
};
UserIcon.displayName = 'UserIcon';
