import { GqlUser } from '@/utils/PowerchatClient/graphql/generated/graphqlClient';
import { isoToDate, isoToDateOptionally } from '@/utils/PowerchatClient/graphql/customScalars';

export type UserType = {
    id: string;
    createdAt: Date;
    updatedAt: Date | undefined;
    uniqueName: string;
    uniqueCode: string;
    canceledAt: Date | undefined;
};

export class User implements UserType {
    readonly id: string;

    readonly createdAt: Date;

    updatedAt: Date | undefined;

    readonly uniqueName: string;

    readonly uniqueCode: string;

    canceledAt: Date | undefined;

    constructor({ id, createdAt, updatedAt, uniqueName, uniqueCode, canceledAt }: UserType) {
        this.id = id;
        this.createdAt = createdAt;
        this.updatedAt = updatedAt;
        this.uniqueName = uniqueName;
        this.uniqueCode = uniqueCode;
        this.canceledAt = canceledAt;
    }

    async _updateCanceledAt({ canceledAt, updatedAt }: { canceledAt: Date; updatedAt: Date }) {
        this.canceledAt = canceledAt;
        this.updatedAt = updatedAt;
    }
}

export const getUserFromGql = ({ id, createdAt, updatedAt, uniqueName, uniqueCode, canceledAt }: GqlUser) => {
    return new User({
        id,
        createdAt: isoToDate(createdAt),
        updatedAt: isoToDateOptionally(updatedAt),
        uniqueName,
        uniqueCode,
        canceledAt: isoToDateOptionally(canceledAt),
    });
};
