import { getGraphqlSdk } from '@/utils/PowerchatClient/graphql/graphqlSdk';
import { PowerchatFirebaseUserClient } from '@/utils/PowerchatClient/clients';
import { Language } from '@/utils/customHooks';

export const initPowerchatFirebaseUserClient = (input: {
    firebaseUid: string;
    firebaseUserIdToken: string;
    fcmToken: string;
    language: Language | undefined;
}) => {
    const graphqlSdk = getGraphqlSdk(input);
    return new PowerchatFirebaseUserClient({
        graphqlSdk,
        firebaseUid: input.firebaseUid,
        currentFcmToken: input.fcmToken,
        language: input.language,
    });
};
