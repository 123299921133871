import { parseISO } from 'date-fns';

export class GqlDateTimeData {
    static parseValue(date: Date): string {
        if (date instanceof Date) {
            return date.toISOString();
        }
        throw new Error('GraphQLScalarType DateTime.parseValue(arg) arg should be Date');
    }

    static serialize(isoString: string): Date {
        const date = parseISO(isoString);
        if (date.toString() === 'Invalid Date') {
            throw new Error('GraphQLScalarType DateTime.serialize(arg) arg should be ISO8601 string');
        }
        return date;
    }
}

export const isoToDate = (isoString: string) => {
    return GqlDateTimeData.serialize(isoString);
};

export const dateToIso = (date: Date) => {
    return GqlDateTimeData.parseValue(date);
};

export const isoToDateOptionally = (isoString: string | undefined | null) => {
    if (isoString) {
        return GqlDateTimeData.serialize(isoString);
    }
    return undefined;
};

export const dateToIsoOptionally = (date: Date | undefined | null) => {
    if (date) {
        return GqlDateTimeData.parseValue(date);
    }
    return undefined;
};
