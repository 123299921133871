import { useEffect, useCallback, useState } from 'react';

import { RdbUser, RoomMembershipApi, User, LocalCheckin } from '@/utils/PowerchatClient';
import { useUserCtxAbsolutely, useRoomCtxAbsolutely } from '@/utils/ctxs';
import { useLazyItems } from '@/utils/customHooks/useItems';

export type RoomMemberItem = {
    id: string;
    user: User;
    rdbUser: RdbUser | undefined;
    membershipApi: RoomMembershipApi;
};

export type LastCheckin = LocalCheckin;

export const useRoomMembers = () => {
    // HOOKS
    const { userClient } = useUserCtxAbsolutely();
    const {
        current: { currentRoomApi },
    } = useRoomCtxAbsolutely();
    const { ids, getItem, getItemAbsolutely, updateItem, initialize } = useLazyItems<RoomMemberItem>(undefined);
    const [lastCheckin, setLastCheckin] = useState<LastCheckin | undefined>(
        new LocalCheckin({
            createdAt: new Date(),
            updatedAt: undefined,
            userId: userClient.id,
            roomId: currentRoomApi.id,
            leftAt: undefined,
        })
    );
    const [mountedAt, setMountedAt] = useState(new Date());

    // CALLBACK
    const fetchMembers = useCallback(async () => {
        initialize(undefined);
        const membershipWithUsers = await currentRoomApi.getRoomMembershipApiWithUsersForRoom();
        initialize(
            membershipWithUsers.map(({ membershipApi, user }) => ({
                id: user.id,
                user,
                rdbUser: undefined,
                membershipApi,
            }))
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentRoomApi.id]);
    const initListen = useCallback(() => {
        if (ids !== undefined) {
            const { unsubscribeListenToHotUsersForRoom } = currentRoomApi.listenToHotUsersForRoom({
                onAdded: ({ newUser }) => {
                    updateItem({
                        id: newUser.id,
                        updator: (prevItem) => ({
                            ...prevItem,
                            rdbUser: newUser,
                        }),
                    });
                    console.log({ newUser });
                    if (newUser.id !== userClient.id && mountedAt < new Date(newUser.updatedAt)) {
                        // 自分のcheckinはタイミングがシビアで安定しないので、マニュアルで表示する
                        setLastCheckin(
                            new LocalCheckin({
                                createdAt: new Date(),
                                updatedAt: undefined,
                                userId: newUser.id,
                                roomId: currentRoomApi.id,
                                leftAt: undefined,
                            })
                        );
                    }
                },
                onRemoved: ({ removedUser }) => {
                    updateItem({
                        id: removedUser.id,
                        updator: (prevItem) => ({
                            ...prevItem,
                            rdbUser: removedUser,
                        }),
                    });
                    if (removedUser.id !== userClient.id) {
                        // 自分のcheckinはタイミングがシビアで安定しないので、マニュアルで表示する
                        setLastCheckin(
                            new LocalCheckin({
                                createdAt: new Date(),
                                updatedAt: new Date(),
                                userId: removedUser.id,
                                roomId: currentRoomApi.id,
                                leftAt: new Date(),
                            })
                        );
                    }
                },
            });
            return unsubscribeListenToHotUsersForRoom;
        }
        return undefined;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ids !== undefined]);

    // USEEFFECT
    useEffect(() => {
        setMountedAt(new Date());
        // setIsInitialized(false);
        setLastCheckin(undefined);
        fetchMembers();
    }, [fetchMembers]);
    useEffect(() => {
        const unsubscribe = initListen();
        return unsubscribe;
    }, [initListen]);

    return {
        userIds: ids,
        getRoomMemberItem: getItem,
        getRoomMemberItemAbsolutely: getItemAbsolutely,
        lastCheckin,
    };
};
