import { GqlServiceHealth } from '@/utils/PowerchatClient/graphql/generated/graphqlClient';
import { ServiceHealthCode } from '@/utils/PowerchatClient/models/enums';

export type ServiceHealthType = {
    code: ServiceHealthCode;
    message: string | undefined;
    url: string | undefined;
};

export class ServiceHealth implements ServiceHealthType {
    code: ServiceHealthCode;

    message: string | undefined;

    url: string | undefined;

    constructor({ code, message, url }: ServiceHealthType) {
        this.code = code;
        this.message = message;
        this.url = url;
    }
}

export const getServiceHealthFromGql = ({ code, message, url }: GqlServiceHealth) => {
    return new ServiceHealth({
        code,
        message: message || undefined,
        url: url || undefined,
    });
};
