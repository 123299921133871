import { MouseEvent, MouseEventHandler, useState } from 'react';

import { useHealthCtx, useMutationsCtx, AddMutationInput } from '@/utils/ctxs';

export const useMutationItem = <T extends HTMLElement>({
    newMutation,
    onClick,
}: {
    newMutation: AddMutationInput;
    onClick?: (event: MouseEvent<T>) => void;
}) => {
    // HOOKS
    const { health } = useHealthCtx();
    const { slot1, slot2, slot3, slot4, addMutation } = useMutationsCtx();
    const [currentSlotNumber, setCurrentSlotNumber] = useState<1 | 2 | 3 | 4 | undefined>(undefined);

    // DATA
    const isOccupied = !!(slot1 && slot2 && slot3 && slot4);
    const isAvailable = !isOccupied && health?.code === 'ok';

    // HANDLER
    const getCurrentSlot = (n?: 1 | 2 | 3 | 4) => {
        if (n === 1) {
            return slot1;
        }
        if (n === 2) {
            return slot2;
        }
        if (n === 3) {
            return slot3;
        }
        if (n === 4) {
            return slot4;
        }
        return undefined;
    };
    const handleClick: MouseEventHandler<T> = (event) => {
        onClick && onClick(event);
        const currentSlot = getCurrentSlot(currentSlotNumber);
        if (currentSlot) {
            currentSlot.retry();
        } else if (isAvailable) {
            const newSlotNumber = addMutation(newMutation);
            setCurrentSlotNumber(newSlotNumber);
        }
    };

    return {
        handleClick,
        isAvailable,
        isOccupied,
        currentSlot: getCurrentSlot(currentSlotNumber),
    };
};
