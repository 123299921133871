import { GqlRoomMembership } from '@/utils/PowerchatClient/graphql/generated/graphqlClient';
import { isoToDate, isoToDateOptionally } from '@/utils/PowerchatClient/graphql/customScalars';
import { RoomMemberRole } from '@/utils/PowerchatClient/models/enums';

export type RoomMembershipType = {
    id: string;
    createdAt: Date;
    updatedAt: Date | undefined;
    serialNumber: number;
    userId: string;
    roomId: string;
    role: RoomMemberRole;
    uniqueName: string;
};

export class RoomMembership implements RoomMembershipType {
    readonly id: string;

    readonly createdAt: Date;

    updatedAt: Date | undefined;

    readonly serialNumber: number;

    readonly userId: string;

    readonly roomId: string;

    role: RoomMemberRole;

    uniqueName: string;

    constructor({ id, createdAt, updatedAt, serialNumber, userId, roomId, role, uniqueName }: RoomMembershipType) {
        this.id = id;
        this.createdAt = createdAt;
        this.updatedAt = updatedAt;
        this.serialNumber = serialNumber;
        this.userId = userId;
        this.roomId = roomId;
        this.role = role;
        this.uniqueName = uniqueName;
    }

    async _updateUniqueName({ uniqueName, updatedAt }: { uniqueName: string; updatedAt: Date }) {
        this.uniqueName = uniqueName;
        this.updatedAt = updatedAt;
    }

    async _updateRole({ role, updatedAt }: { role: RoomMemberRole; updatedAt: Date }) {
        this.role = role;
        this.updatedAt = updatedAt;
    }
}

export const getRoomMembershipFromGql = ({
    id,
    createdAt,
    updatedAt,
    serialNumber,
    userId,
    roomId,
    role,
    uniqueName,
}: GqlRoomMembership) => {
    return new RoomMembership({
        id,
        createdAt: isoToDate(createdAt),
        updatedAt: isoToDateOptionally(updatedAt),
        serialNumber,
        userId,
        roomId,
        role,
        uniqueName,
    });
};
