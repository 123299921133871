import { ref, set } from 'firebase/database';
import { collection, doc } from 'firebase/firestore';
import { getFirebaseRealtimeDatabase, getFirebaseFirestore } from '@/utils/GoogleCloud/firebase';
import { Ring } from '@/utils/PowerchatClient';
import { AddRdbLastRingInput, getRdbLastRingInput } from '@/utils/PowerchatClient/RealtimeDatabase/RdbModels';

export const postRdbRing = ({ roomId, newRing }: { roomId: string; newRing: AddRdbLastRingInput }) => {
    const firestoreDb = getFirebaseFirestore();
    const { id } = doc(collection(firestoreDb, `Room/${roomId}/Ring`));
    const realtimeDb = getFirebaseRealtimeDatabase();
    const lastRingRef = ref(realtimeDb, `lastRings/${roomId}`);
    set(lastRingRef, {
        action: 'add',
        ...getRdbLastRingInput({
            ...newRing,
            id,
            createdAt: 'serverTimestamp',
        }),
    });
    return {
        newRing: new Ring({
            id,
            createdAt: new Date(),
            serialNumber: undefined,
            roomId,
            fromUserId: newRing.fromUserId,
            toUserId: newRing.toUserId,
        }),
    };
};
