import { getTextColorForBackgroundColor } from '@/utils/utilFunctions/getTextColorForBackgroundColor';

const colors = ['#FB8585', '#30A1E1', '#05D41A', '#D4CC05', '#05D496'];

export const generateColorForText = ({
    text,
}: {
    text: string;
}): {
    color: string;
    textColor: string;
} => {
    const parsedNumber = parseInt(text, 36);
    const index = (() => {
        if (parsedNumber <= colors.length) {
            return parsedNumber - 1;
        }
        return (parsedNumber % colors.length) - 1;
    })();
    const color = colors[index] || '#FB8585';
    const textColor = getTextColorForBackgroundColor({ textColor: '#000', backgroundColor: color });
    return {
        color,
        textColor,
    };
};
