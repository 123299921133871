import { FC } from 'react';
import clsx from 'clsx';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import MuiTypography from '@material-ui/core/Typography';

import { MessageApi } from '@/utils/PowerchatClient';
import { MutationButton } from '@/components/0_atom/MutationButton';
import { usePastTimeline } from '@/components/3_template/ConsoleTemplate/RoomTemplate/usePastTimeline';
import { MessageCard } from '@/components/3_template/ConsoleTemplate/RoomTemplate/MessageCard';
import { CheckinCard } from '@/components/3_template/ConsoleTemplate/RoomTemplate/CheckinCard';

const useStyles = makeStyles((theme) => ({
    root: {},
}));

export const PastTimelineView: FC<{
    className?: string;
    setReplyingToMessageApi: (replyingToMessageApi?: MessageApi) => void;
}> = ({ className, setReplyingToMessageApi }) => {
    // STYLE
    const c = useStyles(useTheme());

    // HOOKS
    const { pastTimelineIds, getPastTimelineItem, getPastTimelineItemAbsolutely, fetchPastTimeline, isAllFetched } =
        usePastTimeline();

    return (
        <div className={clsx(c.root, className)}>
            {!isAllFetched && (
                <MutationButton
                    mutation={{
                        description: 'fetch messages',
                        run: async () => {
                            await fetchPastTimeline();
                            return 'success';
                        },
                    }}
                >
                    {'fetch messages'}
                </MutationButton>
            )}
            {isAllFetched && <MuiTypography>{'All messages read'}</MuiTypography>}
            <div>
                {pastTimelineIds &&
                    pastTimelineIds.map((timelineId) => {
                        const { messageWithChildren, checkin, checkout } = getPastTimelineItemAbsolutely(timelineId);
                        console.log({ messageWithChildren });
                        if (messageWithChildren) {
                            return (
                                <MessageCard
                                    key={timelineId}
                                    messageApi={messageWithChildren.messageApi}
                                    initialReactionApis={messageWithChildren.reactionApis}
                                    isLocal={false}
                                    setReplyingToMessageApi={setReplyingToMessageApi}
                                    getMessageApi={(id) => getPastTimelineItem(id)?.messageWithChildren?.messageApi}
                                />
                            );
                        }
                        if (checkin) {
                            return (
                                <CheckinCard
                                    key={timelineId}
                                    checkinData={checkin}
                                    isLocal={false}
                                    isDisplayAsIn={true}
                                />
                            );
                        }
                        if (checkout) {
                            return (
                                <CheckinCard
                                    key={timelineId}
                                    checkinData={checkout}
                                    isLocal={false}
                                    isDisplayAsIn={false}
                                />
                            );
                        }
                        return <></>;
                    })}
            </div>
        </div>
    );
};
PastTimelineView.displayName = 'RoomTemplate/PastTimelineView';
