import { ref, set } from 'firebase/database';
import { getFirebaseRealtimeDatabase } from '@/utils/GoogleCloud/firebase';
import { getRdbLastMessageInput, ModifyRdbLastMessageInput } from '@/utils/PowerchatClient/RealtimeDatabase/RdbModels';

export const modifyRdbMessage = ({ roomId, message }: { roomId: string; message: ModifyRdbLastMessageInput }) => {
    const db = getFirebaseRealtimeDatabase();
    const lastMessageRef = ref(db, `lastMessages/${roomId}`);
    set(lastMessageRef, {
        action: 'modify',
        ...getRdbLastMessageInput({
            ...message,
            updatedAt: 'serverTimestamp',
            addNotification: undefined,
        }),
    });
};
