import { onMessage } from 'firebase/messaging';
import { getFirebaseCloudMessaging } from '@/utils/GoogleCloud/firebase';
import { PowerchatUserClient } from '@/utils/PowerchatClient';
import { MultilingualText } from '@/utils/customHooks';

export const startListeningToFCM = ({
    userClient,
    txt,
}: {
    userClient: PowerchatUserClient;
    txt: (input: MultilingualText) => string;
}) => {
    const { messaging } = getFirebaseCloudMessaging();
    onMessage(messaging, ({ data }) => {
        const { kind, roomId, roomName, userId, userUniqueName } = (() => {
            if (data) {
                return {
                    kind: data.kind || '',
                    roomId: data.roomId || '',
                    roomName: data.roomName || '',
                    userId: data.userId || '',
                    userUniqueName: data.userUniqueName || '',
                };
            }
            return {
                kind: '',
                roomId: '',
                roomName: '',
                userId: '',
                userUniqueName: '',
            };
        })();
        // console.log('Message received. ', data);
        if (userId !== userClient.id) {
            // eslint-disable-next-line no-new
            new Notification(roomName, {
                body: txt({
                    en: `${userUniqueName} ${kind}`,
                    ja: `${userUniqueName} ${kind}`,
                }),
                icon: '/logo192/png',
            });
        }
    });
    // navigator.serviceWorker.addEventListener('message', (m) => {
    //     console.log('Got sw message:', m);
    //     // eslint-disable-next-line no-new
    //     new Notification('こんにちは！');
    // });
};
