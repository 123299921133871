import { FC, forwardRef, ComponentProps, ReactElement, useEffect } from 'react';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import { AddMutationInput } from '@/utils/ctxs';
import { useMutationItem } from '@/utils/customHooks';
import { getMutationStateIcon, getActionState } from '@/components/0_atom/MutationStateIcon';

const useStyles = makeStyles((theme) => ({
    root: {},
}));

const MutationMenuItem: FC<
    ComponentProps<typeof MenuItem> & {
        newMutation: AddMutationInput;
        setIcon?: (icon?: ReactElement) => void;
    }
> = forwardRef(({ newMutation, setIcon, onClick, ...otherProps }, ref) => {
    // STYLE
    const c = useStyles(useTheme());

    // HOOKS
    const { handleClick, isAvailable, currentSlot } = useMutationItem({ newMutation, onClick });

    // USEEFFECT
    useEffect(() => {
        const mutationIcon = getMutationStateIcon({ actionState: getActionState(currentSlot) });
        if (mutationIcon) {
            setIcon && setIcon(mutationIcon);
        }
    }, [currentSlot, setIcon]);

    return (
        <MenuItem
            ref={ref}
            {...otherProps}
            button
            disabled={
                otherProps.disabled ||
                !isAvailable ||
                currentSlot?.status === 'fetching' ||
                currentSlot?.status === 'canceled' ||
                currentSlot?.status === 'failed' ||
                currentSlot?.status === 'fetchError'
            }
            onClick={handleClick}
        />
    );
});
MutationMenuItem.displayName = 'MutationMenuItem';
export default MutationMenuItem;
