import { FC } from 'react';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import MuiTypography from '@material-ui/core/Typography';
import { useUserCtx } from '@/utils/ctxs';
import { useLanguage } from '@/utils/customHooks';
import { Button } from '@/components/0_atom/Button';

const useStyles = makeStyles((theme) => ({
    root: {},
}));

export const BrowserNotSupportedDisplay: FC = () => {
    // STYLE
    const c = useStyles(useTheme());

    // HOOKS
    const { txt } = useLanguage();

    return (
        <div className={c.root}>
            <MuiTypography variant={'h1'}>
                {txt({
                    en: 'Power is not available in your browser! Please use Google Chrome.',
                    ja: 'ご利用のブラウザではPowerをご利用いただけません！Google Chromeを利用してください。',
                })}
            </MuiTypography>
        </div>
    );
};
BrowserNotSupportedDisplay.displayName = 'BrowserNotSupportedDisplay';
