import { useState, useEffect, useCallback } from 'react';

import { getAlphanumericString, initPowerchatFirebaseUserClient } from '@/utils/PowerchatClient';
import { useUserCtx } from '@/utils/ctxs';
import { useLanguage } from '@/utils/customHooks';
import { useDebounce } from '@/components/3_template/PreConsoleTemplate/SignupDisplay/useDebounce';

export const useUniqueName = () => {
    // HOOKS
    const { firebaseUser, fcmToken } = useUserCtx();
    const [isFetching, setIsFetching] = useState(false);
    const [uniqueName, setUniqueName] = useState<string | undefined>(undefined);
    const [isUnique, setIsUnique] = useState<boolean | undefined>(undefined);
    const [isValidFormat, setIsValidFormat] = useState<boolean | undefined>(undefined);
    const { language } = useLanguage();

    // DEBOUNCE
    const callback = useCallback(
        async ({ uniqueName }) => {
            if (firebaseUser?.idToken && firebaseUser?.uid && fcmToken && language) {
                const firebaseUserClient = initPowerchatFirebaseUserClient({
                    firebaseUserIdToken: firebaseUser.idToken,
                    fcmToken,
                    firebaseUid: firebaseUser.uid,
                    language,
                });
                setIsFetching(true);
                const { isAvailable } = await firebaseUserClient.getIsUserUniqueNameAvailable({
                    uniqueName,
                });
                setIsFetching(false);
                setIsUnique(isAvailable);
            }
        },
        [firebaseUser?.idToken, firebaseUser?.uid, fcmToken, language]
    );
    const [fetchIsUserUniqueNameAvailable, cancelTimer] = useDebounce<{
        uniqueName: string;
    }>({
        callback,
        offset: 500,
    });

    // CALLBACK
    const validateUniqueName = useCallback(async () => {
        if (!uniqueName) {
            cancelTimer();
            setIsValidFormat(undefined);
            setIsUnique(undefined);
            return;
        }
        const validationResult = getAlphanumericString(uniqueName);
        if (!validationResult) {
            cancelTimer();
            setIsValidFormat(false);
            setIsUnique(undefined);
            return;
        }
        setIsValidFormat(true);
        setIsUnique(undefined);
        fetchIsUserUniqueNameAvailable({ uniqueName });
    }, [uniqueName, fetchIsUserUniqueNameAvailable, cancelTimer]);

    // USEEFFECT
    useEffect(() => {
        validateUniqueName();
    }, [validateUniqueName]);

    return {
        isFetching,
        uniqueName,
        setUniqueName,
        isUnique,
        isValidFormat,
    };
};
