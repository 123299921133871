import { useEffect, useCallback, useState } from 'react';

import { useUserCtxAbsolutely } from '@/utils/ctxs';

export const useRealtimeDatabase = ({ initialRoomId }: { initialRoomId: string | undefined }) => {
    // HOOKS
    const { userClient } = useUserCtxAbsolutely();
    const [isOnline, setIsOnline] = useState<boolean | undefined>(undefined);

    // CALLBACK
    const initRealtimeDatabase = useCallback(() => {
        userClient.startConnectingRealtimeDatabase({
            initialRoomId: undefined,
            initialRoomName: undefined,
            onConnectionChanged: (newValue) => {
                setIsOnline(newValue.isOnline);
            },
        });
        setIsOnline(true);
    }, [userClient]);

    // USEEFFECT
    useEffect(() => {
        initRealtimeDatabase();
    }, [initRealtimeDatabase]);

    return {
        isRdbOnline: isOnline,
    };
};
