import { FC } from 'react';

const Svg: FC<{ className?: string }> = ({ className }) => {
    return (
        <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
            <path
                fill="currentColor"
                d="M208 96C93.1 96 0 189.1 0 304s93.1 208 208 208 208-93.1 208-208S322.9 96 208 96zm0 368c-88.2 0-160-71.8-160-160s71.8-160 160-160 160 71.8 160 160-71.8 160-160 160zm61.8-124.2c-30.6 35.8-92.9 35.8-123.5 0-8.7-10.1-23.8-11.2-33.8-2.7-10.1 8.6-11.2 23.8-2.7 33.8 24.4 28.6 60.2 45 98.2 45s73.8-16.4 98.2-45c8.6-10.1 7.4-25.2-2.7-33.8-10-8.5-25.1-7.4-33.7 2.7zM144 288c17.7 0 32-14.3 32-32s-14.3-32-32-32-32 14.3-32 32 14.3 32 32 32zm128 0c17.7 0 32-14.3 32-32s-14.3-32-32-32-32 14.3-32 32 14.3 32 32 32zM624 88h-72V16c0-8.8-7.2-16-16-16h-16c-8.8 0-16 7.2-16 16v72h-72c-8.8 0-16 7.2-16 16v16c0 8.8 7.2 16 16 16h72v72c0 8.8 7.2 16 16 16h16c8.8 0 16-7.2 16-16v-72h72c8.8 0 16-7.2 16-16v-16c0-8.8-7.2-16-16-16z"
            />
        </svg>
    );
};
Svg.displayName = 'svg/fa-smile-plus-solid';
export default Svg;
