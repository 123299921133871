export const getAlphanumericString = (input: string) => {
    const isValid = !!input.match(/^[A-Za-z0-9]*$/);
    if (!isValid) {
        return undefined;
    }
    return {
        upper: input.toUpperCase(),
        lower: input.toLocaleLowerCase(),
        raw: input,
    };
};
