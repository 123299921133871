import { FC, ReactNode, useState, useCallback, useEffect } from 'react';

import { RoomApi, RoomMembershipApi } from '@/utils/PowerchatClient';
import { RoomsCtx, RoomCtx, useUserCtxAbsolutely, useRdbUserCtx } from '@/utils/ctxs';

export const RoomsProvider: FC<{
    children: ReactNode;
}> = ({ children }) => {
    // HOOKS
    const { userClient } = useUserCtxAbsolutely();
    const [items, setItems] = useState<
        | {
              roomApi: RoomApi;
              membershipApi: RoomMembershipApi;
          }[]
        | undefined
    >(undefined);
    const [current, setCurrent] = useState<
        | {
              currentRoomApi: RoomApi;
              currentMembershipApi: RoomMembershipApi;
          }
        | undefined
    >(undefined);
    const { isOnline: isRdbUserOnline } = useRdbUserCtx();

    // CALLBACKS
    const fetchRooms = useCallback(async () => {
        const fetchedItems = await userClient.getRoomWithMembershipsForUser();
        setItems(fetchedItems);
    }, [userClient]);

    // USEEFFECT
    useEffect(() => {
        fetchRooms();
    }, [fetchRooms]);
    useEffect(() => {
        if (isRdbUserOnline === false) {
            setCurrent(undefined);
        }
    }, [isRdbUserOnline]);

    return (
        <RoomsCtx.Provider
            value={{
                roomWithMemberships: items,
                refetchRooms: fetchRooms,
            }}
        >
            <RoomCtx.Provider
                value={{
                    current,
                    setRoomId: ({ roomId }) => {
                        const targetItem = items?.find(({ roomApi }) => roomApi.id === roomId);
                        if (!targetItem) {
                            throw new Error('RoomsProvider: missing roomId.');
                        }
                        userClient.updateCurrentRoomId({
                            currentRoomId: roomId,
                            currentRoomName: targetItem.roomApi.name,
                        });
                        setCurrent({
                            currentRoomApi: targetItem.roomApi,
                            currentMembershipApi: targetItem.membershipApi,
                        });
                    },
                }}
            >
                {children}
            </RoomCtx.Provider>
        </RoomsCtx.Provider>
    );
};
RoomsProvider.displayName = 'RoomsProvider';
