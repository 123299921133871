import { createContext, useContext } from 'react';

import { PowerchatUserClient } from '@/utils/PowerchatClient';

type UserCtxType = {
    userClient?: PowerchatUserClient;
    isFetching: boolean;
    reInitialize: () => Promise<void>;
    signOut: () => void;
    firebaseUser?: {
        uid: string;
        email: string;
        idToken: string;
    };
    isFirebaseError?: boolean;
    fcmToken?: string;
    isNeedToGrantNotification?: boolean;
    retryGetFcmToken: () => Promise<void>;
    isBrowserNotSupported?: boolean;
};
export const UserCtx = createContext<UserCtxType>({
    isFetching: true,
    reInitialize: () =>
        new Promise((r) => {
            r();
        }),
    signOut: () => undefined,
    retryGetFcmToken: () =>
        new Promise((r) => {
            r();
        }),
});
export const useUserCtx = () => {
    return useContext(UserCtx);
};
export const useUserCtxAbsolutely = () => {
    const { userClient, firebaseUser, fcmToken, ...otherProps } = useUserCtx();
    if (!userClient) {
        throw new Error('useUserCtxAbsolutely: !userClient.');
    }
    if (!firebaseUser) {
        throw new Error('useUserCtxAbsolutely: !firebaseUser.');
    }
    if (!fcmToken) {
        throw new Error('useUserCtxAbsolutely: !fcmToken.');
    }
    return {
        userClient,
        firebaseUser,
        fcmToken,
        ...otherProps,
    };
};
