import { FC, useState } from 'react';
import clsx from 'clsx';

import { useTheme, makeStyles } from '@material-ui/core/styles';

import SvgStrikethrough from '@/svgs/fa-strikethrough-solid';

import { RoomApi, RoomMembershipApi, MessageApi, ReactionApi } from '@/utils/PowerchatClient';
import { useRoomCtx, useRoomsCtx, useRoomCtxAbsolutely, useUserCtxAbsolutely } from '@/utils/ctxs';
import { useLanguage } from '@/utils/customHooks';
import { Button } from '@/components/0_atom/Button';
import { IconButtonWithTextMenu } from '@/components/1_mol/ButtonWithMenu';
import { RoomItem } from '@/components/3_template/ConsoleTemplate/RoomsTemplate/RoomItem';
import { useReactions } from '@/components/3_template/ConsoleTemplate/RoomTemplate/useReactions';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: 6,
        color: theme.palette.error.main,
        borderRadius: '50%',
    },
    svg: {
        width: 12,
        height: 12,
    },
}));

export const CancelMessageButton: FC<{
    className?: string;
    messageApi: MessageApi;
}> = ({ className, messageApi }) => {
    // STYLE
    const c = useStyles(useTheme());

    // HOOKS
    const { userClient } = useUserCtxAbsolutely();
    const { txt } = useLanguage();

    // HANDLER
    const handleClick = () => {
        messageApi.cancel();
    };

    return (
        <IconButtonWithTextMenu
            className={clsx(c.root, className)}
            items={[
                {
                    text: txt({ en: 'Cancel this message', ja: 'このメッセージを取り消し' }),
                    onClick: handleClick,
                    isWarn: true,
                },
            ]}
            menuEmptyPlaceholder={'never'}
        >
            <SvgStrikethrough className={c.svg} />
        </IconButtonWithTextMenu>
    );
};
CancelMessageButton.displayName = 'RoomTemplate/CancelMessageButton';
