import { useEffect, useCallback } from 'react';

import { listenToHotUsersForRoom, RdbUser } from '@/utils/PowerchatClient';
import { useLazyItems } from '@/utils/customHooks/useItems';

export const useHotMembers = ({ roomId }: { roomId: string }) => {
    // HOOKS
    const { ids, getItem, addItem, removeItem } = useLazyItems<RdbUser & { id: string }>(undefined);

    // CALLBACK
    const initListen = useCallback(() => {
        listenToHotUsersForRoom({
            roomId,
            onAdded: ({ newUser }) => {
                console.log({ newUser });
                addItem({
                    newItem: newUser,
                });
            },
            onRemoved: ({ removedUser }) => {
                console.log({ removedUser });
                removeItem(removedUser.id);
            },
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [roomId]);

    // USEEFFECT
    useEffect(() => {
        initListen();
    }, [initListen]);

    return {
        hotUserIds: ids,
        getHotUserItem: getItem, // removeを使うので、absolutelyは使わない
    };
};
