import { FC, useState, useCallback } from 'react';
import clsx from 'clsx';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import MuiIconButton from '@material-ui/core/IconButton';

import SvgSend from '@/svgs/fa-paper-plane-solid';
import SvgPlus from '@/svgs/fa-plus-solid';

import { MessageApi } from '@/utils/PowerchatClient';
import { useRoomCtxAbsolutely, useUserCtxAbsolutely, useRoomMembersCtx } from '@/utils/ctxs';
import { useLanguage } from '@/utils/customHooks';
import { TextInput } from '@/components/0_atom/Input';
import { UserIconWithName } from '@/components/2_org/UserIconWithName';
import { MessageBodyViewer } from '@/components/3_template/ConsoleTemplate/RoomTemplate/MessageBodyViewer';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 0,
        flexShrink: 1,
        marginTop: 10,
        position: 'relative',
    },
    input: {
        background: '#fff',
    },
    submitButton: {
        position: 'absolute',
        bottom: 0,
        right: 0,
        '& svg': {
            width: 14,
            height: 14,
        },
    },
    replyingToMessageApi: {
        background: '#fff',
        borderRadius: 12,
        boxShadow: '0 0 4px 0 rgba(0,0,0,.05)',
        padding: '12px 18px',
        position: 'relative',
        marginBottom: 6,
    },
    replyingToUserRow: {
        display: 'flex',
        alignItems: 'center',
    },
    replyingToUser: {
        fontSize: 10,
    },
    replyingToUserIcon: {
        width: 16,
        height: 16,
        marginRight: 6,
    },
    replyingToBody: {
        fontSize: 10,
        marginTop: 8,
    },
    replyingToCloseButton: {
        position: 'absolute',
        top: 4,
        right: 4,
        padding: 8,
        '& svg': {
            width: 12,
            height: 12,
            transform: 'rotate(45deg)',
        },
    },
    replyingToLabel: {
        fontSize: 12,
        marginRight: 8,
    },
}));

export const AddMessageInput: FC<{
    className?: string;
    replyingToMessageApi?: MessageApi;
    setReplyingToMessageApi: (replyingToMessageApi?: MessageApi) => void;
}> = ({ className, replyingToMessageApi, setReplyingToMessageApi }) => {
    // STYLE
    const c = useStyles(useTheme());

    // HOOKS
    const [text, setText] = useState<string | undefined>(undefined);
    const {
        current: { currentRoomApi },
    } = useRoomCtxAbsolutely();
    const { txt } = useLanguage();
    const { userClient } = useUserCtxAbsolutely();
    const { getRoomMemberItem } = useRoomMembersCtx();

    // CALLBACK
    const runCreateMessage = useCallback(async () => {
        // まずはすぐにInputを空にし、操作性を軽くする
        // later: messages に status フィールドを用意し、送信中やエラーなどをもつ
        const body = text;
        setText(undefined);
        if (body) {
            setReplyingToMessageApi(undefined);
            const { messageApi } = await currentRoomApi.createMessage({
                body,
                replyTo: (() => {
                    if (replyingToMessageApi) {
                        return {
                            messageId: replyingToMessageApi.id,
                            user: {
                                id: replyingToMessageApi.userId,
                                uniqueName:
                                    getRoomMemberItem(replyingToMessageApi.userId)?.user.uniqueName ||
                                    txt({
                                        en: 'Missing User',
                                        ja: '存在しないユーザー',
                                    }),
                            },
                        };
                    }
                    return undefined;
                })(),
                mentions: [],
            });
            console.log({ messageApi });
            return 'success' as const;
        }
        throw new Error('never');
    }, [text, currentRoomApi, replyingToMessageApi, setReplyingToMessageApi, txt, getRoomMemberItem]);

    return (
        <div className={clsx(c.root, className)}>
            {replyingToMessageApi && (
                <div className={c.replyingToMessageApi}>
                    <div className={c.replyingToUserRow}>
                        <span className={c.replyingToLabel}>
                            {txt({
                                en: 'Replying to',
                                ja: '返信先:',
                            })}
                        </span>

                        {(() => {
                            const member = getRoomMemberItem(replyingToMessageApi.userId);
                            if (member) {
                                return (
                                    <UserIconWithName
                                        userName={member.user.uniqueName}
                                        isHot={member.rdbUser?.currentRoomId === currentRoomApi.id}
                                        className={c.replyingToUser}
                                        iconClassName={c.replyingToUserIcon}
                                    />
                                );
                            }
                            return <></>;
                        })()}
                        <MuiIconButton
                            className={c.replyingToCloseButton}
                            onClick={() => setReplyingToMessageApi(undefined)}
                        >
                            <SvgPlus />
                        </MuiIconButton>
                    </div>
                    <MessageBodyViewer text={replyingToMessageApi.body} className={c.replyingToBody} />
                </div>
            )}
            <TextInput
                fullWidth
                className={c.input}
                value={text}
                setValue={setText}
                placeholder={txt({
                    en: replyingToMessageApi ? 'Reply to above message' : `Message to ${currentRoomApi.name}`,
                    ja: replyingToMessageApi ? '上のメッセージに返信' : `To ${currentRoomApi.name}`,
                })}
                multiline
            />
            <MuiIconButton onClick={runCreateMessage} disabled={!text} className={c.submitButton}>
                <SvgSend />
            </MuiIconButton>
        </div>
    );
};
AddMessageInput.displayName = 'RoomTemplate/AddMessageInput';
