import { GraphQLSdk } from '@/utils/PowerchatClient/graphql/graphqlSdk';
import { dateToIsoOptionally, isoToDate } from '@/utils/PowerchatClient/graphql/customScalars';
import {
    postRdbMessage,
    postRdbRing,
    listenToMessages,
    listenToReactions,
    listenToRings,
    listenToHotUsersForRoom,
    RdbUser,
    ReplyToUser,
    MentionToUser,
} from '@/utils/PowerchatClient/RealtimeDatabase';
import { Room, RoomType } from '@/utils/PowerchatClient/models/Room/data/Room';
import { getRoomMembershipFromGql, RoomMembershipApi } from '@/utils/PowerchatClient/models/RoomMembership';
import { MessageApi, getMessageFromGql } from '@/utils/PowerchatClient/models/Message';
import { ReactionApi, getReactionFromGql } from '@/utils/PowerchatClient/models/Reaction';
import { Ring, getRingFromGql } from '@/utils/PowerchatClient/models/Ring';
import { User, getUserFromGql } from '@/utils/PowerchatClient/models/User';
import { getCheckinFromGql } from '@/utils/PowerchatClient/models/Checkin';
import { TimelineRow } from '@/utils/PowerchatClient/models/TimelineRow';

type ReplyTo = {
    messageId: string;
    user: ReplyToUser;
};

type RoomApiType = {
    close: () => Promise<void>;
    getRoomMembershipApisForRoom: () => Promise<RoomMembershipApi[]>;
    getRoomMembershipApiWithUsersForRoom: () => Promise<
        {
            membershipApi: RoomMembershipApi;
            user: User;
        }[]
    >;
    getMessage: (input: { messageId: string }) => Promise<{
        messageApi: MessageApi;
    }>;
    getMessageWithChildren: (input: { messageId: string }) => Promise<{
        messageApi: MessageApi;
        reactionApis: ReactionApi[];
    }>;
    getMessageWithChildrens: (input: {
        maxAmount?: number;
        startAfterSerialNumber?: number;
        endBeforeSerialNumber?: number;
        startAfterCreatedAt?: Date;
        endBeforeCreatedAt?: Date;
        userId?: string;
    }) => Promise<
        {
            messageApi: MessageApi;
            reactionApis: ReactionApi[];
        }[]
    >;
    getRings: (input: {
        maxAmount?: number;
        startAfterSerialNumber?: number;
        endBeforeSerialNumber?: number;
        userId?: string;
    }) => Promise<Ring[]>;
    getTimeline: (input: { maxAmount?: number; startAfterAt?: Date; endBeforeAt?: Date }) => Promise<TimelineRow[]>;
    createMessage: (input: { body: string; replyTo: ReplyTo | undefined; mentions: MentionToUser[] }) => Promise<{
        messageApi: MessageApi;
    }>;
    createRing: (input: { toUserId: string }) => Promise<{
        ring: Ring;
    }>;
    createRoomMembership: (input: { newMemberUserCode: string; uniqueName: string }) => Promise<{
        roomMembershipApi: RoomMembershipApi;
    }>;
    // Listeners
    listenToMessages: (input: {
        onAdded: (input: { newMessageApi: MessageApi }) => void;
        onModified: (input: { modifiedMessageApi: MessageApi }) => void;
    }) => {
        unsubscribeListenToMessages: () => void;
    };
    listenToReactions: (input: {
        onAdded: (input: { newReactionApi: ReactionApi }) => void;
        onRemoved: (input: { removedReactionApi: ReactionApi }) => void;
    }) => {
        unsubscribeListenToReactions: () => void;
    };
    listenToRings: (input: { onAdded: (input: { newRing: Ring }) => void }) => {
        unsubscribeListenToRings: () => void;
    };
    listenToHotUsersForRoom: (input: {
        onAdded: (input: { newUser: RdbUser }) => void;
        onRemoved: (input: { removedUser: RdbUser }) => void;
    }) => {
        unsubscribeListenToHotUsersForRoom: () => void;
    };
};

type ConstructorInput = RoomType & {
    graphqlSdk: GraphQLSdk;
    currentFcmToken: string;
    clientUser: User;
};

export class RoomApi extends Room implements RoomApiType {
    protected _graphqlSdk: GraphQLSdk;

    protected _currentFcmToken: string;

    protected _clientUser: User;

    constructor(input: ConstructorInput) {
        super(input);
        this._graphqlSdk = input.graphqlSdk;
        this._currentFcmToken = input.currentFcmToken;
        this._clientUser = input.clientUser;
    }

    async close() {
        const {
            closeRoom: { closedRoom },
        } = await this._graphqlSdk.closeRoom({
            input: {
                roomId: this.id,
            },
        });
        closedRoom.closedAt &&
            closedRoom.updatedAt &&
            this._updateClosedAt({
                closedAt: isoToDate(closedRoom.closedAt),
                updatedAt: isoToDate(closedRoom.updatedAt),
            });
    }

    async getRoomMembershipApisForRoom() {
        const {
            getRoomMembershipsForRoom: { roomMemberships },
        } = await this._graphqlSdk.getRoomMembershipsForRoom({
            input: {
                roomId: this.id,
            },
        });
        return roomMemberships.map(
            (roomMembership) =>
                new RoomMembershipApi({
                    graphqlSdk: this._graphqlSdk,
                    currentFcmToken: this._currentFcmToken,
                    clientUser: this._clientUser,
                    room: this,
                    ...getRoomMembershipFromGql(roomMembership),
                })
        );
    }

    async getRoomMembershipApiWithUsersForRoom() {
        const {
            getRoomMembershipWithUsersForRoom: { roomMembershipWithUsers },
        } = await this._graphqlSdk.getRoomMembershipWithUsersForRoom({
            input: {
                roomId: this.id,
            },
        });
        return roomMembershipWithUsers.map(({ membership, user }) => {
            return {
                membershipApi: new RoomMembershipApi({
                    graphqlSdk: this._graphqlSdk,
                    currentFcmToken: this._currentFcmToken,
                    clientUser: this._clientUser,
                    room: this,
                    ...getRoomMembershipFromGql(membership),
                }),
                user: getUserFromGql(user),
            };
        });
    }

    async getMessage({ messageId }: { messageId: string }) {
        const {
            getMessage: { message },
        } = await this._graphqlSdk.getMessage({
            input: {
                roomId: this.id,
                messageId,
            },
        });
        return {
            messageApi: new MessageApi({
                graphqlSdk: this._graphqlSdk,
                currentFcmToken: this._currentFcmToken,
                clientUser: this._clientUser,
                room: this,
                ...getMessageFromGql(message),
            }),
        };
    }

    async getMessageWithChildren({ messageId }: { messageId: string }) {
        const {
            getMessageWithChildren: {
                messageWithChildren: { message, reactions },
            },
        } = await this._graphqlSdk.getMessageWithChildren({
            input: {
                roomId: this.id,
                messageId,
            },
        });
        return {
            messageApi: new MessageApi({
                graphqlSdk: this._graphqlSdk,
                currentFcmToken: this._currentFcmToken,
                clientUser: this._clientUser,
                room: this,
                ...getMessageFromGql(message),
            }),
            reactionApis: reactions.map(
                (reaction) =>
                    new ReactionApi({
                        graphqlSdk: this._graphqlSdk,
                        currentFcmToken: this._currentFcmToken,
                        clientUser: this._clientUser,
                        room: this,
                        ...getReactionFromGql(reaction),
                    })
            ),
        };
    }

    async getMessageWithChildrens({
        maxAmount,
        startAfterSerialNumber,
        endBeforeSerialNumber,
        startAfterCreatedAt,
        endBeforeCreatedAt,
        userId,
    }: {
        maxAmount?: number;
        startAfterSerialNumber?: number;
        endBeforeSerialNumber?: number;
        startAfterCreatedAt?: Date;
        endBeforeCreatedAt?: Date;
        userId?: string;
    }) {
        const {
            getMessageWithChildrens: { messageWithChildrens },
        } = await this._graphqlSdk.getMessageWithChildrens({
            input: {
                roomId: this.id,
                filter: {
                    userId,
                },
                limitation: {
                    maxAmount,
                    startAfterSerialNumber,
                    endBeforeSerialNumber,
                    startAfterCreatedAt: dateToIsoOptionally(startAfterCreatedAt),
                    endBeforeCreatedAt: dateToIsoOptionally(endBeforeCreatedAt),
                },
            },
        });
        return messageWithChildrens.map(({ message, reactions }) => ({
            messageApi: new MessageApi({
                graphqlSdk: this._graphqlSdk,
                currentFcmToken: this._currentFcmToken,
                clientUser: this._clientUser,
                room: this,
                ...getMessageFromGql(message),
            }),
            reactionApis: reactions.map(
                (reaction) =>
                    new ReactionApi({
                        graphqlSdk: this._graphqlSdk,
                        currentFcmToken: this._currentFcmToken,
                        clientUser: this._clientUser,
                        room: this,
                        ...getReactionFromGql(reaction),
                    })
            ),
        }));
    }

    async getRings({
        maxAmount,
        startAfterSerialNumber,
        endBeforeSerialNumber,
        fromUserId,
        toUserId,
    }: {
        maxAmount?: number;
        startAfterSerialNumber?: number;
        endBeforeSerialNumber?: number;
        fromUserId?: string;
        toUserId?: string;
    }) {
        const {
            getRings: { rings },
        } = await this._graphqlSdk.getRings({
            input: {
                roomId: this.id,
                filter: {
                    fromUserId,
                    toUserId,
                },
                limitation: {
                    maxAmount,
                    startAfterSerialNumber,
                    endBeforeSerialNumber,
                },
            },
        });
        return rings.map((ring) => new Ring(getRingFromGql(ring)));
    }

    async getTimeline({
        maxAmount,
        startAfterAt,
        endBeforeAt,
        userId,
    }: {
        maxAmount?: number;
        startAfterAt?: Date;
        endBeforeAt?: Date;
        userId?: string;
    }) {
        const {
            getTimeline: { timelineRows },
        } = await this._graphqlSdk.getTimeline({
            input: {
                roomId: this.id,
                filter: {
                    userId,
                },
                limitation: {
                    maxAmount,
                    startAfterAt: dateToIsoOptionally(startAfterAt),
                    endBeforeAt: dateToIsoOptionally(endBeforeAt),
                },
            },
        });
        return timelineRows.map(({ messageWithChildren, ring, checkin, checkout }) => ({
            messageWithChildren: messageWithChildren
                ? {
                      messageApi: new MessageApi({
                          graphqlSdk: this._graphqlSdk,
                          currentFcmToken: this._currentFcmToken,
                          clientUser: this._clientUser,
                          room: this,
                          ...getMessageFromGql(messageWithChildren.message),
                      }),
                      reactionApis: messageWithChildren.reactions.map(
                          (reaction) =>
                              new ReactionApi({
                                  graphqlSdk: this._graphqlSdk,
                                  currentFcmToken: this._currentFcmToken,
                                  clientUser: this._clientUser,
                                  room: this,
                                  ...getReactionFromGql(reaction),
                              })
                      ),
                  }
                : undefined,
            ring: ring ? getRingFromGql(ring) : undefined,
            checkin: checkin ? getCheckinFromGql(checkin) : undefined,
            checkout: checkout ? getCheckinFromGql(checkout) : undefined,
        }));
    }

    async createMessage({
        body,
        replyTo,
        mentions,
    }: {
        body: string;
        replyTo: ReplyTo | undefined;
        mentions: MentionToUser[];
    }) {
        const {
            newMessage: { id: preparedId },
        } = postRdbMessage({
            roomId: this.id,
            newMessage: {
                userId: this._clientUser.id,
                body,
                addNotification: {
                    replyToUser: replyTo?.user,
                    mentions,
                    userName: this._clientUser.uniqueName,
                    roomName: this.name,
                },
            },
        });
        const {
            createMessage: { message },
        } = await this._graphqlSdk.createMessage({
            input: {
                roomId: this.id,
                preparedId,
                body,
                replyToMessageId: replyTo?.messageId,
                mentionToUserIds: mentions?.map(({ id }) => id) || [],
            },
        });
        return {
            messageApi: new MessageApi({
                graphqlSdk: this._graphqlSdk,
                currentFcmToken: this._currentFcmToken,
                clientUser: this._clientUser,
                room: this,
                ...getMessageFromGql(message),
            }),
        };
    }

    async createRing({ toUserId }: { toUserId: string }) {
        const {
            newRing: { id: preparedId },
        } = postRdbRing({
            roomId: this.id,
            newRing: {
                fromUserId: this._clientUser.id,
                toUserId,
                roomName: this.name,
                fromUserName: this._clientUser.uniqueName,
            },
        });
        const {
            createRing: { ring },
        } = await this._graphqlSdk.createRing({
            input: {
                preparedId,
                roomId: this.id,
                toUserId,
            },
        });
        return {
            ring: new Ring(getRingFromGql(ring)),
        };
    }

    async createRoomMembership({
        newMemberUserCode,
        uniqueName,
    }: {
        newMemberUserCode: string;
        uniqueName: string | undefined;
    }) {
        const {
            createRoomMembership: { membership },
        } = await this._graphqlSdk.createRoomMembership({
            input: {
                roomId: this.id,
                newMemberUserCode,
                uniqueName,
            },
        });
        return {
            roomMembershipApi: new RoomMembershipApi({
                graphqlSdk: this._graphqlSdk,
                currentFcmToken: this._currentFcmToken,
                clientUser: this._clientUser,
                room: this,
                ...getRoomMembershipFromGql(membership),
            }),
        };
    }

    // Listers
    listenToMessages({
        onAdded,
        onModified,
    }: {
        onAdded: (input: { newMessageApi: MessageApi }) => void;
        onModified: (input: { modifiedMessageApi: MessageApi }) => void;
    }) {
        return listenToMessages({
            roomId: this.id,
            onAdded: ({ newMessage }) => {
                onAdded({
                    newMessageApi: new MessageApi({
                        ...newMessage,
                        currentFcmToken: this._currentFcmToken,
                        clientUser: this._clientUser,
                        room: this,
                        graphqlSdk: this._graphqlSdk,
                    }),
                });
            },
            onModified: ({ modifiedMessage }) => {
                console.log({ modifiedMessage });
                onModified({
                    modifiedMessageApi: new MessageApi({
                        ...modifiedMessage,
                        currentFcmToken: this._currentFcmToken,
                        clientUser: this._clientUser,
                        room: this,
                        graphqlSdk: this._graphqlSdk,
                    }),
                });
            },
        });
    }

    listenToReactions({
        onAdded,
        onRemoved,
    }: {
        onAdded: (input: { newReactionApi: ReactionApi }) => void;
        onRemoved: (input: { removedReactionApi: ReactionApi }) => void;
    }) {
        return listenToReactions({
            roomId: this.id,
            onAdded: ({ newReaction }) => {
                onAdded({
                    newReactionApi: new ReactionApi({
                        ...newReaction,
                        currentFcmToken: this._currentFcmToken,
                        clientUser: this._clientUser,
                        room: this,
                        graphqlSdk: this._graphqlSdk,
                    }),
                });
            },
            onRemoved: ({ removedReaction }) => {
                console.log({ removedReaction });
                onRemoved({
                    removedReactionApi: new ReactionApi({
                        ...removedReaction,
                        currentFcmToken: this._currentFcmToken,
                        clientUser: this._clientUser,
                        room: this,
                        graphqlSdk: this._graphqlSdk,
                    }),
                });
            },
        });
    }

    listenToRings({ onAdded }: { onAdded: (input: { newRing: Ring }) => void }) {
        return listenToRings({
            roomId: this.id,
            onAdded,
        });
    }

    listenToHotUsersForRoom({
        onAdded,
        onRemoved,
    }: {
        onAdded: (input: { newUser: RdbUser }) => void;
        onRemoved: (input: { removedUser: RdbUser }) => void;
    }) {
        return listenToHotUsersForRoom({
            roomId: this.id,
            onAdded,
            onRemoved,
        });
    }
}
