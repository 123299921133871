import { useCallback } from 'react';

let timerId: NodeJS.Timeout | undefined;

export const useDebounce = <T>({ callback, offset }: { callback: (input: T) => void; offset: number }) => {
    // CALLBACK
    const cancelTimer = useCallback(() => {
        if (timerId) {
            clearTimeout(timerId); // 前回のタイマーを解除
        }
    }, []);
    const debounce = useCallback(
        (input: T) => {
            cancelTimer();
            const newTimerId = setTimeout(() => {
                // 新たにタイマーをセット
                callback(input);
            }, offset);
            timerId = newTimerId; // 今回のタイマーのタイマーIDをセット
        },
        [cancelTimer, callback, offset]
    );

    return [debounce, cancelTimer] as const;
};
