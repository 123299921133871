import { FC } from 'react';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import MuiListItem from '@material-ui/core/ListItem';
import MuiListItemIcon from '@material-ui/core/ListItemIcon';
import MuiListItemText from '@material-ui/core/ListItemText';
import MuiCircularProgress from '@material-ui/core/CircularProgress';
import { ProcessingMutation } from '@/utils/ctxs';
import { useLanguage } from '@/utils/customHooks';
import { Button } from '@/components/0_atom/Button';

import SvgCheck from '@/svgs/fa-check-light';
import SvgCloudUnavailable from '@/svgs/cloud-unavailable';
import SvgCloudCaution from '@/svgs/cloud-caution';

const useSlotStyles = makeStyles((theme) => ({
    root: {
        alignItems: 'flex-start',
        '&:last-child': {
            paddingBottom: 0,
        },
    },
    status: {
        width: 30,
        minWidth: 'auto',
        '& svg': {
            height: 16,
            width: 16,
        },
    },
    successIcon: {
        color: theme.palette.success.main,
    },
    intervalTooShortIcon: {
        color: theme.palette.warning.main,
    },
    fetchErrorIcon: {
        color: theme.palette.error.main,
    },
    fetchingIcon: {
        color: 'rgba(255,255,255,.8)',
    },
    incorrectOldValueIcon: {
        color: theme.palette.warning.main,
    },
    text: {
        margin: 0,
    },
    textSecondary: {
        fontSize: 8,
        fontWeight: 600,
        color: 'rgba(255,255,255,.8)',
        marginTop: 4,
    },
    actions: {
        marginTop: 7,
        '&>:not(:last-child)': {
            marginRight: 7,
        },
    },
}));

const Slot: FC<{
    slot: ProcessingMutation;
}> = ({ slot }) => {
    // STYLE
    const c = useSlotStyles(useTheme());

    // HOOKS
    const { txt } = useLanguage();

    // DATA
    const { status, description, retry, cancel } = slot;
    const secondaryDescription = (() => {
        if (status === 'fetchError') {
            return txt({ en: "Couldn't connect to the server.", ja: 'サーバーに接続できませんでした' });
        }
        if (status === 'failed') {
            return txt({ en: 'Request failed.', ja: 'リクエストが失敗しました' });
        }
        return undefined;
    })();

    return (
        <MuiListItem className={c.root} disableGutters>
            <MuiListItemIcon className={c.status}>
                {status === 'success' ? (
                    <SvgCheck className={c.successIcon} />
                ) : status === 'fetchError' ? (
                    <SvgCloudUnavailable className={c.fetchErrorIcon} />
                ) : status === 'fetching' ? (
                    <MuiCircularProgress className={c.fetchingIcon} size={16} />
                ) : status === 'failed' ? (
                    <SvgCloudCaution className={c.intervalTooShortIcon} />
                ) : (
                    <></>
                )}
            </MuiListItemIcon>
            <div>
                <MuiListItemText
                    className={c.text}
                    primary={description}
                    secondary={secondaryDescription}
                    primaryTypographyProps={{ variant: 'caption' }}
                    secondaryTypographyProps={{ className: c.textSecondary }}
                />
                {(status === 'fetchError' || status === 'failed') && (
                    <div className={c.actions}>
                        {status === 'fetchError' && (
                            <>
                                <Button onClick={cancel} colorMode={'dark'} role={'cancel'} size={'small'}>
                                    {txt({ en: 'Cancel', ja: 'キャンセル' })}
                                </Button>
                                <Button onClick={retry} colorMode={'dark'} role={'submit'} size={'small'}>
                                    {txt({ en: 'Retry', ja: 'リトライ' })}
                                </Button>
                            </>
                        )}
                        {status === 'failed' && (
                            <Button onClick={cancel} colorMode={'dark'} role={'submit'} size={'small'}>
                                {txt({ en: 'Got it', ja: '了承' })}
                            </Button>
                        )}
                    </div>
                )}
            </div>
        </MuiListItem>
    );
};
Slot.displayName = 'Slot';
export default Slot;
