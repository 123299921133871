import { forwardRef, ComponentProps } from 'react';
import clsx from 'clsx';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import MuiDrawer from '@material-ui/core/Drawer';

const useStyles = makeStyles((theme) => ({
    paper: {
        boxShadow: '0 0 64px 0 rgba(0,0,0,.2)',
        paddingTop: 83,
        paddingBottom: 22,
        paddingLeft: 22,
        paddingRight: 22,
    },
    paperTop: {
        borderRadius: '0 0 23px 23px',
        maxWidth: 700,
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    paperBottom: {
        borderRadius: '23px 23px 0 0',
        maxWidth: 700,
        marginLeft: 'auto',
        marginRight: 'auto',
        height: '90%',
    },
    paperRight: {
        borderRadius: '23px 0 0 23px',
    },
    paperLeft: {
        borderRadius: '0 23px 23px 0',
    },
    backdrop: {
        background: 'rgba(0,0,0,.1)',
    },
}));

export const Modal = forwardRef<
    HTMLDivElement,
    ComponentProps<typeof MuiDrawer> & {
        drawerZIndex?: number;
    }
>(({ drawerZIndex, anchor = 'bottom', ...props }, ref) => {
    // STYLE
    const c = useStyles(useTheme());

    return (
        <MuiDrawer
            ref={ref}
            anchor={anchor}
            {...props}
            BackdropProps={{
                classes: {
                    root: c.backdrop,
                },
            }}
            classes={{
                paper: clsx(c.paper, {
                    [c.paperTop]: anchor === 'top',
                    [c.paperBottom]: anchor === 'bottom' || !anchor,
                    [c.paperLeft]: anchor === 'left',
                    [c.paperRight]: anchor === 'right',
                }),
            }}
            style={{
                zIndex: drawerZIndex || 1300,
            }}
            transitionDuration={{ enter: 500, exit: 250 }}
        />
    );
});
Modal.displayName = 'Modal';
