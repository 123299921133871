import { GqlMessage } from '@/utils/PowerchatClient/graphql/generated/graphqlClient';
import { isoToDate, isoToDateOptionally } from '@/utils/PowerchatClient/graphql/customScalars';

export type MessageType = {
    id: string;
    createdAt: Date;
    updatedAt: Date | undefined;
    serialNumber: number | undefined;
    userId: string;
    roomId: string;
    body: string;
    replyToMessageId: string | undefined;
    mentionToUserIds: string[];
    canceledAt: Date | undefined;
};

export class Message implements MessageType {
    readonly id: string;

    readonly createdAt: Date;

    updatedAt: Date | undefined;

    readonly serialNumber: number | undefined;

    readonly userId: string;

    readonly roomId: string;

    readonly body: string;

    readonly replyToMessageId: string | undefined;

    readonly mentionToUserIds: string[];

    canceledAt: Date | undefined;

    constructor({
        id,
        createdAt,
        updatedAt,
        serialNumber,
        userId,
        roomId,
        body,
        replyToMessageId,
        mentionToUserIds,
        canceledAt,
    }: MessageType) {
        this.id = id;
        this.createdAt = createdAt;
        this.updatedAt = updatedAt;
        this.serialNumber = serialNumber;
        this.userId = userId;
        this.roomId = roomId;
        this.body = body;
        this.replyToMessageId = replyToMessageId;
        this.mentionToUserIds = mentionToUserIds;
        this.canceledAt = canceledAt;
    }

    _updateCanceledAt({ canceledAt, updatedAt }: { canceledAt: Date | undefined; updatedAt: Date | undefined }) {
        this.updatedAt = updatedAt;
        this.canceledAt = canceledAt;
    }
}

export const getMessageFromGql = ({
    id,
    createdAt,
    updatedAt,
    serialNumber,
    userId,
    roomId,
    body,
    replyToMessageId,
    mentionToUserIds,
    canceledAt,
}: GqlMessage) => {
    return new Message({
        id,
        createdAt: isoToDate(createdAt),
        updatedAt: isoToDateOptionally(updatedAt),
        serialNumber,
        userId,
        roomId,
        body,
        replyToMessageId: replyToMessageId || undefined,
        mentionToUserIds,
        canceledAt: isoToDateOptionally(canceledAt),
    });
};
