import { FC } from 'react';
import clsx from 'clsx';

import { useTheme, makeStyles } from '@material-ui/core/styles';

import { MessageApi } from '@/utils/PowerchatClient';
import { useLocalTimeline } from '@/components/3_template/ConsoleTemplate/RoomTemplate/useLocalTimeline';
import { MessageCard } from '@/components/3_template/ConsoleTemplate/RoomTemplate/MessageCard';
import { CheckinCard } from '@/components/3_template/ConsoleTemplate/RoomTemplate/CheckinCard';

const useStyles = makeStyles((theme) => ({
    root: {},
}));

export const LocalTimelineView: FC<{
    className?: string;
    setReplyingToMessageApi: (replyingToMessageApi?: MessageApi) => void;
}> = ({ className, setReplyingToMessageApi }) => {
    // STYLE
    const c = useStyles(useTheme());

    // HOOKS
    const { timelineRowIds, getTimelineRow, getTimelineRowAbsolutely } = useLocalTimeline();

    return (
        <div className={clsx(c.root, className)}>
            {timelineRowIds &&
                timelineRowIds.map((timelineRowId) => {
                    const { messageApi, checkin, checkout } = getTimelineRowAbsolutely(timelineRowId);
                    console.log({ messageApi, checkin, checkout });
                    if (messageApi) {
                        return (
                            <MessageCard
                                key={timelineRowId}
                                messageApi={messageApi}
                                initialReactionApis={[]}
                                isLocal={true}
                                setReplyingToMessageApi={setReplyingToMessageApi}
                                getMessageApi={(id) => getTimelineRow(id)?.messageApi}
                            />
                        );
                    }
                    if (checkin) {
                        return (
                            <CheckinCard
                                key={timelineRowId}
                                checkinData={checkin}
                                isLocal={true}
                                isDisplayAsIn={true}
                            />
                        );
                    }
                    if (checkout) {
                        return (
                            <CheckinCard
                                key={timelineRowId}
                                checkinData={checkout}
                                isLocal={true}
                                isDisplayAsIn={false}
                            />
                        );
                    }
                    return <></>;
                })}
        </div>
    );
};
LocalTimelineView.displayName = 'RoomTemplate/LocalTimelineView';
