import { ref, query, orderByChild, equalTo, onChildRemoved, onChildAdded } from 'firebase/database';
import { getFirebaseRealtimeDatabase } from '@/utils/GoogleCloud/firebase';
import { RdbUser } from '@/utils/PowerchatClient/RealtimeDatabase/RdbModels';

export const listenToHotUsersForRoom = ({
    roomId,
    onAdded,
    onRemoved,
}: {
    roomId: string;
    onAdded: (input: { newUser: RdbUser }) => void;
    onRemoved: (input: { removedUser: RdbUser }) => void;
}) => {
    const db = getFirebaseRealtimeDatabase();
    const usersRef = ref(db, `users`);
    const q = query(usersRef, orderByChild('currentRoomId'), equalTo(roomId));
    const unsubscribeOnChildAdded = onChildAdded(q, (data) => {
        if (!data.key) {
            throw new Error('listenToHotUsersForRoom: unexectedly !data.key.');
        }
        const value: Omit<RdbUser, 'id'> = data.val();
        onAdded({
            newUser: {
                ...value,
                id: data.key,
            },
        });
    });
    const unsubscribeOnChildRemoved = onChildRemoved(q, (data) => {
        if (!data.key) {
            throw new Error('listenToHotUsersForRoom: unexectedly !data.key.');
        }
        const value: Omit<RdbUser, 'id'> = data.val();
        onRemoved({
            removedUser: {
                ...value,
                currentRoomId: undefined,
                currentRoomName: undefined,
                id: data.key,
            },
        });
    });
    return {
        unsubscribeListenToHotUsersForRoom: () => {
            unsubscribeOnChildAdded();
            unsubscribeOnChildRemoved();
        },
    };
};
