import { FC, ReactNode, useState, useEffect, useCallback } from 'react';

import { UserCtx, useHealthCtx } from '@/utils/ctxs';
import { useFirebaseUser, useFirebaseCloudMessaging, useLanguage } from '@/utils/customHooks';
import { initPowerchatUserClient, PowerchatUserClient } from '@/utils/PowerchatClient';

export const UserProvider: FC<{
    children: ReactNode;
}> = ({ children }) => {
    // HOOKS
    const { health } = useHealthCtx();
    const { firebaseUser, signOut } = useFirebaseUser();
    const { fcmToken, isNeedToGrantNotification, retryGetFcmToken, isBrowserNotSupported } =
        useFirebaseCloudMessaging();
    const [isFetching, setIsFetching] = useState<boolean>(true);
    const [isFirebaseError, setIsFirebaseError] = useState<boolean | undefined>(undefined);
    const [userClient, setUserClient] = useState<PowerchatUserClient | undefined>(undefined);
    const { language } = useLanguage();

    // CALLBACK
    const initialize = useCallback(async () => {
        const { idToken, uid } = firebaseUser;
        if (idToken && uid && fcmToken && health?.code === 'ok' && language) {
            setIsFetching(true);
            const userClient = await initPowerchatUserClient({
                firebaseUserIdToken: idToken,
                fcmToken,
                firebaseUid: uid,
                language,
            }).catch(() => undefined);
            setUserClient(userClient);
            setIsFetching(false);
        } else {
            setUserClient(undefined);
            setIsFetching(firebaseUser.isFetching);
        }
    }, [fcmToken, firebaseUser, health, language]);

    // USEEFFECT
    useEffect(() => {
        initialize();
    }, [initialize]);
    useEffect(() => {
        setIsFirebaseError(firebaseUser.isError);
    }, [firebaseUser.isError]);

    return (
        <UserCtx.Provider
            value={{
                userClient,
                isFetching,
                firebaseUser: (() => {
                    const { uid, email, idToken } = firebaseUser;
                    if (uid && email && idToken) {
                        return {
                            uid,
                            email,
                            idToken,
                        };
                    }
                    return undefined;
                })(),
                reInitialize: initialize,
                signOut: () => {
                    signOut();
                    setUserClient(undefined);
                },
                isFirebaseError,
                fcmToken,
                isNeedToGrantNotification,
                retryGetFcmToken,
                isBrowserNotSupported,
            }}
        >
            {children}
        </UserCtx.Provider>
    );
};
UserProvider.displayName = 'UserProvider';
