import { FC, ReactNode, useState, useEffect } from 'react';

import { useRoomCtxAbsolutely, LastReactionCtx, LastReactionLog } from '@/utils/ctxs';

export const LastReactionProvider: FC<{
    children: ReactNode;
}> = ({ children }) => {
    // HOOKS
    const {
        current: { currentRoomApi },
    } = useRoomCtxAbsolutely();
    const [lastReactionLog, setLastReactionLog] = useState<LastReactionLog | undefined>(undefined);

    // CALLBACKS

    // USEEFFECT
    useEffect(() => {
        const { unsubscribeListenToReactions } = currentRoomApi.listenToReactions({
            onAdded: ({ newReactionApi }) => {
                console.log({ newReactionApi });
                setLastReactionLog({
                    reactionApi: newReactionApi,
                    action: 'add',
                });
            },
            onRemoved: ({ removedReactionApi }) => {
                console.log({ removedReactionApi });
                setLastReactionLog({
                    reactionApi: removedReactionApi,
                    action: 'remove',
                });
            },
        });
        return unsubscribeListenToReactions;
    }, [currentRoomApi]);

    return (
        <LastReactionCtx.Provider
            value={{
                lastReactionLog,
            }}
        >
            {children}
        </LastReactionCtx.Provider>
    );
};
LastReactionProvider.displayName = 'LastReactionProvider';
