import { GraphQLSdk } from '@/utils/PowerchatClient/graphql/graphqlSdk';
import { removeRdbReaction } from '@/utils/PowerchatClient/RealtimeDatabase';
import { Reaction, ReactionType } from '@/utils/PowerchatClient/models/Reaction/data/Reaction';
import { User } from '@/utils/PowerchatClient/models/User';
import { Room } from '@/utils/PowerchatClient/models/Room';

type ReactionApiType = {
    remove: () => Promise<void>;
};

type ConstructorInput = ReactionType & {
    graphqlSdk: GraphQLSdk;
    currentFcmToken: string;
    clientUser: User;
    room: Room;
};

export class ReactionApi extends Reaction implements ReactionApiType {
    protected _graphqlSdk: GraphQLSdk;

    protected _currentFcmToken: string;

    protected _clientUser: User;

    protected _room: Room;

    constructor(input: ConstructorInput) {
        super(input);
        this._graphqlSdk = input.graphqlSdk;
        this._currentFcmToken = input.currentFcmToken;
        this._clientUser = input.clientUser;
        this._room = input.room;
    }

    async remove() {
        removeRdbReaction({
            roomId: this.roomId,
            reaction: {
                ...this,
                userName: this._clientUser.uniqueName,
                roomName: this._room.name,
                addNotification: undefined,
            },
        });
        await this._graphqlSdk.removeReaction({
            input: {
                roomId: this.roomId,
                messageId: this.messageId,
                reactionId: this.id,
            },
        });
    }
}
