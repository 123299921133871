export type LocalCheckinType = {
    createdAt: Date;
    updatedAt: Date | undefined;
    userId: string;
    roomId: string;
    leftAt: Date | undefined;
};

export class LocalCheckin implements LocalCheckinType {
    readonly createdAt: Date;

    updatedAt: Date | undefined;

    readonly userId: string;

    readonly roomId: string;

    leftAt: Date | undefined;

    constructor({ createdAt, updatedAt, userId, roomId, leftAt }: LocalCheckinType) {
        this.createdAt = createdAt;
        this.updatedAt = updatedAt;
        this.userId = userId;
        this.roomId = roomId;
        this.leftAt = leftAt;
    }

    _updateLeftAt({ leftAt, updatedAt }: { leftAt: Date; updatedAt: Date }) {
        this.leftAt = leftAt;
        this.updatedAt = updatedAt;
    }

    markAsCheckout() {
        const now = new Date();
        this._updateLeftAt({
            leftAt: now,
            updatedAt: now,
        });
    }
}
