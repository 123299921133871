import { createContext, useContext } from 'react';

type RdbUserCtxType = {
    isOnline: boolean | undefined;
};
export const RdbUserCtx = createContext<RdbUserCtxType>({
    isOnline: undefined,
});
export const useRdbUserCtx = () => {
    return useContext(RdbUserCtx);
};
